import React from 'react'
import { PageHeading } from '../PageHeading'
import { Icon } from '../Icon'
import { connect } from 'react-redux'
import { ApplicationState } from '../../store'
import { IconButton, Tooltip, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

export type PageHeaderProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> & {
    title: string
    actions?: React.ReactNode
  }

const mapState = (state: ApplicationState) => ({
  collapsed: state.nav.collapsed,
})

const mapDispatch = (dispatch: any) => ({
  toggleCollapse: dispatch.nav.toggleCollapse,
})

export const PageHeader = connect(
  mapState,
  mapDispatch
)(
  ({
    collapsed,
    title,
    actions,
    toggleCollapse,
    ...props
  }: PageHeaderProps) => {
    const { t } = useTranslation()
    return (
      <Box component="header" display="flex" alignItems="cetner" {...props}>
        <Box mr={4}>
          <Tooltip title={t('global.collapse-sidebar-message').toString()}>
            <IconButton onClick={() => toggleCollapse()}>
              <Icon
                name="bars"
                scale="lg"
                className="c-pointer"
                rotate={collapsed && 90}
              />
            </IconButton>
          </Tooltip>
        </Box>

        <PageHeading>{title}</PageHeading>
        <Box ml="auto" display="flex" alignItems="center">
          {actions}
        </Box>
      </Box>
    )
  }
)
