import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../Alert'
import { Button } from '../Button'
import { ButtonIcon } from '../ButtonIcon'

export interface ContactFormProps {
  contact: ContactFields
  onSubmit: (contact: ContactFields) => Promise<void>
  saving?: boolean
  variant?: 'create' | 'update'
}

export function ContactForm({
  contact,
  onSubmit,
  saving = false,
  variant = 'create',
}: ContactFormProps): JSX.Element {
  const { t } = useTranslation()
  const [changed, setChanged] = React.useState<ContactFields>(contact)
  const [error, setError] = React.useState<string>('')
  const languageLabel = React.useRef<HTMLLabelElement>(null)

  async function handleSubmit(
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> {
    e.preventDefault()
    setError('')
    try {
      await onSubmit({ ...changed })
    } catch (error) {
      setError(error.message)
    }
  }

  function handleChange(field: string, value: string): void {
    setError('')
    setChanged({ ...changed, [field]: value })
  }

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <Box my={4}>
          <Alert>{error}</Alert>
        </Box>
      )}
      <FormControl fullWidth>
        <TextField
          autoFocus
          disabled={saving || variant === 'update'}
          label={t('pages.contacts.contact-form-fields.email')}
          onChange={e => handleChange('email', e.currentTarget.value)}
          value={changed.email}
          placeholder={t('pages.contacts.contact-form-fields.email') + '...'}
        />
      </FormControl>
      <Grid
        container
        spacing={1}
        alignItems="stretch"
        direction="row"
        justify="center"
        className="my-md"
      >
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              disabled={saving}
              label={t('pages.contacts.contact-form-fields.first-name')}
              onChange={e => handleChange('firstName', e.currentTarget.value)}
              value={changed.firstName}
              placeholder={
                t('pages.contacts.contact-form-fields.first-name') + '...'
              }
              spellCheck={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              disabled={saving}
              label={t('pages.contacts.contact-form-fields.last-name')}
              onChange={e => handleChange('lastName', e.currentTarget.value)}
              value={changed.lastName}
              placeholder={
                t('pages.contacts.contact-form-fields.last-name') + '...'
              }
              spellCheck={false}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        alignItems="stretch"
        direction="row"
        justify="center"
        className="my-md"
      >
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              disabled={saving}
              label={t('pages.contacts.contact-form-fields.group')}
              onChange={e => handleChange('companyName', e.currentTarget.value)}
              value={changed.companyName}
              placeholder={
                t('pages.contacts.contact-form-fields.group') + '...'
              }
              spellCheck={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {variant === 'create' && (
            <FormControl>
              <InputLabel ref={languageLabel} id="contact-form-language-select">
                {t('pages.contacts.contact-form-fields.language')}
              </InputLabel>
              <Select
                disabled={saving}
                labelId="contact-form-language-select"
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  handleChange('language', e.target.value as string)
                }
                value={changed.language as AvailableLanguage}
              >
                <MenuItem value="en">
                  English <span className="gray ml-md">英語</span>
                </MenuItem>
                <MenuItem value="ja">
                  Japanese <span className="gray ml-md">日本語</span>
                </MenuItem>
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Box mt={6} display="flex" alignItems="center">
        <Button
          aria-label={t('pages.sharing.save-contact-tooltip')}
          color="primary"
          size="small"
          disabled={saving}
          type="submit"
        >
          {saving ? (
            <ButtonIcon icon="spinner-third" spin />
          ) : (
            <ButtonIcon icon="check" />
          )}
          {t(
            variant === 'create'
              ? 'pages.contacts.contact-button-label.create'
              : 'pages.contacts.contact-button-label.update'
          )}
        </Button>
        {variant === 'create' && (
          <Box ml={4} color="text.secondary" fontSize={12}>
            {t('pages.contacts.save-contact-invite-help-text')}
          </Box>
        )}
      </Box>
    </form>
  )
}
