import React from 'react'

export interface Props extends React.HTMLProps<HTMLAnchorElement> {}

export function IOSBadge(props: Props) {
  return (
    <a
      href="https://itunes.apple.com/us/app/remote-it/id1437569166?mt=8"
      {...props}
    >
      <img
        src="https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2019-01-05&kind=iossoftware&bubble=ios_apps"
        style={{ height: '40px', paddingRight: '5px' }}
      />
    </a>
  )
}
