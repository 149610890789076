import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from '../../src/graphql-introspection-result'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { from } from 'apollo-link'
import gql from 'graphql-tag'
import { LogEvent, DeviceShareEvent, DeviceStateEvent, EventType } from '../graphql-types'
import { getToken } from './remote.it'
import { store } from '../store'

export type GenericLogEvent = LogEvent & DeviceShareEvent & DeviceStateEvent

const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL

const customFetch = (uri, options) => {
  return fetch(uri, options).then(response => {
    if (response.status === 401) {
      store.dispatch.auth.signOut(undefined)
      return Promise.reject(response.status)
    }
    return response
  })
}

const httpLink = new HttpLink({
  uri: GRAPHQL_API_URL,
  fetch: customFetch,
})

const authLink = setContext(
  () =>
    new Promise(async (resolve, reject) => {
      const token = await getToken()
      resolve({
        headers: { Authorization: token },
      })
    })
)

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})
const cache = new InMemoryCache({ fragmentMatcher })

export const client = new ApolloClient({
  link: from([
    authLink,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      if (networkError) console.error(`[Network error]: ${networkError}`)
    }),
    httpLink,
  ]),
  cache,
})

//QUERIES

export const GET_EVENT_LOGS = gql`
  query GetEventLogs($from: Int, $size: Int) {
    login {
      id
      events(size: $size, from: $from, types: [${EventType.DeviceState}, ${EventType.DeviceConnect}, ${EventType.DeviceShare}]) {
        items {
          id
          type
          timestamp
          state
          action
          actor {
            id
            email
          }
          users {
            id
            email
          }
          owner {
            id
            email
          }
          target {
            id
            name
            device {
              id
              name
            }
          }
          ... on DeviceShareEvent {
            scripting
          }
        }
        total
        last
        hasMore
      }
    }
  }
`
export const GET_LOGS_URL = gql`
  query getLogsUrl {
    login {
      id
      eventsUrl
    }
  }
`
export const GET_USER_METADATA = gql`
  query {
    login {
      metadata: attributes
    }
  }
`
//mutations
export const UPDATE_USER_METADATA = gql`
  mutation UpdateUserMetadata(
    $onlineDeviceNotification: Boolean
    $onlineSharedDeviceNotification: Boolean
    $portalUrl: String
  ) {
    setAttributes(
      attributes: {
        onlineDeviceNotification: $onlineDeviceNotification
        onlineSharedDeviceNotification: $onlineSharedDeviceNotification
        portalUrl: $portalUrl
      }
    )
  }
`
