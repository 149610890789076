import { useQuery } from '@apollo/react-hooks'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Query, UserEventsArgs } from '../../../src/graphql-types'
import { GET_EVENT_LOGS } from '../../services/apolloClient'
import { ApplicationState } from '../../store'
import { PageHeader } from '../PageHeader'
import { RefreshButton } from '../RefreshButton'
import { SignInMessage } from '../SignInMessage'
import { EventDownloadButton } from './EventDownloadButton'
import { EventLogsTable } from './EventLogsTable'
import { Box } from '@material-ui/core'

export function EventLogsPage(): JSX.Element {
  const { t } = useTranslation()
  const { signInStarted, user } = useSelector((state: ApplicationState) => ({
    signInStarted: state.auth.signInStartedk,
    user: state.auth.user,
  }))

  if (!user || signInStarted) {
    return (
      <Box height="100%">
        <PageHeader title={t('pages.logs.title')} />
        <SignInMessage />
      </Box>
    )
  }

  return <EventLogs />
}

function EventLogs(): JSX.Element {
  const { t } = useTranslation()
  const [lastUpdated, setLastUpdated] = useState<DateTime>(DateTime.local())
  const [itemOffset, setItemOffset] = useState(0)
  const pageSize = 20
  const { loading, error, data, networkStatus, refetch } = useQuery<Query, UserEventsArgs>(GET_EVENT_LOGS, {
    variables: {
      size: pageSize,
      from: itemOffset,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: () => setLastUpdated(DateTime.local()),
  })

  const paginatedEvents = data && data.login && data.login.events
  const hasNext = !!paginatedEvents && paginatedEvents.hasMore
  const hasPrev = !!paginatedEvents && itemOffset >= pageSize
  const events = paginatedEvents && paginatedEvents.items ? paginatedEvents.items : []
  const total = paginatedEvents ? paginatedEvents.total : 0

  function handleFetch(): void {
    if (itemOffset != 0) {
      setItemOffset(0)
    }

    refetch()
  }

  function handlePrevious(): void {
    return setItemOffset(itemOffset - pageSize)
  }

  function handleNext(): void {
    return setItemOffset(itemOffset + pageSize)
  }

  return (
    <Box height="100%">
      <PageHeader
        title={t('pages.logs.title')}
        actions={
          <>
            <Box className="txt-gray-lighter txt-sm mr-md italic">
              {t('global.notices.last-updated', {
                time: lastUpdated.toLocaleString(DateTime.DATETIME_MED),
              })}
            </Box>
            <EventDownloadButton key="download" />
            <RefreshButton
              onClick={handleFetch}
              title={t('global.actions.refresh')}
              loading={loading || networkStatus === 4}
            />
          </>
        }
      />
      <Box height="100%">
        <EventLogsTable
          events={events}
          error={error}
          fetch={handleFetch}
          hasNext={hasNext}
          hasPrev={hasPrev}
          itemOffset={itemOffset + 1}
          loading={loading || networkStatus === 4}
          onNext={handleNext}
          onPrev={handlePrevious}
          pageSize={pageSize}
          total={total}
        />
      </Box>
    </Box>
  )
}
