import React from 'react'
import ReactDOM from 'react-dom'
import { Wrapper } from '../utils/render'
import { QuickConnectButton } from '../components/QuickConnectButton'
import { ServiceState } from 'remote.it'

/**
 * Renders the "quick launch" button React component on the
 * device list.
 */
export default function renderQuickConnectButton(): void {
  const selector = '.quick-launch-button-placeholder'
  const nodes = document.querySelectorAll<HTMLDivElement>(selector)

  Array.from(nodes).map(node => {
    const deviceID = node.dataset.deviceId
    const status: ServiceState | undefined = node.dataset
      .deviceStatus as ServiceState

    // Clear out content from previous render
    ReactDOM.unmountComponentAtNode(node)

    ReactDOM.render(
      <Wrapper>
        <QuickConnectButton deviceID={deviceID} status={status} />
      </Wrapper>,
      node
    )
  })
}
