import React from 'react'
import { Icon } from '../Icon'

export interface LoadingMessageProps {
  className?: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
  text?: string
}

export function LoadingMessage({
  className,
  size = 'lg',
  text = 'Loading...',
  ...props
}: LoadingMessageProps): JSX.Element {
  return (
    <div {...props} className={`gray-light txt-${size} fw-1 ${className}`}>
      <Icon
        name="spinner-third"
        spin
        weight="regular"
        className={`mr-${size}`}
      />
      {text}
    </div>
  )
}
