import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../Icon'
import { IconButton, Tooltip } from '@material-ui/core'

type NavigationDirection = 'next' | 'back' | 'none'

export interface PageNavigationProps {
  direction: NavigationDirection
  enabled: boolean
  onClick: (dir: NavigationDirection) => void
}

export function EventPageNavigation({
  direction,
  enabled,
  onClick,
}: PageNavigationProps): JSX.Element {
  const { t } = useTranslation()
  const icon = direction === 'next' ? 'angle-right' : 'angle-left'

  if (!enabled) {
    return (
      <div className="as-center mx-sm transparent">
        <IconButton disabled>
          <Icon name={icon} fixedWidth scale="lg" />
        </IconButton>
      </div>
    )
  }

  return (
    <div className="as-center mx-sm">
      <Tooltip title={t(`global.actions.${direction}`).toString()}>
        <IconButton
          onClick={e => {
            e.preventDefault()
            //fetch data again
            onClick(direction)
          }}
        >
          <Icon name={icon} fixedWidth scale="lg" />
        </IconButton>
      </Tooltip>
    </div>
  )
}
