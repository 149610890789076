import airbrake from '../../services/airbrake'
import React from 'react'

export interface Props {
  children: React.ReactNode
}

interface State {
  error: Error | null
}

export class ErrorHandler extends React.Component<Props, State> {
  state: State = { error: null }

  componentDidCatch(error: Error, info) {
    if (process.env.NODE_ENV === 'test') throw error
    console.error('[CAUGHT ERROR]:', error)
    this.setState({ error })
    airbrake.notify({ error, params: { info } })
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div className="mx-auto my-xxl txt-lg" style={{ maxWidth: '600px' }}>
          <h1 className="txt-xxl fw-1 danger mb-lg">Something went wrong</h1>
          <p>
            Something on the page has gone wrong. Please try{' '}
            <strong>reloading the page</strong>
            and trying again.
          </p>
          <p>
            If you continue to experience problems, please contact support at{' '}
            <a href="mailto:support@remote.it">support@remote.it</a>.
          </p>
          <p>Sorry for the inconvenience!</p>
          <pre className="dn">{error.message}</pre>
        </div>
      )
    }

    return this.props.children
  }
}
