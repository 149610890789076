import React from 'react'
import Select from 'react-select'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import { ApplicationState } from '../../store'
import { useTranslation } from 'react-i18next'
import { Alert } from '../Alert'
import { connect } from 'react-redux'
import { ContactSelectorWarnings } from '../ContactSelectorWarnings'
import { LoadingMessage } from '../LoadingMessage'
import styles from './ContactSelector.module.css'

export type SelectorType = 'transfer-device' | 'transfer-bulk' | 'add-share'

const RemoteItCheckbox = withStyles({
  root: {
    color: '#0374b1',
    '&$checked': {
      color: '#0374b1',
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

type Props = {
  isMultiSelect: boolean
  selectorType: SelectorType
}
export type ContactSelectorProps = Props &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  contactOptions: state.contacts.contactOptions,
  allContacts: state.contacts.all,
  selectedContacts: state.contacts.selected,
  acceptableSelectedDevices: state.devices.acceptableSelectedDevices,
  rejectedSelectedDevices: state.devices.rejectedSelectedDevices,
  selectedScripting: state.shares.selectedScripting,
  error: state.devices.error,
  fetching: state.devices.fetching,
  contactsFetching: state.contacts.fetching,
})

const mapDispatch = (dispatch: any) => ({
  select: dispatch.contacts.select,
  updateScripting: dispatch.shares.updateScripting,
})

export const ContactSelector = connect(
  mapState,
  mapDispatch
)(
  ({
    contactOptions,
    allContacts,
    selectedContacts,
    acceptableSelectedDevices,
    rejectedSelectedDevices,
    selectedScripting,
    error,
    fetching,
    contactsFetching,
    select,
    updateScripting,
    ...props
  }: ContactSelectorProps) => {
    const { t } = useTranslation()
    function handleChange(newValue: any, actionMeta: any) {
      select(newValue)
    }
    function handleScriptingChange() {
      updateScripting(!selectedScripting)
    }
    if (fetching || contactsFetching) {
      return (
        <LoadingMessage text={t('global.actions.loading')} className="center" />
      )
    }
    if (error) {
      return (
        <Alert type="warning" className="my-md" stayOpen={true}>
          {t(
            'pages.contact-selector.selected-devices.share-contactslist-failed',
            { message: error }
          )}
        </Alert>
      )
    }
    if (
      props.selectorType !== 'transfer-bulk' &&
      acceptableSelectedDevices.length < 1
    ) {
      return (
        <Alert type="warning" className="my-md" stayOpen={true}>
          {t('pages.contact-selector.selected-devices.noQualifiedDevices')}
        </Alert>
      )
    }
    return (
      <>
        <ContactSelectorWarnings
          selectorType={props.selectorType}
          hasContactsNotSelectable={contactOptions.length < allContacts.length}
          hasNoContacts={contactOptions.length === 0}
          acceptableDevices={acceptableSelectedDevices}
          rejectedDevices={rejectedSelectedDevices}
        />
        {contactOptions.length > 0 && (
          <>
            <Select
              options={contactOptions}
              value={selectedContacts}
              placeholder={t(
                `pages.contact-selector.placeholder.${
                  props.isMultiSelect ? 'multi' : 'single'
                }`
              )}
              isMulti={props.isMultiSelect}
              isClearable
              maxMenuHeight={110}
              onChange={handleChange}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#e0e0e0',
                  primary: '#e0e0e0',
                },
              })}
            />
            {props.selectorType === 'add-share' && (
              <div className="mt-sm">
                <FormControlLabel
                  control={
                    <RemoteItCheckbox
                      checked={selectedScripting}
                      onChange={handleScriptingChange}
                      color="primary"
                    />
                  }
                  label={
                    <Typography className={styles.scriptingLabel}>
                      {t('pages.device-share.allow-scripting')}
                    </Typography>
                  }
                />
                <div className="mt-md">
                  {t('pages.device-share.share-info')}
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  }
)
