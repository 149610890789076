import React from 'react'
import { Section } from '../Section'
import { TextBlock } from '../TextBlock'
import { CopyButton } from '../CopyButton'

interface DeveloperKeySectionProps {
  apiKey: string
}

export const DeveloperKeySection = ({ apiKey }: DeveloperKeySectionProps) => {
  return (
    <>
      <Section title="Developer API Key" icon="laptop-code">
        <TextBlock>
          This is your unique Developer API Key to use and access remote.it APIs.
          <br />
          <em>Do not share it with anyone</em>.
        </TextBlock>
        <div className="df ai-center my-sm">
          <pre className="m-none txt-sm">{apiKey}</pre>
          <CopyButton text={apiKey} className="ml-md" />
        </div>
      </Section>
    </>
  )
}
