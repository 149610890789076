import React from 'react'
import { TextBlock } from '../../TextBlock'

export function RaspberryPiInstructions(): JSX.Element {
  return (
    <>  
      <TextBlock>
        Please follow the{' '}
        <a
          href="https://link.remote.it/pi-portal-docs-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          instructions here
        </a>{' '}
        to download and configure remote.it on your Raspberry Pi
      </TextBlock>
      
    </>
  )
}
