import React from 'react'
import { colors, spacing } from '../styling'
import { makeStyles, Box } from '@material-ui/core'

export const Quote: React.FC<{ margin?: number, paddingLeft?: number }> = ({ margin = spacing.lg, children, paddingLeft = spacing.lg }) => {
  const css = useStyles( margin, paddingLeft) ()
  return <Box className={css.quote}>{children}</Box>
}

const useStyles = (margin, paddingLeft) =>
  makeStyles({
    quote: {
      width: '100%',
      margin: `${margin}px 0`,
      paddingLeft: `${paddingLeft}px `,
      borderLeft: `1px solid ${colors.grayLighter}`,
      '& .MuiListItem-root, & > .MuiIconButton-root': {
        marginLeft: -spacing.md,
      },
    },
  })
