import debug from 'debug'
import { r3 } from '../../services/remote.it'

const d = debug('r3:legacy:DeviceRequestHelper')

export default class DeviceRequestHelper {
  /**
   * Get the amount of devices the user has in their account.
   * This allows us to check if we should show search or device
   * list.
   */
  public static async devicesCount(): Promise<number> {
    const count = await r3.get('/device/count/')
    const mine =
      typeof count.mine === 'string' ? parseInt(count.mine || 0, 10) : 0
    const friends =
      typeof count.friends === 'string' ? parseInt(count.friends || 0, 10) : 0
    const total = mine + friends
    d('services count:', total)
    return total
  }

  public static async findDevices(
    searchOnly: boolean,
    query: string,
    refresh?: boolean
  ): Promise<[RawService[], RawMetaData, Error | undefined]> {
    // Return immediately if in search only mode and
    // there is no query as this is a non-op.
    if (searchOnly && !query) return [[], {}, undefined]

    // const [services, error] =
    const servicesPromise = searchOnly
      ? this.searchResults(query)
      : this.services(refresh)

    const metadataPromise = this.metadata()

    const [services, error] = await servicesPromise

    return [services, await metadataPromise, error]
  }

  /**
   * Find search results using the device search API based on the
   * give query. This is the alternative to device list which is
   * used on small device lists.
   *
   * @param query The search query to find devices/services by
   */
  public static async searchResults(
    query: string
  ): Promise<[RawService[], Error | undefined]> {
    d('fetching search results with query:', query)

    if (!query) return [[], undefined]

    interface Resp {
      status: 'true' | 'false' | string
      reason?: 'string'
      devices?: RawService[]
    }

    try {
      const data: Resp = await r3.post('/device/find/by/name/', {
        devicestate: 'all',
        devicename: query,
      })

      d('search results', data)

      if (!data || data.status !== 'true') {
        const message = data.reason || 'Please try a different search query!'

        return [[], new Error(message)]
      }

      if (!data.devices?.length) return [[], undefined]

      return [this.makeUniqueByID(data.devices), undefined]
    } catch (error) {
      d('error searching services:', error)
      if (error.message?.includes('limit exceeded')) {
        error.message =
          'Your search returned too many results, please refine your search.'
      }
      return [[], error]
    }
  }

  /**
   * Fetch the metadata for the user's list of devices which
   * includes the grouping around the bulk service and category/status
   * field data.
   */
  public static async metadata(): Promise<RawMetaData> {
    d('fetching metadata')
    const { devices } = await r3.get('/device/metadata/list/me')
    d('fetched metadata:', devices)
    return devices
  }

  /**
   * Fetch all services within the given account. This step will
   * be combined with fetching the metadata to construct the device
   * list.
   *
   * @param refresh Whether to force busting the device list cache
   */
  public static async services(
    refresh?: boolean
  ): Promise<[RawService[], Error | undefined]> {
    d('fetching services with refresh:', refresh)
    const url = `/device/list/all?cache=false`

    try {
      const { devices } = await r3.get(url)
      window.remot3it.store.dispatch.devices.setList(devices)
      d('fetched services:', devices)
      return [devices, undefined]
    } catch (error) {
      d('error fetching services:', error)
      return [[], error]
    }
  }

  private static makeUniqueByID(services: RawService[]): RawService[] {
    return Array.from(new Set(services.map(a => a.deviceaddress))).map(id =>
      services.find(a => a.deviceaddress === id)
    ) as RawService[]
  }
}
