import { Box, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { PageHeading } from '../PageHeading'
import { OSSelect } from './OSSelect'
import { platforms } from './platforms'
import { TextBlock } from '../TextBlock'

export function AddDevicePage(): JSX.Element {
  const [os, setOS] = useState<OperatingSystem>('')
  const platform = platforms.find(p => p.id === os)

  return (
    <Box maxWidth="1000px" m={8}>
      <PageHeading>Add Device</PageHeading>
      <Typography variant="h3">1. Select an OS</Typography>
      <OSSelect
        platforms={platforms}
        selected={os}
        onSelect={(os: OperatingSystem) => setOS(os)}
      />
      {platform && (
        <>
          <Typography variant="h3">2. Install remote.it</Typography>
          <div className="instructions">{platform.instructions}</div>
          <Typography variant="h3">3. Connect</Typography>
          <TextBlock>
            You should now be able to find your device on the Devices page and
            connect to it.
          </TextBlock>
          <p>
            <Button className="button" href="#devices">
              View Devices
              <Icon weight="regular" name="arrow-right" className="ml-sm" />
            </Button>
          </p>
        </>
      )}
    </Box>
  )
}
