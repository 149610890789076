import DeviceSearch from './DeviceSearch'

// FIXME: Exposing these global helpers sucks, we can do better
window.findServiceIDsByHWID = (hwid: string): string[] =>
  DeviceSearch.findServiceIDsByHWID(hwid)

// FIXME: Exposing these global helpers sucks, we can do better
window.findService = (id: string): RawService | undefined =>
  DeviceSearch.findService(id)

export default (refresh?: boolean): Promise<void> =>
  DeviceSearch.deviceSearch(refresh)
