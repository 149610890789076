import React from 'react'
import { Avatar } from '../Avatar'
import { Section } from '../Section'
import { TextBlock } from '../TextBlock'

export const ProfilePictureSection = ({ email }: { email: string }) => {
  return (
    <Section title="Profile Picture" icon="user">
      <a href="https://gravatar.com" target="_blank" rel="noopener noreferrer">
        <Avatar email={email} size="70px" className="fl rad-sm mr-md" />
      </a>
      <TextBlock>
        Your profile picture is imported from the free service Gravatar.
        <br />
        To edit or add a profile image please visit{' '}
        <a href="https://gravatar.com" target="_blank" rel="noopener noreferrer">
          gravatar.com
        </a>
      </TextBlock>
    </Section>
  )
}
