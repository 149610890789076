import React from 'react'
import { ApplicationState } from '../../store'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from '../Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

type Props = {}

const mapState = (state: ApplicationState) => ({
  showTransferConfirmation: state.nav.showTransferConfirmation,
})

const mapDispatch = (dispatch: any) => ({
  toggleShowTransferConfirmation: dispatch.nav.toggleShowTransferConfirmation,
})

export type ContactSelectorProps = Props &
  ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>

export const ConfirmationAlert = connect(
  mapState,
  mapDispatch
)(
  ({
    showTransferConfirmation,
    toggleShowTransferConfirmation,
    ...props
  }: ContactSelectorProps) => {
    const { t } = useTranslation()

    const handleClose = () => {
      toggleShowTransferConfirmation(false)
    }
    const handleConfirm = () => {
      toggleShowTransferConfirmation(false)
      window.doBulkDeviceTransfer()
    }

    return (
      <div>
        <Dialog
          open={showTransferConfirmation}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className="danger">
              {t('pages.device-transfer.confirmation-dialog.title')}
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('pages.device-transfer.confirmation-dialog.description')}
              <br />
              {t('pages.device-transfer.confirmation-dialog.description2')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" outline={true}>
              {t('pages.device-transfer.confirmation-dialog.cancel')}
            </Button>
            <Button onClick={handleConfirm} color="danger" autoFocus>
              {t('pages.device-transfer.confirmation-dialog.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
)
