import React from 'react'
import { Alert } from '../Alert'
import { useTranslation } from 'react-i18next'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styles from './ContactSelectorWarnings.module.css'

type SelectorType =
  | 'transfer-device'
  | 'add-share'
  | 'remove-share'
  | 'transfer-bulk'

export interface Props {
  selectorType: SelectorType
  hasContactsNotSelectable: boolean
  hasNoContacts: boolean
  acceptableDevices: string[]
  rejectedDevices: string[]
}

export function ContactSelectorWarnings({
  selectorType,
  hasContactsNotSelectable,
  hasNoContacts,
  acceptableDevices,
  rejectedDevices,
}: Props) {
  const { t } = useTranslation()
  const rejectedDeviceList =
    rejectedDevices.length !== 1
      ? rejectedDevices
          .slice(0, -1)
          .join(t('global.formatting.sentence.delimiter')) +
        t('global.formatting.sentence.or') +
        rejectedDevices.slice(-1)
      : rejectedDevices[0]
  const rejected = selectorType !== 'transfer-bulk' &&
    rejectedDevices.length > 0 && (
      <li key="rejected">
        {t('pages.contact-selector.selected-devices.rejectedDevices', {
          devicelist: rejectedDeviceList,
        })}
      </li>
    )
  const nocontacts = selectorType !== 'remove-share' && hasNoContacts && (
    <li key="nocontacts">{t('pages.contact-selector.has-no-contacts')}</li>
  )
  const nonselectablecontacts = selectorType !== 'remove-share' &&
    hasContactsNotSelectable && (
      <li key="nonselectablecontacts">
        {t('pages.contact-selector.has-contacts-not-selectable')}
      </li>
    )
  const removenocontacts = selectorType === 'remove-share' && hasNoContacts && (
    <li key="removenocontacts">
      {t('pages.contact-selector.has-no-remove-contacts')}
    </li>
  )
  const alerts =
    removenocontacts || rejected || nocontacts || nonselectablecontacts
  return (
    <>
      {alerts && (
        <Alert type="warning" className="my-md" id={`${selectorType}-alert`}>
          <ul className={styles.warning}>
            {removenocontacts}
            {rejected}
            {nocontacts}
            {nonselectablecontacts}
          </ul>
        </Alert>
      )}
      {selectorType !== 'transfer-bulk' && !removenocontacts && (
        <div className="my-sm">
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={styles.panel}
            >
              <Typography className={styles.header}>
                {t(
                  `pages.contact-selector.selected-devices.${selectorType}WithCount`,
                  {
                    count: acceptableDevices.length,
                  }
                )}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={styles.content}>
              {acceptableDevices.map(device => (
                <div className={styles.deviceName} key={device}>
                  {device}
                </div>
              ))}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      )}
    </>
  )
}
