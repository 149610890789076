import platform from 'platform'

export type TrackerFunctionData = {
  action?: string
  label?: string
  value?: number
  [key: string]: number | string | boolean | undefined
}

export type TrackerFunction = (event: string, args: TrackerFunctionData) => void

export type GenericAnalyticsInterface = typeof window.analytics

/**
 * Very simple wrapper around Google Analytics tracking
 * method to send arbitrary events to GA.
 *
 * This class is exported specifically for tests
 * and should not be used directly. Instead please use
 * the default export for tracking events.
 */
export class Analytics {
  /**
   * Send a tracking event to the tracker (Google Analytics)
   */
  public track(event: string, options: TrackerFunctionData = {}): void {
    window.analytics.track(event, {
      ...this.staticMetadata,
      ...options,
    })
  }

  public identify(userID: string): void {
    window.analytics.identify(userID, this.staticMetadata)
  }

  public clearIdentity(): void {
    window.analytics.user().traits({})
    window.analytics.reset()
  }

  public page(name: string, properties: GenericObject = {}): void {
    if (name) {
      window.analytics.page('Portal', name, {
        path: '/' + name,
        url: window.PORTAL_URL + '/' + name,
        ...this.staticMetadata,
        ...properties,
      })
    }
  }

  // Static metadata for logging that is common across all events.
  private get staticMetadata(): { [key: string]: string | number | undefined } {
    return {
      appName: 'Portal',
      appVersion: process.env.NODE_ENV,
      category: 'Portal',
      systemArch: platform.os?.architecture,
      systemOS: platform.os?.family,
      systemOSVersion: platform.os?.version,
    }
  }
}

/**
 * Export an initalized version of the tracker
 * for actual usage in the app.
 */
export default new Analytics()
