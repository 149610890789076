import React from 'react'
import { RefreshButton } from '../RefreshButton'
import { ButtonIcon } from '../ButtonIcon'
import { PageHeader } from '../PageHeader'
import { HelpIcon } from '../HelpIcon'
import { ApplicationState } from '../../store'
import { connect } from 'react-redux'
import { SignInMessage } from '../SignInMessage'
import { ButtonGroup, Button } from '@material-ui/core'

export type ScriptingPageProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  signInStarted: state.auth.signInStarted,
})
const mapDispatch = (dispatch: any) => ({})

export const ScriptingPage = connect(
  mapState,
  mapDispatch
)(({ signInStarted }: ScriptingPageProps) => {
  if (signInStarted) return <SignInMessage />

  return (
    <div>
      <PageHeader
        title="Scripting"
        actions={
          <>
            <HelpIcon
              title="View scripting documentation"
              href="https://support.remote.it/hc/en-us/categories/360003803911-Bulk-Scripting"
            />
            <RefreshButton
              onClick={() => window.showJobs()}
              className="ml-auto"
            />
          </>
        }
      />
      <div className="my-lg">
        <Button
          data-toggle="modal"
          onClick={() => window.showUpload()}
          className="mr-md"
          color="primary"
          variant="contained"
          size="large"
        >
          <ButtonIcon icon="cloud-upload" />
          Upload
        </Button>
        <ButtonGroup>
          <Button
            data-toggle="modal"
            onClick={() => window.showContentModal('file')}
            variant="outlined"
            size="large"
          >
            <ButtonIcon icon="folder" />
            Files
          </Button>
          <Button
            data-toggle="modal"
            onClick={() => window.showContentModal('script')}
            variant="outlined"
            size="large"
          >
            <ButtonIcon icon="code" />
            Scripts
          </Button>
        </ButtonGroup>
      </div>
      <div id="jobs-table-content"></div>
    </div>
  )
})
