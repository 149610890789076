import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ErrorHandler } from '../components/ErrorHandler'
import { LoadingScreen } from '../components/LoadingScreen'
import { store } from '../store'
import muiTheme from '../styles/mui-theme'

/**
 * Simple util to render React components unless the
 * target doesn't exist.
 *
 * @param {React.component} component
 * @param {DOM Node} target
 */
export function render(component, selector: string): void {
  const node = document.querySelector<HTMLDivElement>(selector)
  if (node) ReactDOM.render(component, node)
}

interface ComponentList {
  [key: string]: React.ReactNode
}

export function renderComponents(all: ComponentList): void {
  Object.keys(all).map(selector =>
    render(<Wrapper>{all[selector]}</Wrapper>, selector)
  )
}

export function Wrapper({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  return (
    <ErrorHandler>
      <Suspense fallback={<LoadingScreen />}>
        <Provider store={store}>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            {children}
          </ThemeProvider>
        </Provider>
      </Suspense>
    </ErrorHandler>
  )
}
