import React from 'react'
import { TextBlock } from '../../TextBlock'

export function LinuxInstructions(): JSX.Element {
  return (
    <>
      <TextBlock>
          Please follow the improved {' '}
          <a
            href="https://link.remote.it/linux-portal-docs-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            instructions here
          </a>{' '}
          to download and configure remote.it on your linux device
        </TextBlock>
    </>
  )
}
