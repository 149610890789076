import React from 'react'

export interface CoBrandingLogoProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  email?: string
  hostName?: string
  fallback?: JSX.Element
  onLoaded?: () => void
}

export function CoBrandingLogo({
  fallback,
  hostName = window.location.hostname,
  onLoaded,
  ...props
}: CoBrandingLogoProps): JSX.Element {
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const cobrandLogo =
    'https://s3-us-west-2.amazonaws.com/r3-whitelabel-assets/' +
    hostName +
    '/logo.png'

  React.useEffect(() => {
    try {
      // FIXME: This is an ugly hack. Better to abstract the
      // implementation into a helper which we can mock.
      if (process.env.NODE_ENV === 'test') return

      if (hostName.includes('localhost') || hostName.includes('app.remote.it')) {
        setLoaded(false)
        return
      }

      const cobranded = new Image()
      cobranded.src = cobrandLogo

      // Microsoft Edge previous to v80 do not support
      // decoding of images, so let's just do nothing instead
      // of failing.
      if (!cobranded.decode) {
        setLoaded(false)
        return
      }

      cobranded
        .decode()
        .then(() => {
          setLoaded(true)
          if (onLoaded) onLoaded()
        })
        .catch(() => setLoaded(false))
    } catch (error) {
      // We go here if there is no image
      setLoaded(false)
      //console.error(error)
    }
  }, [])

  if (loaded)
    return <img {...props} src={cobrandLogo} style={{ width: '100%' }} />

  if (fallback) return fallback

  return <></>
}
