import React, { useEffect, useState } from 'react'
import { TextBlock } from '../TextBlock'
import { Icon } from '../Icon'
import { fetchAccessKeys, toggleAccessKeys } from './accessKeyService'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import { DeleteAccessKey } from './DeleteAccessKey'
import { CreateAccessKey } from './CreateAccessKey'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import { createAccessKey } from './accessKeyService'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 550,
    backgroundColor: theme.palette.background.paper,
  },
}))

export function AccountAccessKey({ ...props }): JSX.Element {
  const classes = useStyles()
  const [keyArray, setKeyArray] = useState([])
  const [maxlimit, setMaxLimit] = useState(0)
  const [secretKey, setSecretKey] = useState('')
  const [key, setKey] = useState('')
  const [showSection, setShowSection] = useState(false)

  useEffect(() => {
    getAccessKeys()
  }, [])
  async function getAccessKeys() {
    const resp = await fetchAccessKeys()
    if (resp && resp.data) {
      const data = resp.data.data.login.accessKeys
      setMaxLimit(data.length)
      const arr = [] as any
      data.forEach(element => {
        const obj = {}
        obj['key'] = element.key
        const dateCr = moment(new Date(element.created)).format('MM/DD/YYYY')
        obj['createdDate'] = dateCr
        const dateLastUsed = element.lastUsed
          ? 'Last used ' +
            moment(new Date(element.lastUsed)).format('MM/DD/YYYY')
          : 'Never used'
        obj['lastUsed'] = dateLastUsed
        obj['enabled'] = element.enabled
        arr.push(obj)
      })
      setKeyArray(arr)
    }
  }

  async function handleToggle(e) {
    const selectedId = e.currentTarget.id
    const selectedKey = keyArray.filter(item => selectedId === item['key'])
    if (selectedKey.length) {
      const properties = {
        key: selectedKey[0]['key'],
        enabled: !selectedKey[0]['enabled'],
      }
      const resp = await toggleAccessKeys(properties)
      if (resp && resp.data) {
        getAccessKeys()
      }
    }
  }

  async function handleCreateKey() {
    const res = await createAccessKey()
    if (res && res.data) {
      const data = res.data.data.createAccessKey
      setKey(data.key)
      setSecretKey(data.secret)
      setShowSection(true)
      getAccessKeys()
    }
  }

  function closePanel() {
    setShowSection(false)
  }

  return (
    <Section title="Access Key" icon="key">
      <TextBlock>
        Access keys are used to authenticate you with our API. You can create a
        new key or delete an existing key at any time. You can also temporarily
        disable a key.
        <br />
        <em>
          If you lose or forget your secret key, you cannot retreive it. There
          is a limit of 2 access keys.
        </em>
      </TextBlock>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateKey}
        disabled={!(maxlimit < 2)}
      >
        Create Access key
      </Button>
      {keyArray.map((item, index) => {
        return (
          <div className={classes.root} key={index}>
            <List>
              <ListItem
                key={item['key'] + index}
                id={item['key']}
                button
                onClick={handleToggle}
              >
                <ListItemText
                  primary={
                    <>
                      <Typography
                        component="span"
                        variant="body1"
                        color="textPrimary"
                      >
                        {item['key']}
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        component="span"
                        variant="caption"
                        color="textSecondary"
                      >
                        Created date {item['createdDate']} , {item['lastUsed']}
                      </Typography>
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <ListItemIcon>
                    <DeleteAccessKey
                      getAccessKeys={getAccessKeys}
                      deleteKey={item['key']}
                      enabled={item['enabled']}
                    />
                  </ListItemIcon>
                  <Switch
                    id={item['key']}
                    edge="end"
                    color="primary"
                    onChange={handleToggle}
                    checked={item['enabled']}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            {index === 0 ? (
              <Collapse in={showSection}>
                <CreateAccessKey
                  getAccessKeys={getAccessKeys}
                  maxlimit={maxlimit}
                  secretKey={secretKey}
                  showSection={showSection}
                  accessKey={key}
                  closePanel={closePanel}
                />
              </Collapse>
            ) : (
              <></>
            )}
          </div>
        )
      })}
    </Section>
  )
}

function Section({
  title,
  icon,
  children,
}: {
  title: string | React.ReactNode
  icon: string
  children: React.ReactNode
}): JSX.Element {
  return (
    <section
      className="df p-xl bb bc-gray-lightest"
      style={{ maxWidth: '100%' }}
    >
      <div className="mr-xl">
        <div className="rad-100 bg-gray-lightest">
          <Icon name={icon} color="gray" className="txt-xxl p-lg" />
        </div>
      </div>
      <div className="txt-md w-100">
        <h3 className="mt-none gray-darker mb-md txt-xl fw-light">{title}</h3>
        {children}
      </div>
    </section>
  )
}
