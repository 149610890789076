import React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from '../../store'
import { AuthLayout } from '../AuthLayout'
import { CognitoAuth } from '@remote.it/components'
import { ErrorHandler } from '../ErrorHandler'
import r3Analytics from '../../services/Analytics'
import './App.css'

function trackPageChange(): void {
  try {
    const { location } = window
    const hash = location?.hash?.replace('#/', '') || 'sign-in'
    r3Analytics.page(hash)
  } catch (error) {
    console.error('PAGE TRACK ERROR:', error)
  }
}

export type AuthProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  // user: state.auth.user,
})

const mapDispatch = (dispatch: any) => ({
  checkSignIn: dispatch.auth.checkSignIn,
  signIn: dispatch.auth.signIn,
})

export function AppData(): JSX.Element {
  // Track route changes in analytics
  React.useEffect(() => {
    // Track all page navigations using hash state.
    window.addEventListener('hashchange', trackPageChange, false)
    // Track first page on load
    window.addEventListener('load', trackPageChange, false)
    return () => {
      window.removeEventListener('hashchange', trackPageChange, false)
      window.removeEventListener('load', trackPageChange, false)
    }
  }, [])

  const segmentSettings = {
    segmentKey: process.env.SEGMENT_KEY,
    segmentAppName: 'Portal',
  }

  console.log('authservice defined: ')
  console.log(window.authService)

  return (
    <ErrorHandler>
      <AuthLayout>
        <CognitoAuth
          onSignInSuccess={() => (window.location.href = '/')}
          showLogo={false}
          clientId={
            process.env.COGNITO_CLIENT_ID ? process.env.COGNITO_CLIENT_ID : ''
          }
          // @ts-ignore
          authService={window.authService}
          segmentSettings={segmentSettings}
          fullWidth={true}
        />
      </AuthLayout>
    </ErrorHandler>
  )
}

export const App = connect(mapState, mapDispatch)(AppData)
