import React, { useState } from 'react'
import cookies from 'js-cookie'
import styles from './AppStoreBanner.module.css'
import { useTranslation } from 'react-i18next'
import { IOSBadge } from './IOSBadge'
import { AndroidBadge } from './AndroidBadge'
import { Icon } from '../Icon'

type OS = 'unknown' | 'iOS' | 'Android'
const UNKNOWN: OS = 'unknown'
const IOS: OS = 'iOS'
const ANDROID: OS = 'Android'
export const HIDE_APP_BADGE_COOKIE = 'remoteit.hide-app-badge'

export function AppStoreBanner() {
  const { t } = useTranslation()
  const [hidden, setHidden] = useState(isAppBannerHidden())

  // Return nothing if they've opted to close the banner
  if (hidden) return null

  const os = getMobileOperatingSystem()

  return (
    <div className="df ai-center bg-info px-lg py-md white fw-wrap relative">
      <div className="mr-lg">
        {t('app-store-banner.connect')}{' '}
        {os === UNKNOWN && t('app-store-banner.unknown-os')}
      </div>
      <div className="ml-auto mr-lg">
        {os === IOS && <IOSBadge />}
        {os === ANDROID && <AndroidBadge />}
        {os === UNKNOWN && (
          <>
            <IOSBadge className="mr-xs" />
            <AndroidBadge />
          </>
        )}
      </div>
      <a
        href="#"
        className={styles.x}
        onClick={e => {
          e.preventDefault()
          setHidden(true)
          rememberToHideAppBadge()
        }}
      >
        <Icon name="times" color="white" title="Close" weight="regular" />
      </a>
    </div>
  )
}

function getMobileOperatingSystem() {
  const userAgent: string =
    navigator.userAgent || navigator.vendor || String(window.opera)

  if (/android/i.test(userAgent)) {
    return ANDROID
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS
  }

  return UNKNOWN
}

function isAppBannerHidden() {
  return cookies.get(HIDE_APP_BADGE_COOKIE) === 'true'
}

function rememberToHideAppBadge() {
  cookies.set(HIDE_APP_BADGE_COOKIE, 'true', { expires: 3650 })
}
