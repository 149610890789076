import React from 'react'
import { Container } from '../../components/Container'
import { Invoices } from '../../components/Invoices'
import { Section } from '../Section'

export const BillingPage: React.FC = () => {
  return (
    <>
      <Section title={<>Billing</>} icon="shopping-basket">
        <Container gutterBottom integrated={false}>
          <Invoices />
        </Container>
      </Section>
    </>
  )
}
