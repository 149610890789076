export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** ISO Date string */
  DateTime: any;
  /** Arbitrary Object */
  Object: any;
  /** Arbitrary Value */
  Any: any;
};

/** remote.it Device Access */
export type Access = {
   __typename?: 'Access';
  /** Creation datetime (ISO 8601 format date string) */
  created?: Maybe<Scalars['DateTime']>;
  /** Is the user allowed scripting on the device? */
  scripting?: Maybe<Scalars['Boolean']>;
  /** User */
  user: UserReference;
};

/** remote.it Access Key */
export type AccessKey = {
   __typename?: 'AccessKey';
  /** Access key creation datetime (ISO 8601 format date string) */
  created: Scalars['DateTime'];
  /** Is the access key enabled? (active) */
  enabled: Scalars['Boolean'];
  /** Access key */
  key: Scalars['String'];
  /** Last used datetime (ISO 8601 format date string) */
  lastUsed?: Maybe<Scalars['DateTime']>;
};

/** remote.it Access Key */
export type AccessKeyResponse = {
   __typename?: 'AccessKeyResponse';
  /** Key */
  key: Scalars['String'];
  /** Secret */
  secret: Scalars['String'];
};


/** remote.it API Key */
export type ApiKey = {
   __typename?: 'APIKey';
  /** API key */
  key: Scalars['String'];
  /** API key update datetime (ISO 8601 format date string) */
  updated: Scalars['DateTime'];
};

/** remote.it Application Type */
export type ApplicationType = {
   __typename?: 'ApplicationType';
  /** Application ID */
  id: Scalars['Int'];
  /** Application Name */
  name: Scalars['String'];
  /** Description */
  description: Scalars['String'];
  /** Default Port */
  port?: Maybe<Scalars['Int']>;
  /** Reverse Proxy? */
  proxy: Scalars['Boolean'];
  /** Protocol */
  protocol: Protocol;
};

/** Authentication Provider */
export enum AuthenticationProvider {
  Saml = 'SAML'
}

/** remote.it Billing Interval */
export enum BillingInterval {
  /** Bill daily */
  Day = 'DAY',
  /** Bill monthly */
  Month = 'MONTH',
  /** Bill weekly */
  Week = 'WEEK',
  /** Bill yearly */
  Year = 'YEAR'
}

/** Billing Session */
export type BillingSession = {
   __typename?: 'BillingSession';
  /** Session URL */
  url: Scalars['String'];
};

/** Credit Card */
export type CreditCard = {
   __typename?: 'CreditCard';
  /** Credit Card brand */
  brand: Scalars['String'];
  /** Credit Card expiration month */
  month: Scalars['Int'];
  /** Credit Card expiration year */
  year: Scalars['Int'];
  /** Credit Card last 4 digits */
  last: Scalars['String'];
  /** Cardholder name */
  name?: Maybe<Scalars['String']>;
  /** Email Address */
  email?: Maybe<Scalars['String']>;
  /** Billing phone number */
  phone?: Maybe<Scalars['String']>;
  /** Postal/ZIP Code */
  postal?: Maybe<Scalars['String']>;
  /** ISO Country Code */
  country?: Maybe<Scalars['String']>;
  /** Credit Card expiration date (UTC) */
  expiration: Scalars['DateTime'];
};


/** remote.it Device */
export type Device = {
   __typename?: 'Device';
  /** Service UUID */
  id: Scalars['ID'];
  /** Protocol */
  protocol?: Maybe<Protocol>;
  /** Application Type ID (service type id) */
  type?: Maybe<Scalars['String']>;
  /** connectd daemon version */
  version?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Manufacturer */
  manufacturer?: Maybe<Scalars['Int']>;
  /** Platform */
  platform?: Maybe<Scalars['Int']>;
  /** Creation datetime (ISO 8601 format date string) */
  created?: Maybe<Scalars['DateTime']>;
  /** Application Type */
  application?: Maybe<Scalars['Int']>;
  /** Port */
  port?: Maybe<Scalars['Int']>;
  /** Application Type Title (i.e. TCP) */
  title?: Maybe<Scalars['String']>;
  /** Owner (User) */
  owner?: Maybe<UserReference>;
  /** Device */
  device?: Maybe<Device>;
  /** Service Host Address */
  host?: Maybe<Scalars['String']>;
  /** Enabled? */
  enabled: Scalars['Boolean'];
  /** Access list (users with access to the service) */
  access: Array<Access>;
  /** Custom Attributes */
  attributes: Scalars['Object'];
  /** Service Endpoint */
  endpoint?: Maybe<ServiceEndpoint>;
  /** Last reported datetime (ISO 8601 format date string) */
  lastReported?: Maybe<Scalars['DateTime']>;
  /** License Status */
  license: LicenseStatus;
  /** Permissions */
  permissions: Array<ServicePermission>;
  /** Permission Check */
  can: Scalars['Boolean'];
  /** Connection sessions */
  sessions?: Maybe<Array<Session>>;
  /** State (active||inactive) */
  state: Scalars['String'];
  /** Events */
  events: PaginatedLogEventResponse;
  /** Events URL */
  eventsUrl: Scalars['String'];
  /** Time Series */
  timeSeries: TimeSeries;
  /** Notification Settings */
  notificationSettings: NotificationSettings;
  /** Category A */
  categoryA?: Maybe<Scalars['String']>;
  /** Category B */
  categoryB?: Maybe<Scalars['String']>;
  /** Category C */
  categoryC?: Maybe<Scalars['String']>;
  /** Category D */
  categoryD?: Maybe<Scalars['String']>;
  /** Category E */
  categoryE?: Maybe<Scalars['String']>;
  /** Hardware ID */
  hardwareId?: Maybe<Scalars['String']>;
  /** Status A */
  statusA?: Maybe<Scalars['String']>;
  /** Status B */
  statusB?: Maybe<Scalars['String']>;
  /** Status C */
  statusC?: Maybe<Scalars['String']>;
  /** Status D */
  statusD?: Maybe<Scalars['String']>;
  /** Status E */
  statusE?: Maybe<Scalars['String']>;
  /** Configurable? */
  configurable: Scalars['Boolean'];
  /**
   * Allow scripting?
   * @deprecated Use can(permission: SCRIPTING) instead
   */
  scripting: Scalars['Boolean'];
  /** Services */
  services: Array<Service>;
};


/** remote.it Device */
export type DeviceAttributesArgs = {
  name?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};


/** remote.it Device */
export type DeviceCanArgs = {
  permission: ServicePermission;
};


/** remote.it Device */
export type DeviceEventsArgs = {
  after?: Maybe<Scalars['ID']>;
  from?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<EventType>>;
};


/** remote.it Device */
export type DeviceEventsUrlArgs = {
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<EventType>>;
};


/** remote.it Device */
export type DeviceTimeSeriesArgs = {
  type?: Maybe<TimeSeriesType>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  resolution?: Maybe<TimeSeriesResolution>;
  timezone?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
};


/** remote.it Device */
export type DeviceServicesArgs = {
  sort?: Maybe<Scalars['String']>;
  application?: Maybe<Scalars['Int']>;
  hardwareId?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Array<Scalars['Int']>>;
  state?: Maybe<Scalars['String']>;
};

/** remote.it Device Connect Event */
export type DeviceConnectEvent = LogEvent & {
   __typename?: 'DeviceConnectEvent';
  /** Action */
  action?: Maybe<Scalars['String']>;
  /** Event ID */
  id: Scalars['ID'];
  /** Service State */
  state?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp?: Maybe<Scalars['DateTime']>;
  /** Event Type */
  type: Scalars['String'];
  /** Actor */
  actor?: Maybe<UserReference>;
  /** Owner */
  owner?: Maybe<UserReference>;
  /** Target */
  target?: Maybe<Array<ServiceReference>>;
  /**
   * Devices
   * @deprecated Use target instead
   */
  devices?: Maybe<Array<ServiceReference>>;
  /**
   * Services
   * @deprecated Use target instead
   */
  services?: Maybe<Array<ServiceReference>>;
  /** Users */
  users?: Maybe<Array<UserReference>>;
  /** Peer ID */
  peer?: Maybe<Scalars['String']>;
  /** Connection session ID */
  session?: Maybe<Scalars['String']>;
  /** Client Type */
  sourceType?: Maybe<Scalars['String']>;
  /** Source IP Address */
  sourceAddress?: Maybe<Scalars['String']>;
  /** Target IP Address */
  targetAddress?: Maybe<Scalars['String']>;
  /** Source Geolocation Information */
  sourceGeo?: Maybe<Geolocation>;
  /** Target Geolocation Information */
  targetGeo?: Maybe<Geolocation>;
  /** Platform Type */
  platform?: Maybe<Scalars['Int']>;
  /** Proxy connection? */
  proxy?: Maybe<Scalars['Boolean']>;
  /** Version */
  version?: Maybe<Scalars['String']>;
};

/** remote.it Device Share Event */
export type DeviceShareEvent = LogEvent & {
   __typename?: 'DeviceShareEvent';
  /** Action */
  action?: Maybe<Scalars['String']>;
  /** Event ID */
  id: Scalars['ID'];
  /** Service State */
  state?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp?: Maybe<Scalars['DateTime']>;
  /** Event Type */
  type: Scalars['String'];
  /** Actor */
  actor?: Maybe<UserReference>;
  /** Owner */
  owner?: Maybe<UserReference>;
  /** Target */
  target?: Maybe<Array<ServiceReference>>;
  /**
   * Devices
   * @deprecated Use target instead
   */
  devices?: Maybe<Array<ServiceReference>>;
  /**
   * Services
   * @deprecated Use target instead
   */
  services?: Maybe<Array<ServiceReference>>;
  /** Users */
  users?: Maybe<Array<UserReference>>;
  /** Allow scripting */
  scripting?: Maybe<Scalars['Boolean']>;
  /**
   * Shared
   * @deprecated Use action instead
   */
  shared?: Maybe<Scalars['Boolean']>;
};

/** remote.it Device State Event */
export type DeviceStateEvent = LogEvent & {
   __typename?: 'DeviceStateEvent';
  /** Action */
  action?: Maybe<Scalars['String']>;
  /** Event ID */
  id: Scalars['ID'];
  /** Service State */
  state?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp?: Maybe<Scalars['DateTime']>;
  /** Event Type */
  type: Scalars['String'];
  /** Actor */
  actor?: Maybe<UserReference>;
  /** Owner */
  owner?: Maybe<UserReference>;
  /** Target */
  target?: Maybe<Array<ServiceReference>>;
  /**
   * Devices
   * @deprecated Use target instead
   */
  devices?: Maybe<Array<ServiceReference>>;
  /**
   * Services
   * @deprecated Use target instead
   */
  services?: Maybe<Array<ServiceReference>>;
  /** Users */
  users?: Maybe<Array<UserReference>>;
  /** Expired? */
  expired?: Maybe<Scalars['Boolean']>;
  /** External/Mapped IP Address */
  externalAddress?: Maybe<Scalars['String']>;
  /** Internal IP Address */
  internalAddress?: Maybe<Scalars['String']>;
  /** Chat Server IP Address */
  serverAddress?: Maybe<Scalars['String']>;
  /** Geolocation Information */
  geo?: Maybe<Geolocation>;
};

/** remote.it Endpoint Quality */
export enum EndpointQuality {
  /** Unknown quality */
  Unknown = 'UNKNOWN',
  /** Poor quality */
  Poor = 'POOR',
  /** Moderate quality */
  Moderate = 'MODERATE',
  /** Good quality */
  Good = 'GOOD'
}

/** remote.it Endpoint Information */
export type EndpointsInfo = {
   __typename?: 'EndpointsInfo';
  /** Number of active endpoints */
  active: Scalars['Int'];
};

/** remote.it Event Type */
export enum EventType {
  /** Device state change event */
  DeviceState = 'DEVICE_STATE',
  /** Device connection event */
  DeviceConnect = 'DEVICE_CONNECT',
  /** Device share event */
  DeviceShare = 'DEVICE_SHARE',
  /** License update event */
  LicenseUpdated = 'LICENSE_UPDATED',
  /** Login event */
  AuthLogin = 'AUTH_LOGIN',
  /** Login attempt event */
  AuthLoginAttempt = 'AUTH_LOGIN_ATTEMPT',
  /** Password change event */
  AuthPasswordChange = 'AUTH_PASSWORD_CHANGE',
  /** Password reset event */
  AuthPasswordReset = 'AUTH_PASSWORD_RESET',
  /** Phone change event */
  AuthPhoneChange = 'AUTH_PHONE_CHANGE',
  /** MFA enabled event */
  AuthMfaEnabled = 'AUTH_MFA_ENABLED',
  /** MFA disabled event */
  AuthMfaDisabled = 'AUTH_MFA_DISABLED'
}

/** remote.it Generic Event */
export type GenericEvent = LogEvent & {
   __typename?: 'GenericEvent';
  /** Action */
  action?: Maybe<Scalars['String']>;
  /** Event ID */
  id: Scalars['ID'];
  /** Service State */
  state?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp?: Maybe<Scalars['DateTime']>;
  /** Event Type */
  type: Scalars['String'];
  /** Actor */
  actor?: Maybe<UserReference>;
  /** Owner */
  owner?: Maybe<UserReference>;
  /** Target */
  target?: Maybe<Array<ServiceReference>>;
  /**
   * Devices
   * @deprecated Use target instead
   */
  devices?: Maybe<Array<ServiceReference>>;
  /**
   * Services
   * @deprecated Use target instead
   */
  services?: Maybe<Array<ServiceReference>>;
  /** Users */
  users?: Maybe<Array<UserReference>>;
};

/** Geolocation Information */
export type Geolocation = {
   __typename?: 'Geolocation';
  /** City */
  city?: Maybe<Scalars['String']>;
  /** ISP Connection Type */
  connectionType?: Maybe<Scalars['String']>;
  /** ISO Continent Code */
  continent?: Maybe<Scalars['String']>;
  /** Continent Name */
  continentName?: Maybe<Scalars['String']>;
  /** ISO Country Code */
  country?: Maybe<Scalars['String']>;
  /** Country Name */
  countryName?: Maybe<Scalars['String']>;
  /** ISP */
  isp?: Maybe<Scalars['String']>;
  /** Latitude */
  latitude?: Maybe<Scalars['Float']>;
  /** Longitude */
  longitude?: Maybe<Scalars['Float']>;
  /** Postal/ZIP Code */
  postal?: Maybe<Scalars['String']>;
  /** ISO State/Region Code */
  state?: Maybe<Scalars['String']>;
  /** State/Region Name */
  stateName?: Maybe<Scalars['String']>;
  /** Time zone (IANA time zone format) */
  timezone?: Maybe<Scalars['String']>;
};

/** Invoice */
export type Invoice = {
   __typename?: 'Invoice';
  /** Invoice plan */
  plan: Plan;
  /** Invoice quantity */
  quantity: Scalars['Int'];
  /** Invoice price */
  price: Price;
  /** Invoice total */
  total: Scalars['Int'];
  /** Invoice paid? */
  paid: Scalars['Boolean'];
  /** Invoice URL */
  url?: Maybe<Scalars['String']>;
  /** Invoice created */
  created: Scalars['DateTime'];
};

/** remote.it License */
export type License = {
   __typename?: 'License';
  /** Creation datetime (ISO 8601 format date string) */
  created: Scalars['DateTime'];
  /** Expiration datetime (ISO 8601 format date string) */
  expiration?: Maybe<Scalars['DateTime']>;
  /** License ID */
  id: Scalars['ID'];
  /** Plan */
  plan: Plan;
  /** Product */
  product: Product;
  /** Last Updated (ISO 8601 format date string) */
  updated: Scalars['DateTime'];
  /** Quantity */
  quantity?: Maybe<Scalars['Int']>;
  /** Valid? */
  valid: Scalars['Boolean'];
  /** Subscription */
  subscription?: Maybe<Subscription>;
};

/** License Option */
export enum LicenseOption {
  /** The user is licensed */
  Licensed = 'LICENSED',
  /** The user is unlicensed */
  Unlicensed = 'UNLICENSED'
}

/** remote.it License Status */
export enum LicenseStatus {
  /** The object is licensed */
  Licensed = 'LICENSED',
  /** The object is unlicensed */
  Unlicensed = 'UNLICENSED',
  /** The object is not licensed for commercial use */
  NonCommercial = 'NON_COMMERCIAL'
}

/** remote.it License Updated Event */
export type LicenseUpdatedEvent = LogEvent & {
   __typename?: 'LicenseUpdatedEvent';
  /** Action */
  action?: Maybe<Scalars['String']>;
  /** Event ID */
  id: Scalars['ID'];
  /** Service State */
  state?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp?: Maybe<Scalars['DateTime']>;
  /** Event Type */
  type: Scalars['String'];
  /** Actor */
  actor?: Maybe<UserReference>;
  /** Owner */
  owner?: Maybe<UserReference>;
  /** Target */
  target?: Maybe<Array<ServiceReference>>;
  /**
   * Devices
   * @deprecated Use target instead
   */
  devices?: Maybe<Array<ServiceReference>>;
  /**
   * Services
   * @deprecated Use target instead
   */
  services?: Maybe<Array<ServiceReference>>;
  /** Users */
  users?: Maybe<Array<UserReference>>;
  /** Quantity */
  quantity?: Maybe<Scalars['Int']>;
  /** Expiration datetime (ISO 8601 format date string) */
  expiration?: Maybe<Scalars['DateTime']>;
  /** Plan */
  plan?: Maybe<Plan>;
};

/** remote.it Limit */
export type Limit = {
   __typename?: 'Limit';
  /** License */
  license?: Maybe<License>;
  /** Name */
  name: Scalars['String'];
  /** Value */
  value?: Maybe<Scalars['Any']>;
  /** Actual */
  actual?: Maybe<Scalars['Any']>;
};

/** remote.it LogEvent */
export type LogEvent = {
  /** Action */
  action?: Maybe<Scalars['String']>;
  /** Event ID */
  id: Scalars['ID'];
  /** Service State */
  state?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp?: Maybe<Scalars['DateTime']>;
  /** Event Type */
  type: Scalars['String'];
  /** Actor */
  actor?: Maybe<UserReference>;
  /** Owner */
  owner?: Maybe<UserReference>;
  /** Target */
  target?: Maybe<Array<ServiceReference>>;
  /**
   * Devices
   * @deprecated Use target instead
   */
  devices?: Maybe<Array<ServiceReference>>;
  /**
   * Services
   * @deprecated Use target instead
   */
  services?: Maybe<Array<ServiceReference>>;
  /** Users */
  users?: Maybe<Array<UserReference>>;
};

export type Mutation = {
   __typename?: 'Mutation';
  /** Create remote.it Access Key */
  createAccessKey: AccessKeyResponse;
  /** Delete remote.it Access Key */
  deleteAccessKey: Scalars['Boolean'];
  /** Update remote.it Access Key */
  updateAccessKey?: Maybe<AccessKey>;
  /** Add/Update Attribute */
  setAttribute?: Maybe<Scalars['Any']>;
  /** Add/Update Attributes */
  setAttributes: Scalars['Object'];
  /** Share Device */
  share: Scalars['Boolean'];
  /** Transfer Device */
  transfer: Scalars['Boolean'];
  /** Set notifications settings */
  setNotificationSettings: Scalars['Boolean'];
  /** Mark Notice Read/Unread */
  readNotice: Scalars['Boolean'];
  /** Claim Device */
  claimDevice?: Maybe<Device>;
  /** Add Service */
  addService?: Maybe<Service>;
  /** Remove Service */
  removeService: Scalars['Boolean'];
  /** Update Service */
  updateService?: Maybe<Service>;
  /** Refresh Device */
  refreshDevice: Scalars['Boolean'];
  /** Create Subscription */
  createSubscription?: Maybe<BillingSession>;
  /** Update Subscription */
  updateSubscription: Scalars['Boolean'];
  /** Cancel Subscription */
  cancelSubscription: Scalars['Boolean'];
  /** Update Credit Card */
  updateCreditCard?: Maybe<BillingSession>;
  /** Refresh License */
  refreshLicense: Scalars['Boolean'];
  /**
   * Link Account
   * @deprecated Use Organization instead
   */
  link: Scalars['Boolean'];
  /** Create/Update Organization */
  setOrganization: Scalars['Boolean'];
  /** Delete Organization */
  deleteOrganization: Scalars['Boolean'];
  /** Add/Remove Organization Member */
  setMember: Scalars['Boolean'];
  /** Leave Organization */
  leaveOrganization: Scalars['Boolean'];
};


export type MutationDeleteAccessKeyArgs = {
  key: Scalars['String'];
};


export type MutationUpdateAccessKeyArgs = {
  enabled: Scalars['Boolean'];
  key: Scalars['String'];
};


export type MutationSetAttributeArgs = {
  name: Scalars['String'];
  context?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Any']>;
  serviceId?: Maybe<Scalars['String']>;
};


export type MutationSetAttributesArgs = {
  attributes: Scalars['Object'];
  context?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};


export type MutationShareArgs = {
  action?: Maybe<SharingAction>;
  scripting?: Maybe<Scalars['Boolean']>;
  notify?: Maybe<Scalars['Boolean']>;
  services?: Maybe<Array<ServiceSharingOptions>>;
  email: Array<Scalars['String']>;
  deviceId: Scalars['String'];
};


export type MutationTransferArgs = {
  email: Scalars['String'];
  deviceId: Scalars['String'];
};


export type MutationSetNotificationSettingsArgs = {
  emailNotifications?: Maybe<Scalars['Boolean']>;
  desktopNotifications?: Maybe<Scalars['Boolean']>;
  urlNotifications?: Maybe<Scalars['Boolean']>;
  notificationEmail?: Maybe<Scalars['String']>;
  notificationUrl?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};


export type MutationReadNoticeArgs = {
  value?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationClaimDeviceArgs = {
  code: Scalars['String'];
};


export type MutationAddServiceArgs = {
  application?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  deviceId: Scalars['String'];
};


export type MutationRemoveServiceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateServiceArgs = {
  application?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};


export type MutationRefreshDeviceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSubscriptionArgs = {
  cancelUrl: Scalars['String'];
  successUrl: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  priceId: Scalars['String'];
};


export type MutationUpdateSubscriptionArgs = {
  quantity?: Maybe<Scalars['Int']>;
  priceId: Scalars['String'];
};


export type MutationUpdateCreditCardArgs = {
  cancelUrl: Scalars['String'];
  successUrl: Scalars['String'];
};


export type MutationLinkArgs = {
  action?: Maybe<SharingAction>;
  scripting?: Maybe<Scalars['Boolean']>;
  email: Array<Scalars['String']>;
};


export type MutationSetOrganizationArgs = {
  name: Scalars['String'];
};


export type MutationSetMemberArgs = {
  license?: Maybe<LicenseOption>;
  role?: Maybe<OrganizationRole>;
  email: Array<Scalars['String']>;
};


export type MutationLeaveOrganizationArgs = {
  id: Scalars['ID'];
};

/** remote.it Notice */
export type Notice = {
   __typename?: 'Notice';
  /** Notice ID */
  id: Scalars['ID'];
  /** Notice Type */
  type?: Maybe<NoticeType>;
  /** Notice Stage */
  stage?: Maybe<Scalars['String']>;
  /** Notice Language */
  language?: Maybe<Scalars['String']>;
  /** Notice Title */
  title: Scalars['String'];
  /** Body HTML */
  body: Scalars['String'];
  /** Notice Image */
  image?: Maybe<Scalars['String']>;
  /** Notice Link */
  link?: Maybe<Scalars['String']>;
  /** Preview HTML */
  preview?: Maybe<Scalars['String']>;
  /** Enabled? */
  enabled?: Maybe<Scalars['Boolean']>;
  /** From Date */
  from?: Maybe<Scalars['DateTime']>;
  /** Until Date */
  until?: Maybe<Scalars['DateTime']>;
  /** Last Updated */
  modified: Scalars['DateTime'];
  /** Read Date */
  read?: Maybe<Scalars['DateTime']>;
};

/** remote.it Notice Type */
export enum NoticeType {
  /** Generic announcement */
  Generic = 'GENERIC',
  /** System notice */
  System = 'SYSTEM',
  /** Security notice */
  Security = 'SECURITY',
  /** Release related announcement */
  Release = 'RELEASE',
  /** Generic communication */
  Communication = 'COMMUNICATION'
}

/** remote.it Notification Settings */
export type NotificationSettings = {
   __typename?: 'NotificationSettings';
  /** Email notification? */
  emailNotifications?: Maybe<Scalars['Boolean']>;
  /** Desktop notification? */
  desktopNotifications?: Maybe<Scalars['Boolean']>;
  /** URL notification? */
  urlNotifications?: Maybe<Scalars['Boolean']>;
  /** Notification email */
  notificationEmail?: Maybe<Scalars['String']>;
  /** Notification URL */
  notificationUrl?: Maybe<Scalars['String']>;
};


/** remote.it Organization */
export type Organization = {
   __typename?: 'Organization';
  /** Organization ID */
  id: Scalars['ID'];
  /** Name */
  name: Scalars['String'];
  /** Domain Name */
  domain?: Maybe<Scalars['String']>;
  /** SAML Name */
  samlName?: Maybe<Scalars['String']>;
  /** Creation datetime (ISO 8601 format date string) */
  created: Scalars['DateTime'];
  /** Authentication Providers */
  providers?: Maybe<AuthenticationProvider>;
  /** Account */
  account: UserReference;
  /** Member List */
  members: Array<OrganizationMember>;
  /** Organization Licenses */
  licenses: Array<License>;
};

/** remote.it Organization Member */
export type OrganizationMember = {
   __typename?: 'OrganizationMember';
  /** Creation datetime (ISO 8601 format date string) */
  created: Scalars['DateTime'];
  /** Role */
  role: OrganizationRole;
  /** User */
  user: UserReference;
  /** License Status */
  license: LicenseStatus;
  /** Organization */
  organization: Organization;
};

/** Organization Role */
export enum OrganizationRole {
  Remove = 'REMOVE',
  Admin = 'ADMIN',
  Member = 'MEMBER'
}

export type PaginatedDeviceResponse = {
   __typename?: 'PaginatedDeviceResponse';
  hasMore: Scalars['Boolean'];
  items: Array<Device>;
  last?: Maybe<Scalars['ID']>;
  total: Scalars['Int'];
};

export type PaginatedLogEventResponse = {
   __typename?: 'PaginatedLogEventResponse';
  hasMore: Scalars['Boolean'];
  items: Array<LogEvent>;
  last?: Maybe<Scalars['ID']>;
  total: Scalars['Int'];
};

/** remote.it Plan */
export type Plan = {
   __typename?: 'Plan';
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Plan ID */
  id: Scalars['ID'];
  /** Name */
  name: Scalars['String'];
  /** Product */
  product: Product;
  /** Commercial? */
  commercial: Scalars['Boolean'];
  /** Billing? */
  billing: Scalars['Boolean'];
  /**
   * Default Duration
   * @deprecated Field no longer supported
   */
  duration?: Maybe<Scalars['String']>;
  /** Price List */
  prices: Array<Price>;
};

/** Price */
export type Price = {
   __typename?: 'Price';
  /** Price ID */
  id: Scalars['ID'];
  /** Price per unit */
  amount: Scalars['Int'];
  /** Currency */
  currency: Scalars['String'];
  /** Billing interval */
  interval?: Maybe<BillingInterval>;
};

/** remote.it Product */
export type Product = {
   __typename?: 'Product';
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Product ID */
  id: Scalars['ID'];
  /** Name */
  name: Scalars['String'];
  /**
   * Provider
   * @deprecated Field no longer supported
   */
  provider?: Maybe<Scalars['String']>;
};

/** TCP or UDP */
export enum Protocol {
  /** Transmission Control Protocol */
  Tcp = 'TCP',
  /** User Datagram Protocol */
  Udp = 'UDP'
}

export type Query = {
   __typename?: 'Query';
  /** Application Types (array of application type IDs) */
  applicationTypes: Array<ApplicationType>;
  /** System Notices */
  notices: Array<Notice>;
  /** remote.it GraphQL version */
  version: Scalars['String'];
  /** The login user */
  login?: Maybe<User>;
  /** Notification Event */
  event: LogEvent;
  /** Available plans */
  plans: Array<Plan>;
  /** System Information */
  system: SystemInfo;
};

/** remote.it Service */
export type Service = {
   __typename?: 'Service';
  /** Service UUID */
  id: Scalars['ID'];
  /** Protocol */
  protocol?: Maybe<Protocol>;
  /** Application Type ID (service type id) */
  type?: Maybe<Scalars['String']>;
  /** connectd daemon version */
  version?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Manufacturer */
  manufacturer?: Maybe<Scalars['Int']>;
  /** Platform */
  platform?: Maybe<Scalars['Int']>;
  /** Creation datetime (ISO 8601 format date string) */
  created?: Maybe<Scalars['DateTime']>;
  /** Application Type */
  application?: Maybe<Scalars['Int']>;
  /** Port */
  port?: Maybe<Scalars['Int']>;
  /** Application Type Title (i.e. TCP) */
  title?: Maybe<Scalars['String']>;
  /** Owner (User) */
  owner?: Maybe<UserReference>;
  /** Device */
  device?: Maybe<Device>;
  /** Service Host Address */
  host?: Maybe<Scalars['String']>;
  /** Enabled? */
  enabled: Scalars['Boolean'];
  /** Access list (users with access to the service) */
  access: Array<Access>;
  /** Custom Attributes */
  attributes: Scalars['Object'];
  /** Service Endpoint */
  endpoint?: Maybe<ServiceEndpoint>;
  /** Last reported datetime (ISO 8601 format date string) */
  lastReported?: Maybe<Scalars['DateTime']>;
  /** License Status */
  license: LicenseStatus;
  /** Permissions */
  permissions: Array<ServicePermission>;
  /** Permission Check */
  can: Scalars['Boolean'];
  /** Connection sessions */
  sessions?: Maybe<Array<Session>>;
  /** State (active||inactive) */
  state: Scalars['String'];
  /** Events */
  events: PaginatedLogEventResponse;
  /** Events URL */
  eventsUrl: Scalars['String'];
  /** Time Series */
  timeSeries: TimeSeries;
  /** Notification Settings */
  notificationSettings: NotificationSettings;
};


/** remote.it Service */
export type ServiceAttributesArgs = {
  name?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};


/** remote.it Service */
export type ServiceCanArgs = {
  permission: ServicePermission;
};


/** remote.it Service */
export type ServiceEventsArgs = {
  after?: Maybe<Scalars['ID']>;
  from?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<EventType>>;
};


/** remote.it Service */
export type ServiceEventsUrlArgs = {
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<EventType>>;
};


/** remote.it Service */
export type ServiceTimeSeriesArgs = {
  type?: Maybe<TimeSeriesType>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  resolution?: Maybe<TimeSeriesResolution>;
  timezone?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Int']>;
};

/** remote.it Service Endpoint */
export type ServiceEndpoint = {
   __typename?: 'ServiceEndpoint';
  /** External IP Address */
  externalAddress?: Maybe<Scalars['String']>;
  /** Endpoint ID */
  id: Scalars['ID'];
  /** Internal IP Address */
  internalAddress?: Maybe<Scalars['String']>;
  /** Chat Server IP Address */
  serverAddress?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp: Scalars['DateTime'];
  /** Geolocation Information */
  geo?: Maybe<Geolocation>;
  /** Availability */
  availability?: Maybe<Scalars['Float']>;
  /** Expired? */
  expired?: Maybe<Scalars['Boolean']>;
  /** Instability */
  instability?: Maybe<Scalars['Float']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Offline Count */
  offlineCount?: Maybe<Scalars['Int']>;
  /** Offline Duration */
  offlineDuration?: Maybe<Scalars['Float']>;
  /** Offline since datetime (ISO 8601 format date string) */
  offlineSince?: Maybe<Scalars['DateTime']>;
  /** Online Count */
  onlineCount?: Maybe<Scalars['Int']>;
  /** Online Duration */
  onlineDuration?: Maybe<Scalars['Float']>;
  /** Online since datetime (ISO 8601 format date string) */
  onlineSince?: Maybe<Scalars['DateTime']>;
  /** Ping Interval */
  pingInterval?: Maybe<Scalars['Float']>;
  /** Service State */
  state: Scalars['String'];
  /** Type */
  type?: Maybe<Scalars['String']>;
  /** Quality */
  quality?: Maybe<EndpointQuality>;
  /** Application Type ID */
  application?: Maybe<Scalars['Int']>;
  /** Manufacturer */
  manufacturer?: Maybe<Scalars['Int']>;
  /** Platform */
  platform?: Maybe<Scalars['Int']>;
  /** Port */
  port?: Maybe<Scalars['Int']>;
  /** Version */
  version?: Maybe<Scalars['String']>;
  /** Application Title */
  title?: Maybe<Scalars['String']>;
};

/** Service Definition */
export type ServiceInput = {
  /** Application Type ID */
  application?: Maybe<Scalars['Int']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Service Host Address */
  host?: Maybe<Scalars['String']>;
  /** Port */
  port?: Maybe<Scalars['Int']>;
  /** Enabled? */
  enabled?: Maybe<Scalars['Boolean']>;
};

/** remote.it Service Permission */
export enum ServicePermission {
  /** Can connect to the service */
  Connect = 'CONNECT',
  /** Can run scripts on the device */
  Scripting = 'SCRIPTING',
  /** Can manage the service */
  Manage = 'MANAGE'
}

/** remote.it Service Reference */
export type ServiceReference = {
   __typename?: 'ServiceReference';
  /** Service UUID */
  id: Scalars['ID'];
  /** Protocol */
  protocol?: Maybe<Protocol>;
  /** Application Type ID (service type id) */
  type?: Maybe<Scalars['String']>;
  /** connectd daemon version */
  version?: Maybe<Scalars['String']>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Manufacturer */
  manufacturer?: Maybe<Scalars['Int']>;
  /** Platform */
  platform?: Maybe<Scalars['Int']>;
  /** Creation datetime (ISO 8601 format date string) */
  created?: Maybe<Scalars['DateTime']>;
  /** Application Type */
  application?: Maybe<Scalars['Int']>;
  /** Port */
  port?: Maybe<Scalars['Int']>;
  /** Application Type Title (i.e. TCP) */
  title?: Maybe<Scalars['String']>;
  /** Owner (User) */
  owner?: Maybe<UserReference>;
  /** Device */
  device?: Maybe<Device>;
};

/** Service Sharing Options */
export type ServiceSharingOptions = {
  /** Share Action */
  action?: Maybe<SharingAction>;
  /** Service ID */
  serviceId: Scalars['String'];
};

/** remote.it Session */
export type Session = {
   __typename?: 'Session';
  /** Session ID */
  id: Scalars['ID'];
  /** Timestamp (ISO 8601 format date string) */
  timestamp: Scalars['DateTime'];
  /** User */
  user?: Maybe<UserReference>;
  /** Target Service */
  target?: Maybe<ServiceReference>;
  /** Session Endpoint */
  endpoint?: Maybe<SessionEndpoint>;
};

/** remote.it Session Endpoint */
export type SessionEndpoint = {
   __typename?: 'SessionEndpoint';
  /** External IP Address */
  externalAddress?: Maybe<Scalars['String']>;
  /** Endpoint ID */
  id: Scalars['ID'];
  /** Internal IP Address */
  internalAddress?: Maybe<Scalars['String']>;
  /** Chat Server IP Address */
  serverAddress?: Maybe<Scalars['String']>;
  /** Timestamp (ISO 8601 format date string) */
  timestamp: Scalars['DateTime'];
  /** Geolocation Information */
  geo?: Maybe<Geolocation>;
  /** Client Type */
  type?: Maybe<Scalars['String']>;
  /** Platform Type */
  platform?: Maybe<Scalars['Int']>;
  /** Proxy? */
  proxy: Scalars['Boolean'];
  /** Version */
  version?: Maybe<Scalars['String']>;
};

/** Sharing Action */
export enum SharingAction {
  /** Add a share */
  Add = 'ADD',
  /** Remove a share */
  Remove = 'REMOVE',
  /** Leave a share */
  Leave = 'LEAVE'
}

/** remote.it Subscription */
export type Subscription = {
   __typename?: 'Subscription';
  /** Subscription status */
  status: SubscriptionStatus;
  /** Subscription email */
  email: Scalars['String'];
  /** Subscription price */
  price: Price;
  /** Credit Card */
  card?: Maybe<CreditCard>;
  /** Subscription total */
  total: Scalars['Int'];
};

/** remote.it Subscription Status */
export enum SubscriptionStatus {
  /** Subscription is active */
  Active = 'ACTIVE',
  /** Subscription is cancelled */
  Canceled = 'CANCELED',
  /** Subscription is incomplete */
  Incomplete = 'INCOMPLETE',
  /** Subscription is incomplete/expired */
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  /** Subscription is past-due */
  PastDue = 'PAST_DUE',
  /** Subscription is in trial period */
  Trialing = 'TRIALING',
  /** Subscription is unpaid */
  Unpaid = 'UNPAID'
}

/** remote.it System Information */
export type SystemInfo = {
   __typename?: 'SystemInfo';
  /** Select User */
  user?: Maybe<User>;
  /** Select Devices */
  device: Device;
  /** Select Services */
  service: Service;
  /** Endpoint Information */
  endpoints: EndpointsInfo;
};


/** remote.it System Information */
export type SystemInfoUserArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


/** remote.it System Information */
export type SystemInfoDeviceArgs = {
  id: Scalars['String'];
};


/** remote.it System Information */
export type SystemInfoServiceArgs = {
  id: Scalars['String'];
};

/** remote.it Time Series */
export type TimeSeries = {
   __typename?: 'TimeSeries';
  /** Type */
  type: TimeSeriesType;
  /** Start datetime (ISO 8601 format date string) */
  start: Scalars['DateTime'];
  /** End datetime (ISO 8601 format date string) */
  end: Scalars['DateTime'];
  /** Resolution */
  resolution: TimeSeriesResolution;
  /** Time zone (IANA time zone format) */
  timezone: Scalars['String'];
  /** Time Values */
  time: Array<Scalars['DateTime']>;
  /** Data Values */
  data: Array<Scalars['Float']>;
};

/** remote.it Time Series Resolution */
export enum TimeSeriesResolution {
  /** Second Resolution */
  Second = 'SECOND',
  /** Minute Resolution */
  Minute = 'MINUTE',
  /** Hour Resolution */
  Hour = 'HOUR',
  /** Day Resolution */
  Day = 'DAY',
  /** Week Resolution */
  Week = 'WEEK',
  /** Month Resolution */
  Month = 'MONTH',
  /** Quarter Resolution */
  Quarter = 'QUARTER',
  /** Year Resolution */
  Year = 'YEAR'
}

/** remote.it Time Series Type */
export enum TimeSeriesType {
  /** Online Percentage */
  Availability = 'AVAILABILITY',
  /** Online Duration (in seconds) */
  OnlineDuration = 'ONLINE_DURATION',
  /** Number of online events */
  Online = 'ONLINE',
  /** Number of offline events */
  Offline = 'OFFLINE',
  /** Connected Percentage */
  Usage = 'USAGE',
  /** Connected Duration (in seconds) */
  ConnectDuration = 'CONNECT_DURATION',
  /** Number of connection events */
  Connect = 'CONNECT',
  /** Number of disconnect events */
  Disconnect = 'DISCONNECT'
}

/** remote.it User */
export type User = {
   __typename?: 'User';
  /** User ID */
  id: Scalars['ID'];
  /** Email Address */
  email?: Maybe<Scalars['String']>;
  /** authhash */
  authhash?: Maybe<Scalars['String']>;
  /** User creation datetime (ISO 8601 format date string) */
  created?: Maybe<Scalars['DateTime']>;
  /** Last login datetime (ISO 8601 format date string) */
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Language (ISO 639-1 two letter code) */
  language?: Maybe<Scalars['String']>;
  /** Time zone (IANA time zone format) */
  timezone?: Maybe<Scalars['String']>;
  /** Access List */
  access: Array<Access>;
  /** Organization */
  organization?: Maybe<Organization>;
  /** Select Account/Organization */
  account?: Maybe<User>;
  /** User metadata */
  attributes: Scalars['Object'];
  /** User Licenses */
  licenses: Array<License>;
  /** User Limits */
  limits: Array<Limit>;
  /** Account Membership */
  member: Array<Access>;
  /** Organization Membership */
  membership: Array<OrganizationMember>;
  /** User connection sessions */
  sessions?: Maybe<Array<Session>>;
  /**
   * Yoics ID (Internal remote.it use only)
   * @deprecated Use email instead
   */
  yoicsId?: Maybe<Scalars['String']>;
  /** API Key */
  apiKey?: Maybe<ApiKey>;
  /** Access Keys */
  accessKeys: Array<AccessKey>;
  /** Contacts who the current user has shared devices/services. */
  contacts: Array<UserReference>;
  /** Select Devices */
  device?: Maybe<Array<Device>>;
  /** Select Services */
  service?: Maybe<Array<Service>>;
  /** User Devices */
  devices: PaginatedDeviceResponse;
  /** Endpoint */
  endpoints?: Maybe<Array<ServiceEndpoint>>;
  /** User Events */
  events: PaginatedLogEventResponse;
  /** User Events URL */
  eventsUrl: Scalars['String'];
  /** Notification Settings */
  notificationSettings?: Maybe<NotificationSettings>;
  /** Invoice list */
  invoices: Array<Invoice>;
  /** Registration Code */
  registrationCode?: Maybe<Scalars['String']>;
  /** Report URL */
  report: Scalars['Any'];
};


/** remote.it User */
export type UserAccountArgs = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};


/** remote.it User */
export type UserAttributesArgs = {
  name?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};


/** remote.it User */
export type UserDeviceArgs = {
  id: Array<Scalars['String']>;
};


/** remote.it User */
export type UserServiceArgs = {
  id: Array<Scalars['String']>;
};


/** remote.it User */
export type UserDevicesArgs = {
  sort?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['ID']>;
  from?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  application?: Maybe<Scalars['Int']>;
  hardwareId?: Maybe<Scalars['String']>;
  id?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['Boolean']>;
  platform?: Maybe<Array<Scalars['Int']>>;
  state?: Maybe<Scalars['String']>;
};


/** remote.it User */
export type UserEndpointsArgs = {
  id?: Maybe<Array<Scalars['String']>>;
  state?: Maybe<Scalars['String']>;
};


/** remote.it User */
export type UserEventsArgs = {
  after?: Maybe<Scalars['ID']>;
  from?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['Int']>;
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<EventType>>;
};


/** remote.it User */
export type UserEventsUrlArgs = {
  maxDate?: Maybe<Scalars['DateTime']>;
  minDate?: Maybe<Scalars['DateTime']>;
  types?: Maybe<Array<EventType>>;
};


/** remote.it User */
export type UserRegistrationCodeArgs = {
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['Int']>;
  services?: Maybe<Array<ServiceInput>>;
};


/** remote.it User */
export type UserReportArgs = {
  name: Scalars['String'];
  parameters?: Maybe<Scalars['Object']>;
};

/** remote.it User Reference */
export type UserReference = {
   __typename?: 'UserReference';
  /** User ID */
  id: Scalars['ID'];
  /** Email Address */
  email?: Maybe<Scalars['String']>;
};
