import { createMuiTheme } from '@material-ui/core'
import theme from './theme'

export default createMuiTheme({
  // zIndex: {
  //   modal: 20000,
  // },
  palette: {
    primary: { main: theme.colors.primary },
    secondary: { main: theme.colors.secondary },
    error: { main: theme.colors.danger },
  },
  typography: { fontFamily: 'Roboto, san-serif' },
  overrides: {
    MuiListItemText: { secondary: { fontSize: theme.fonts.sm } },
    MuiDivider: { root: { backgroundColor: theme.colors.grayLighter } },
    //   MuiFormHelperText: { root: { fontSize: 10 } },
    MuiButton: {
      containedSecondary: {
        color: 'white',
      },
      label: {
        fontSize: theme.fonts.sm,
        fontWeight: 600,
        letterSpacing: 1,
      },
      sizeLarge: {
        padding: `9px 20px`,
      },
      sizeSmall: {
        '& .MuiButton-label': { fontSize: theme.fonts.xs },
      },
      //     root: {
      //       color: colors.grayDark,
      //       backgroundColor: colors.grayLightest,
      //       padding: `${spacing.sm}px ${spacing.md}px`,
      //       '& .MuiSvgIcon-root': { marginLeft: spacing.sm },
      //     },
      //     text: { padding: `${spacing.sm}px ${spacing.md}px` },
      //     outlined: { borderColor: colors.grayLighter },
    },
    //   MuiListItem: {
    //     root: { opacity: 1 },
    //     button: {
    //       paddingLeft: spacing.sm,
    //       paddingRight: spacing.sm,
    //       '&:hover, &:focus': { backgroundColor: colors.grayLightest },
    //     },
    //     container: {
    //       '& .MuiListItemSecondaryAction-root': {},
    //       '&:hover, &:focus': {
    //         '& .MuiListItemSecondaryAction-root.hidden': { display: 'block' },
    //         '& .hoverHide': { display: 'none' },
    //       },
    //     },
    //   },
    //   MuiListItemSecondaryAction: { root: { right: gutters } },
    //   MuiListItemIcon: { root: { justifyContent: 'center' } },
    //   MuiInput: {
    //     root: {
    //       '&.Mui-disabled': {
    //         color: colors.grayDarker,
    //         '& svg': { display: 'none' },
    //       },
    //     },
    //     underline: { '&.Mui-disabled:before': { borderColor: colors.grayLight } },
    //   },
    //   MuiLink: {
    //     root: { padding: `${spacing.xs}px ${spacing.sm}px` },
    //     underlineHover: {
    //       '&:hover': {
    //         backgroundColor: colors.grayLightest,
    //         borderRadius: 10,
    //         textDecoration: 'none',
    //         cursor: 'pointer',
    //       },
    //     },
    //   },
    MuiTypography: {
      body1: {
        fontSize: theme.fonts.md,
        lineHeight: 1.8,
      },
      // h1: {},
      // h2: {},
      h3: {
        color: theme.colors.primary,
        fontSize: theme.fonts.lg,
        marginTop: theme.spacing.lg,
        marginBottom: theme.spacing.sm,
      },
      h4: {
        fontWeight: 600,
        fontSize: theme.fonts.md,
        marginTop: theme.spacing.lg,
        marginBottom: theme.spacing.sm,
      },
      //     subtitle1: {
      //       fontSize: fontSizes.sm,
      //       fontFamily: 'Roboto Mono',
      //       color: colors.gray,
      //       padding: `${spacing.lg}px ${gutters}px 0`,
      //       marginLeft: spacing.xxs,
      //       textTransform: 'uppercase',
      //       letterSpacing: 3,
      //       fontWeight: 500,
      //     },
      //     caption: {
      //       fontSize: '11px',
      //       color: colors.grayDark,
      //       lineHeight: '1.5em',
      //     },
      //     colorTextSecondary: {
      //       color: colors.grayDark,
      //     },
    },
    //   MuiDialogContent: { root: { margin: `${spacing.md}px ${gutters}px`, padding: 0 } },
    //   MuiDialogActions: { root: { margin: `${spacing.md}px ${gutters}px`, padding: 0 } },
    //   MuiFilledInput: {
    //     root: {
    //       backgroundColor: colors.grayLightest,
    //       '&.Mui-disabled': {
    //         backgroundColor: colors.grayLighter,
    //       },
    //     },
    //   },
  },
})
