import React from 'react'
import { r3 } from '../services/remote.it'

/**
 * Returns a list of shared contacts (and emails) for the list of device
 * IDs provided that represent the collective and de-duplicated list of
 * accounts that have been shared with this set of devices.
 *
 * In simple terms; provide an array of device IDs and get back an de-duplicated
 * array of any email addresses that has been shared with any one of the devices.
 */
export default function useSharedAccounts(
  deviceIDs: string[]
): {
  emails: string[]
  loading: boolean
  error: Error | null
  refetch: () => Promise<void>
} {
  const [emails, setEmails] = React.useState<string[]>([])
  const [error, setError] = React.useState<Error | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  async function refetch(): Promise<void> {
    setError(null)
    setLoading(true)
    let resp: any
    try {
      resp = await r3.post('/developer/device/share/list/merged', {
        devices: deviceIDs,
      })
      setEmails(resp?.shares?.map(s => s.email))
    } catch (error) {
      setError(error)
    }
    setLoading(false)
    return resp
  }

  React.useEffect(() => {
    refetch()
  }, [deviceIDs])

  return { emails, loading, error, refetch }
}
