import React, { useEffect, useState } from 'react'
import styles from './SplashScreen.module.css'
import laptopImage from '../../images/laptop_image.png'
import { Logo } from '../Logo'
import { Box, Typography, Button } from '@material-ui/core'
import { Icon } from '../Icon'

function HeaderLogo(): JSX.Element {
  return (
    <Box justifyContent="flex-start" display="flex" className={styles.splashHeader}>
      <Box width={1200} justifyContent="flex-start" margin="auto">
        <Logo white={true} width={140} />
      </Box>
    </Box>
  )
}
const FEATURE = [
  { item: 'Peer-to-peer connections' },
  { item: 'On demand connections' },
  { item: 'Persistent URLs ' },
  { item: 'Enable remote access' },
  { item: 'Share and access device lists' },
  { item: 'Advanced search and filters' },
  { item: 'System notifications' },
  { item: 'Includes remote.it CLI' },
]

function SplashContent(): JSX.Element {
  const [platformName, setPlatformName] = useState('')
  const [platformUrl, setPlatformUrl] = useState('')
  const downloadUrl = 'https://downloads.remote.it/desktop/latest/'

  useEffect(() => {
    let platform = navigator.platform.toLowerCase()
    if (platform.includes('mac')) {
      platform = 'Mac'
      setPlatformName(platform)
      setPlatformUrl(`${downloadUrl}remoteit-installer.dmg`)
    } else if (platform.includes('win')) {
      platform = 'Windows'
      setPlatformName(platform)
      setPlatformUrl(`${downloadUrl}remoteit-installer.exe`)
    } else if (platform.includes('raspberry')) {
      platform = 'Raspberry Pi'
      setPlatformName(platform)
      setPlatformUrl(`${downloadUrl}remote.itPi.img.zip`)
    } else {
      platform = 'your system'
      setPlatformName(platform)
      setPlatformUrl('https://remote.it/downloads')
    }
  })

  const downloadHandler = e => {
    window.location.href = platformUrl
  }

  return (
    <>
      <Box className={styles.splashBackground}>
        <Box justifyContent="space-between" display="flex" className={styles.splashContent}>
          <HeaderLogo />
          <Box className={styles.splashLeftContent}>
            <Typography variant="h4" className={styles.titleDownload}>
              Get remote.it for {platformName}
            </Typography>
            <Box width="100%" className={styles.buttonBox}>
              <Button variant="contained" className={styles.btnDownload} onClick={downloadHandler} size="large">
                Download
              </Button>
              <div className={styles.description}>
                or use it in{' '}
                <a href="/#devices" style={{ textDecoration: 'underline', color: 'white' }} target="_self">
                  your browser <Icon name="arrow-circle-right" weight="regular" fixedWidth />
                </a>
              </div>
            </Box>
          </Box>
          <Box>
            <a href="#" style={{ height: '20%' }}>
              <img src={laptopImage} alt="remote.it" style={{ maxWidth: '600px' }} />
            </a>
          </Box>
        </Box>
        
      </Box>
      <Box className={styles.splashBottomContent}>
        <h4 style={{ fontSize: '30px', fontWeight: 'bold' }}>
          <span style={{ textDecoration: 'underline #f4b363 4px' }}>Powerful features</span> only available on desktop:
        </h4>
        <ul>
          {FEATURE.map(item => (
            <li key={item.item}>
              <div className={styles.li} style={{ fontSize: '18px', fontWeight: 'bold' }}>
                {item.item}
              </div>
            </li>
          ))}
        </ul>
        <div className={styles.clear}></div>
      </Box>
    </>
  )
}

function FooterContent(): JSX.Element {
  return (
    <Box my={3} className={styles.splashFooter}>
      <Typography className="mt-xl mb-sm center txt-md">Other supported platforms:</Typography>
      <Box className="my-sm pr-sm center" style={{ fontSize: 50 }}>
        <span className="fab fa-apple fa-xs ml-lg"></span>
        <span className="fab fa-windows fa-xs ml-lg"></span>
        <span className="fab fa-aws fa-xs ml-lg"></span>
        <span className="fab fa-raspberry-pi fa-xs ml-lg"></span>
        <span className="fab fa-linux fa-xs ml-lg"></span>
        <span className="fab fa-google-play fa-xs ml-lg"></span>
        <span className="fab fa-app-store-ios fa-xs ml-lg"></span>
      </Box>
      <Typography className="my-sm center txt-sm">
        Get them from{' '}
        <a href="https://remote.it/downloads" target="_blank" className="downloadLink">
          our downloads page
        </a>
        .
      </Typography>
    </Box>
  )
}

export function SplashScreen(): JSX.Element {
  return (
    <div className={styles.splashContainer}>
      <SplashContent />
      <FooterContent />
    </div>
  )
}
