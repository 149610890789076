import { IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { Icon } from '../Icon'

export interface ExperienceToggleProps {
  experience: string
}

export function ExperienceToggle({
  experience,
}: ExperienceToggleProps): JSX.Element {
  const { t } = useTranslation()
  const { auth } = useDispatch<any>() // TODO: fix type defs
  const { preferences } = useSelector((state: ApplicationState) => ({
    preferences: state.auth.preferences,
  }))
  const key = `experience.${experience}`
  const enabled = preferences && preferences[key] && preferences[key] === 'true'

  function handleTogglePreference(): void {
    auth.storePreference({
      key,
      value: preferences[key] && preferences[key] === 'true' ? 'false' : 'true',
    })
  }

  return (
    <Tooltip
      title={
        enabled
          ? t('global.experience-toggle.disable-message', {
              experience,
              enabled,
            }).toString()
          : t('global.experience-toggle.enable-message', {
              experience,
              enabled,
            }).toString()
      }
    >
      <IconButton onClick={handleTogglePreference}>
        <Icon
          name="sparkles"
          color={enabled ? 'warning' : 'gray-dark'}
          className="txt-lg"
        />
      </IconButton>
    </Tooltip>
  )
}
