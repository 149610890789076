import React from 'react'
import { makeStyles, Typography, List, ListItem, ListItemIcon, Divider, Button, Paper, Box } from '@material-ui/core'
import { spacing, fontSizes, colors, radius } from '../styling'
import { Icon } from './Icon'
import classnames from 'classnames'

type Props = {
  name: string
  description: string
  price?: string
  caption: string | React.ReactElement
  note?: string
  feature?: string
  features?: string[]
  button: string
  allowUpdate?: boolean
  disabled?: boolean
  selected?: boolean
  loading?: boolean
  onSelect?: () => void
}

export const PlanCard: React.FC<Props> = ({
  name,
  description,
  price,
  caption,
  note,
  feature,
  features = [],
  button,
  allowUpdate,
  disabled,
  selected,
  loading,
  onSelect,
}) => {
  const css = useStyles()
  return (
    <Paper square elevation={0} style={{ marginLeft: '5%' }}>
      <div className={classnames(css.card, selected && css.selected)}>
        {selected && <header>Current plan</header>}
        <div className={css.plan}>
          <Typography variant="h6" style={{ fontWeight: 400, fontSize: 19, textTransform: 'capitalize' }}>
            {name}
          </Typography>
          <Typography variant="caption" className={css.caption}>
            {description}
          </Typography>
        </div>
        <Divider />
        <div className={css.price}>
          <Box minHeight={70}>
            <Box minHeight={20}>{price !== undefined && <Typography variant="h6">{price}</Typography>}</Box>
            <Typography variant="body2">{caption}</Typography>
            {note !== undefined && (
              <Typography variant="caption" className={css.caption}>
                {note}
              </Typography>
            )}
          </Box>
          <Button
            onClick={onSelect}
            style={{ borderRadius: 50, maxHeight: 30, paddingLeft: 15, paddingRight: 15 }}
            color="primary"
            variant={'contained'}
            disabled={loading || disabled}
          >
            {loading ? 'Processing...' : button}
          </Button>
        </div>
        <div className={css.features}>
          <List dense>
            {feature && (
              <Item>
                <b>{feature}</b>
              </Item>
            )}
            {features.map((f, index) => (
              <Item key={index}>{f}</Item>
            ))}
          </List>
        </div>
      </div>
    </Paper>
  )
}

export const Item: React.FC = ({ children }) => {
  return (
    <ListItem disableGutters dense>
      <ListItemIcon>
        <Icon name="check" color="primary" />
      </ListItemIcon>
      {children}
    </ListItem>
  )
}

const useStyles = makeStyles({
  card: { display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' },
  selected: {
    backgroundColor: colors.primaryHighlight,
    borderRadius: radius,
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    '& .MuiDivider-root': { backgroundColor: colors.primaryLighter },
    '& header': {
      width: '100%',
      position: 'absolute',
      textTransform: 'uppercase',
      textAlign: 'center',
      backgroundColor: colors.primaryLight,
      letterSpacing: spacing.xxs,
      fontSize: fontSizes.xxxs,
      color: colors.white,
      fontWeight: 600,
      lineHeight: 2,
    },
  },
  plan: {
    padding: spacing.md,
    paddingTop: spacing.xxl,
    textAlign: 'center',
    '& h2': {
      textTransform: 'capitalize',
      fontSize: fontSizes.lg,
    },
  },
  price: {
    padding: spacing.md,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 150,
    '& .MuiButton-root': { marginTop: spacing.sm },
  },
  select: {
    marginBottom: spacing.sm,
    width: '100%',
  },
  features: {
    paddingBottom: spacing.lg,
    color: colors.grayDarker,
    fontSize: fontSizes.sm,
    maxWidth: 250,
    lineHeight: 1.3,
    '& b': { fontWeight: 400, color: colors.grayDarkest },
    '& .MuiListItemIcon-root': { minWidth: 40 },
  },
  caption: {
    fontWeight: 400,
    fontSize: 11,
    color: '#999',
  },
})
