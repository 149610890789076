import React from 'react'
import { Input } from './Input'
import { Button } from './Button'
import { useTranslation } from 'react-i18next'
import { Icon } from './Icon'
import { MFAPhoneForm } from './MFAPreference/MFAPhoneForm'
import { makeStyles } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState, Dispatch } from '../store'

type Props = {
  cancelEditPhone: () => void
  setShowEnableSelection: (event: any) => void
  successfulPhoneUpdate: (orginalNumber: any, newNumber: any) => Promise<void>
  sendVerifyPhone: (event: any) => void
  hasOldSentVerification: boolean
  verificationCode: string
  loading: boolean
  resendCode: (event: any) => void
  setCancelShowVerificationCode:  (event: any) => void
}

export const MFAConfigureSms: React.FC<Props> = ({
  cancelEditPhone,
  successfulPhoneUpdate,
  sendVerifyPhone,
  hasOldSentVerification,
  verificationCode,
  loading,
  resendCode,
  setCancelShowVerificationCode
}) => {
  const { t } = useTranslation()
  const css = useStyles()
  const { mfa } = useDispatch<Dispatch>()
  const { showPhone, showVerificationCode , phone_number} = useSelector((state: ApplicationState) => ({
    showPhone: state.mfa.showPhone,
    showVerificationCode: state.mfa.showVerificationCode,
    phone_number: state.auth.AWSUser.phone_number
  }))
  return (
    <>
      {showPhone && (
        <MFAPhoneForm
          onClose={() => {
            cancelEditPhone()
  setShowSMSConfig: (event: any) => void
            mfa.set({ showSMSConfig: false})
          }}
          onSuccess={successfulPhoneUpdate}
        />
      )}
      {showVerificationCode && (
        <>
          <div>
            <form onSubmit={sendVerifyPhone} style={{ maxWidth: '360px' }}>
              <div className={css.modalMessage}>
                <Icon name="info-circle" className={css.icon} fixedWidth />
                <div className={css.message}>
                  {hasOldSentVerification
                    ? t('pages.auth-mfa.old-verification-sent')
                    : t('pages.auth-mfa.verification-sent', { number: phone_number.toString()})
                  }
                   
                </div>
              </div>

              <div className="my-lg w-auto">
                <Input
                  autoCorrect="off"
                  autoCapitalize="none"
                  autoComplete="off"
                  required
                  onChange={e => mfa.set({ verificationCode: e.currentTarget.value.trim() })}
                  value={verificationCode}
                  className="mr-sm"
                  placeholder={t('pages.auth-mfa.verification-code-placeholder')}
                />
              </div>
              <div className="mt-lg">
                <Button
                  loading={loading}
                  disabled={verificationCode === '' || verificationCode.length < 6}
                  type="submit"
                >
                  {t('global.actions.submit')}
                </Button>
                <Button
                  onClick={(event) => {
                    cancelEditPhone()
                  }}
                  link
                >
                  {t('global.actions.cancel')}
                </Button>
              </div>
            </form>
            <div className="my-md">
              {t('pages.auth-mfa.resend-verification-code')}{' '}
              <a onClick={resendCode} data-force-resend="true">
                {t('pages.auth-mfa.resend-verification-code-button')}
              </a>
            </div>
          </div>
          <div>
            <a
              onClick={() => {
                mfa.set({ 
                  showPhone: true, 
                  showVerificationCode: false 
                })
                setCancelShowVerificationCode(true)
              }}
            >
              {t('pages.auth-mfa.update-mfa-phone-number')}
            </a>
          </div>
        </>
      )}
    </>
  )
}

const useStyles = makeStyles({
  modalMessage: {
    backgroundColor: '#e9f6fd',
    padding: 11,
    display: 'flex',
    borderRadius: 4,
    fontSize: 14,
    color: '#3aa1db',
    marginBottom: 10,
    minWidth:  500
  },
  message: {
    color: '',
    fontWeight: 500
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    display: 'flex',
    fontSize: '22px',
    opacity: '0.9',
  },
})
