import React, { Component } from 'react'
import { TextBlock } from '../TextBlock'
import {
  axiosGraphQL,
  GET_USER_METADATA,
  UPDATE_USER_METADATA,
} from '../../../src/utils/graphQL'
import { NotificationMode } from './NotificationMode'
import { LoadingMessage } from '../LoadingMessage'
import { Trans } from 'react-i18next'
import { Typography } from '@material-ui/core'

interface State {
  onlineDeviceNotification: boolean
  onlineSharedDeviceNotification: boolean
  loading: boolean
  error?: string
  notificationUrl?: string
  notificationEmail: boolean,
  urlNotifications: boolean
}
export class NotificationSubscriptions extends Component<{}, State> {
  state: State = {
    onlineDeviceNotification: false,
    onlineSharedDeviceNotification: false,
    loading: true,
    error: '',
    notificationUrl: '',
    notificationEmail: false,
    urlNotifications: false
  }
  setPortalUrl = async (): Promise<void> => {
    const graphQL = await axiosGraphQL()
    graphQL
      ?.post('', {
        query: UPDATE_USER_METADATA,
        variables: { portalUrl: window.location.origin },
      })
      .catch(error => {
        console.error('Failed to Update PortalURL:', error)
      })
  }
  componentDidMount = async () => {
    //fetch data
    this.setState({ loading: true, error: '' })

    const graphQL = await axiosGraphQL()
    graphQL
      ?.post('', { query: GET_USER_METADATA })
      .then(response => {
        const metadata = response.data.data.login.notificationSettings
        this.setState({
          loading: false,
          onlineDeviceNotification: true,
          onlineSharedDeviceNotification: true,
          urlNotifications: metadata.urlNotifications,
          notificationEmail: metadata.emailNotifications,
          notificationUrl: metadata.notificationUrl
        })
        this.setPortalUrl()
      })
      .catch(error => {
        this.setState({ loading: false, error })
        console.error('NotificationSubscriptions Fetch Error:', error)
      })
  }

  updateNotificationValue = obj => {
    let device = this.state.onlineDeviceNotification
    let sharedDevice = this.state.onlineSharedDeviceNotification
    if (obj.onlineDeviceNotification !== undefined) {
      device = obj.onlineDeviceNotification
    }
    if (obj.onlineSharedDeviceNotification !== undefined) {
      sharedDevice = obj.onlineSharedDeviceNotification
    }

    this.setState({
      onlineDeviceNotification: device,
      onlineSharedDeviceNotification: sharedDevice,
    })
  }

  render() {
    const {
      onlineDeviceNotification,
      onlineSharedDeviceNotification,
      loading,
      notificationUrl,
      notificationEmail,
      urlNotifications
    } = this.state

    return (
      <>
        <TextBlock>
          <Trans i18nKey="pages.notification-subscriptions.description" />
        </TextBlock>
        {loading && <LoadingMessage className="center" />}
        
        <div className="bg-gray-lightest py-md pl-md mt-md">
          <Typography className="fg-1 ml-sm">
            Configure how you would you like to receive notifications when
            devices come online or offline
          </Typography>
          <NotificationMode
            onlineDeviceNotification={onlineDeviceNotification}
            onlineSharedDeviceNotification={onlineSharedDeviceNotification}
            urlNotifications={urlNotifications}
            notificationUrl={notificationUrl}
            notificationEmail={notificationEmail}
          />
        </div>
      </>
    )
  }
}
