import React from 'react'
import { useTranslation } from 'react-i18next'
import { GenericLogEvent } from '../../services/apolloClient'
import { Icon } from '../Icon'

export function EventIcon(item: GenericLogEvent): JSX.Element {
  const { t } = useTranslation()
  let color: BrandColor = 'gray'
  let icon = ''
  let title = ''

  if (item.type === 'DEVICE_STATE') {
    if (item.state === 'active') {
      icon = 'check-circle'
      color = 'success'
      title = t('pages.logs.event-types-title.DEVICE_STATE_active')
    } else {
      icon = 'minus-circle'
      color = 'gray-lighter'
      title = t('pages.logs.event-types-title.DEVICE_STATE_inactive')
    }
  } else if (item.type === 'DEVICE_CONNECT') {
    if (item.state === 'connected') {
      icon = 'dot-circle'
      color = 'primary'
      title = t('pages.logs.event-types-title.DEVICE_CONNECT_connected')
    } else {
      icon = 'times-circle'
      color = 'gray'
      title = t('pages.logs.event-types-title.DEVICE_CONNECT_disconnected')
    }
  } else if (item.type === 'DEVICE_SHARE') {
    if (item.action !== 'remove') {
      icon = 'user-plus'
      color = 'success'
      title = t('pages.logs.event-types-title.DEVICE_SHARE_true')
    } else {
      icon = 'user-minus'
      color = 'danger'
      title = t('pages.logs.event-types-title.DEVICE_SHARE_false')
    }
  }
  return <Icon name={icon} fixedWidth title={title} color={color} scale="lg" />
}
