import classNames from 'classnames'
import React, { forwardRef } from 'react'
import styles from './Input.module.css'

export interface Props
  extends React.HTMLProps<HTMLInputElement | HTMLTextAreaElement> {
  block?: boolean
  className?: string
  large?: boolean
  small?: boolean
  textarea?: boolean
}

export const Input = forwardRef(
  (
    {
      block = false,
      className = '',
      large = false,
      small = false,
      textarea = false,
      ...props
    }: Props,
    ref: any
  ) => {
    const classes = classNames(className, styles.input, 'lh-md', {
      [styles.block]: block,
      [styles.large]: large,
      [styles.small]: small,
      [styles.textarea]: textarea,
    })

    const Tag = textarea ? 'textarea' : 'input'

    return <Tag {...props} ref={ref} className={classes} />
  }
)
