import { axiosGraphQL, CREDITCARD, SUBSCRIBE, UNSUBSCRIBE, UPDATE_SUBSCRIPTION } from '../utils/graphQL'

//graphQLSubscribe, graphQLUnsubscribe, graphQLCreditCard

export async function graphQLUpdateSubscription(params: any) {
  const graphQL = await axiosGraphQL()
  return graphQL?.post('', {
    query: UPDATE_SUBSCRIPTION,
    variables: {
      ...params,
    },
  })
}

export async function graphQLSubscribe(params: IPurchase) {
  const graphQL = await axiosGraphQL()
  return graphQL?.post('', {
    query: SUBSCRIBE,
    variables: {
      ...params,
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    },
  })
}

export async function graphQLCreditCard() {
  const graphQL = await axiosGraphQL()

  return graphQL?.post('', {
    query: CREDITCARD,
    variables: {
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    },
  })
}

export async function graphQLUnsubscribe() {
  const graphQL = await axiosGraphQL()
  return graphQL?.post('', {
    query: UNSUBSCRIBE,
  })
}
