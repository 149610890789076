import { Box } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { Button } from '../Button'
import { ButtonIcon } from '../ButtonIcon'
import { ContactsList } from '../ContactsList'
import { ExperienceRenderer } from '../ExperienceRenderer'
import { ExperienceToggle } from '../ExperienceToggle'
import { HelpIcon } from '../HelpIcon'
import { PageHeader } from '../PageHeader'
import { RefreshButton } from '../RefreshButton'
import { SignInMessage } from '../SignInMessage'
import { useTranslation } from 'react-i18next'

export function ContactsPage(): JSX.Element {
  const { contacts: actions } = useDispatch<any>() // TODO: fix type defs
  const { contacts, signInStarted } = useSelector(
    (state: ApplicationState) => ({
      contacts: state.contacts.all,
      signInStarted: state.auth.signInStarted,
    })
  )

  async function handleFetch(): Promise<void> {
    await actions.fetch()
  }

  async function handleCreate(contact: ContactFields): Promise<void> {
    await actions.create(contact)
    handleFetch()
  }

  async function handleUpdate(contact: ContactFields): Promise<void> {
    await actions.update(contact)
    handleFetch()
  }

  async function handleInvite({
    email,
    id,
  }: {
    email: string
    id: string
  }): Promise<void> {
    await actions.invite({ email, id })
  }

  async function handleRemove(email: string): Promise<void> {
    await actions.destroy(email)
    handleFetch()
  }

  // const newExperience = true

  if (signInStarted) return <SignInMessage />

  return (
    <Box height="100%">
      <PageHeader
        title="Contacts"
        actions={
          <>
            <ExperienceToggle experience="contacts" />
            <HelpIcon
              title="View contacts documentation"
              href="https://support.remote.it/hc/en-us/articles/360048707892-Contacts-and-Sharing"
            />
            <RefreshButton onClick={() => window.weaved.getContacts()} />
          </>
        }
      />
      <ExperienceRenderer
        experience="contacts"
        next={
          <ContactsList
            contacts={contacts}
            create={handleCreate}
            fetch={handleFetch}
            invite={handleInvite}
            remove={handleRemove}
            update={handleUpdate}
          />
        }
        current={<LegacyContactsPage />}
      />
    </Box>
  )
}

function LegacyContactsPage(): JSX.Element {
  const { t } = useTranslation()
  React.useEffect(() => {
    if (window.renderLegacyContactsPage) window.renderLegacyContactsPage()
  }, [])

  return (
    <>
      <Box my={8}>
        <Button type="button" onClick={() => window.showCreateCustomerModal()}>
          <ButtonIcon icon="plus" />
          {t('pages.contacts.contact-button-label.create')}
        </Button>
      </Box>
      <div id="customers-table-content" className="of-auto"></div>
    </>
  )
}
