import {
  Button,
  Chip,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../Icon'

export function ContactCardActions({
  allServices = [],
  email,
  open,
  saving,
  scripting,
  sharedServices = [],
  setOpen,
  unshare,
  updateSharing,
}: {
  allServices: SimplifiedService[]
  email: string
  open: boolean
  saving: boolean
  scripting: boolean
  sharedServices: string[]
  setOpen: (open: boolean) => void
  unshare: (email: string) => void
  updateSharing: () => Promise<void>
}): JSX.Element {
  const { t } = useTranslation()
  const shared = sharedServices.length
  const total = allServices.length

  return (
    <ListItemSecondaryAction>
      {open ? (
        <ShareSaveActions
          onCancel={() => setOpen(false)}
          onSave={() => updateSharing()}
          saving={saving}
        />
      ) : (
        <>
          <ShareDetails scripting={scripting} shared={shared} total={total} />
          {shared || scripting ? (
            <span className="br bc-gray-lighter mx-lg"> </span>
          ) : null}
          <Tooltip
            enterDelay={500}
            title={t('pages.device-share.remove-share-label', {
              email,
            }).toString()}
          >
            <IconButton
              aria-label={t('pages.device-share.remove-share-label', {
                email,
              })}
              onClick={e => {
                e.preventDefault()
                unshare(email)
              }}
              disabled={saving}
            >
              <Icon name="trash-alt" fixedWidth />
            </IconButton>
          </Tooltip>
          <Tooltip
            enterDelay={500}
            title={t('pages.device-share.edit-share-label', {
              email,
            }).toString()}
          >
            <IconButton
              aria-label={t('pages.device-share.edit-share-label', {
                email,
              })}
              onClick={() => setOpen(!open)}
              disabled={saving}
            >
              <Icon name="pencil" fixedWidth />
            </IconButton>
          </Tooltip>
        </>
      )}
    </ListItemSecondaryAction>
  )
}

function ShareDetails({
  scripting,
  shared,
  total,
}: {
  scripting: boolean
  shared: number
  total: number
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      {scripting && (
        <Tooltip
          enterDelay={500}
          title={t('pages.device-share.scripting-enabled-icon-label').toString()}
        >
          <IconButton
            aria-label={t('pages.device-share.scripting-enabled-icon-label')}
          >
            <Icon name="scroll" fixedWidth />
          </IconButton>
        </Tooltip>
      )}
      {Boolean(shared) && (
        <Tooltip
          enterDelay={500}
          title={t('pages.device-share.shared-service-count-icon-label', {
            shared,
            total,
          }).toString()}
        >
          <IconButton
            aria-label={t(
              'pages.device-share.shared-service-count-icon-label',
              { shared, total }
            )}
          >
            <Chip label={shared} size="small" variant="outlined" />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export function ShareSaveActions({
  onCancel,
  onSave,
  saving,
}: {
  onCancel: () => void
  onSave: () => void
  saving: boolean
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Tooltip
        enterDelay={500}
        title={t('pages.device-share.cancel-changes-label').toString()}
      >
        <Button
          disabled={saving}
          onClick={onCancel}
          variant="text"
          className="mr-md"
        >
          {t('global.actions.cancel')}
        </Button>
      </Tooltip>
      <Tooltip
        enterDelay={500}
        title={t('pages.device-share.save-changes-label').toString()}
      >
        <Button
          color="primary"
          onClick={onSave}
          disabled={saving}
          variant="contained"
        >
          <span className="mr-md">{t('global.actions.share')}</span>
          {saving ? (
            <Icon name="spinner-third" spin fixedWidth />
          ) : (
            <Icon name="check" fixedWidth />
          )}
        </Button>
      </Tooltip>
    </>
  )
}
