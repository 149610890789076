import React, { useState } from 'react'
import { ApplicationState } from '../../store'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button } from '../Button'
import { startsWith } from 'lodash'
import 'react-phone-input-2/lib/material.css'
import './style.css'
import PhoneInput from 'react-phone-input-2'
import i18n from '../../i18n'
import { Typography, makeStyles } from '@material-ui/core'

export interface Props {
  onClose: () => void
  onSuccess: (orignalNumber, newNumber) => void
}
export type MFAPhoneProps = Props & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  AWSUser: state.auth.AWSUser,
  mfaMethod: state.auth.mfaMethod,
})

const mapDispatch = (dispatch: any) => ({
  updatePhone: dispatch.auth.updatePhone,
  checkSignIn: dispatch.auth.checkSignIn,
})
export const MFAPhoneForm = connect(
  mapState,
  mapDispatch
)(({ AWSUser, mfaMethod, updatePhone, onClose, onSuccess }: MFAPhoneProps) => {
  const { t } = useTranslation()
  const css = useStyles()
  const originalPhone = AWSUser.phone_number
  const [phone, setPhone] = useState<string>(AWSUser && AWSUser.phone_number)
  const [validPhone, setValidPhone] = React.useState<boolean>(AWSUser && AWSUser.phone_number)
  const [error, setError] = React.useState<string | null>(null)
  const [message, setMessage] = React.useState<string | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const country = i18n.language === 'ja' ? 'jp' : 'us'
  const handleOnChange = (value, data, event) => {
    const newValue = value.replace(/[^0-9]+/g, '')

    if (newValue !== '' && startsWith(newValue, data.dialCode)) {
      setValidPhone(true)
    } else {
      setValidPhone(false)
    }
    if (phone !== newValue) {
      setPhone('+' + newValue)
    }
  }
  const updateUsersPhone = event => {
    event.preventDefault()
    if (AWSUser.phone_number !== phone) {
      setError(null)
      setMessage(null)
      setLoading(true)
      // console.log('Update phone number')
      updatePhone(phone)
        .then(() => {
          onSuccess(originalPhone, phone)
        })
        .catch(error => {
          console.error(error)
          setError(t(`pages.auth-mfa.errors.${error.code}`))
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      onSuccess(originalPhone, phone)
    }
  }
  return (
    <>
      {error && <div className="danger my-md fw-bold">{error}</div>}
      {message && <div className="success my-md fw-bold">{message}</div>}
      {AWSUser && AWSUser.phone_number_verified && AWSUser.phone_number && (
        <>
          {mfaMethod === 'SMS_MFA' && (
            <div className="warning my-md fw-bold">{t('pages.auth-mfa.phone-update-warning')}</div>
          )}
          <div className="my-md lh-md ">{t('pages.auth-mfa.update-phone-number')}</div>
        </>
      )}
      {AWSUser && !AWSUser.phone_number && (
        <div className="my-md lh-md ">
          <b>{t('pages.auth-mfa.enter-phone-number')}</b>
        </div>
      )}
      <form>
        <PhoneInput
          value={phone}
          enableSearch
          country={country}
          preserveOrder={['preferredCountries', 'onlyCountries']}
          preferredCountries={['us', 'jp']}
          searchPlaceholder={t('pages.auth-mfa.phone-country-search-placeholder')}
          placeholder={t('pages.auth-mfa.phone-number-placeholder')}
          onChange={(value, data, event) => {
            handleOnChange(value, data, event)
          }}
          inputProps={{
            required: true,
          }}
        />
        {AWSUser.phone_number_verified && AWSUser.phone_number && AWSUser.phone === phone && (
          <div className="success my-sm">{t('pages.auth-mfa.phone-verified')}</div>
        )}
        <div className="my-sm">
          <Typography variant="caption" className={css.caption}>
            {t('pages.auth-mfa.phone-tip-info')}
          </Typography>
        </div>
        <div className="mt-lg">
          <Button
            disabled={phone === '' || !validPhone}
            onClick={e => {
              updateUsersPhone(e)
            }}
            loading={loading}
          >
            {t('global.actions.submit')}
          </Button>
          <Button onClick={onClose} link>
            {t('global.actions.cancel')}
          </Button>
        </div>
      </form>
    </>
  )
})

const useStyles = makeStyles({
  caption: {
    fontWeight: 400,
    fontSize: 11,
    color: '#999',
  },
  modalMessage: {
    backgroundColor: '#fef4e5',
    padding: 11,
    display: 'flex',
    borderRadius: 4,
    fontSize: 14,
    color: '#efa831',
    marginBottom: 10,
  },
  message: {
    color: '#693e03',
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    display: 'flex',
    fontSize: '22px',
    opacity: '0.9',
  },
})
