import {
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  List,
  ListItem,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core'
import React from 'react'
import cookies from 'js-cookie'
import { connect, useDispatch } from 'react-redux'
import useSelectedDevices from '../../hooks/useSelectedDevices'
import { ApplicationState } from '../../store'
import { Alert } from '../Alert'
import { HelpIcon } from '../HelpIcon'
import { Icon } from '../Icon'
import { PageHeader } from '../PageHeader'
import { RefreshButton } from '../RefreshButton'
import { SignInMessage } from '../SignInMessage'
import { makeStyles } from '@material-ui/core'
import Domain from '../../Domain'

export type DevicesPageProps = ReturnType<typeof mapState>

const mapState = (state: ApplicationState) => ({
  advancedFeatures: state.auth.preferences.advancedFeatures && state.auth.preferences.advancedFeatures === 'on',
  signInStarted: state.auth.signInStarted,
  searchOnly: state.devices.searchOnly,
  user: state.auth.user,
})

export const DevicesPage = connect(mapState)(
  ({ advancedFeatures, searchOnly, signInStarted, user }: DevicesPageProps) => {
    const splashDisplay = cookies.get('splash_to_display') || 'true'

    // Checks if the user details is fetched and cookie value is false then splash screen to be shown
    // Otherwise proceed to show Device page after login is sucessfull
    if (user && splashDisplay === 'false') {
      const userCreatedDate = new Date(user.createdAt)
      const date = cookies.get('splash') || ''
      const splashViewDate = new Date(Date.parse(date))

      // Checks if the date is more than the splash saved date then show the splash screen
      // TBD: Change after testing is done
      if (userCreatedDate > splashViewDate) {
        cookies.set('splash_to_display', 'true')
        // window.location.href = '/#splash' // Disabled because as legacy site we don't want the splash screen
      }
    }

    return (
      <div className="h-100">
        {signInStarted ? (
          <SignInMessage />
        ) : (
          <>
            <PageHeader
              title="Devices"
              actions={
                <>
                  <HelpIcon
                    title="View device page documentation"
                    href="https://support.remote.it/hc/en-us/articles/360048707812-The-Devices-Page"
                  />
                  <RefreshButton title="Refresh devices" onClick={() => window.weaved.fetchDevices(true)} />
                </>
              }
            />
            <DevicePageActions searchOnly={searchOnly} />
          </>
        )}
        {!Domain.isEnterprise && <ModalNewVersion />}
        <div id="bulk_page" className="jqGrid_wrapper w-100">
          <div
            // TODO: Hack for legacy stupid jqGrid
            style={{ display: signInStarted ? 'none' : 'inherit' }}
          >
            <div className="right">
              <label className="fw-normal">
                <input
                  type="checkbox"
                  onChange={e => window.weaved.setUserPreference('advancedFeatures', e.target.checked ? 'on' : 'off')}
                  className="mr-sm"
                  defaultChecked={advancedFeatures}
                />
                Show advanced columns
              </label>
            </div>
            <LoadingSpinner />
            <table id="table_bulk"></table>
            <div id="pager_bulk"></div>
          </div>
        </div>

        <NoSearchResultsMessage />
        <TooManyResultsMessage />
        <GenericErrorMessage />
        <NoDevicesMessage />
        <SearchOnlyInstructions />
      </div>
    )
  }
)

function LoadingSpinner() {
  return (
    <div id="loading_spinner" style={{ display: 'none' }}>
      <CircularProgress />
    </div>
  )
}

function SearchOnlyInstructions() {
  return (
    <div id="devicelistOffMessage" className="my-xl center" style={{ display: 'none' }}>
      <p className="txt-lg info">
        <i className="fal fa-search mr-md"></i>
        <strong>Please use the search box above to find devices</strong>
      </p>
      <p>Your account has too many devices to display in a single page, so please search instead.</p>
    </div>
  )
}

function NoSearchResultsMessage() {
  return (
    <div id="no-search-results-message" style={{ display: 'none' }}>
      <Alert type="warning">No results found, please try another search.</Alert>
    </div>
  )
}

function TooManyResultsMessage() {
  return (
    <div id="too-many-results-message" style={{ display: 'none' }}>
      <Alert type="warning">Your search returned too many results, please try adding more to your search.</Alert>
    </div>
  )
}

function GenericErrorMessage() {
  return (
    <Alert type="warning" id="device-search-generic-error" style={{ display: 'none' }}>
      An error occurred.
    </Alert>
  )
}

function NoDevicesMessage() {
  return (
    <div id="no-devices-message" style={{ display: 'none' }}>
      <Alert type="info">You have no devices yet, please add a new device to your account using the button above</Alert>
    </div>
  )
}

function DevicePageActions({ searchOnly = false }: { searchOnly?: boolean }) {
  return (
    <Grid container spacing={1} alignItems="stretch" direction="row" justify="center" className="my-lg">
      <Grid item sm={12} md={12} lg={6}>
        <a href="#add">
          <Button size="large" color="primary" variant="contained" className="mr-sm">
            <Icon name="plus" weight="light" className="mr-sm" />
            Add Device
          </Button>
        </a>
        <GroupBySelector />
        <ActionsSelector />
        <HelpIcon
          title="View actions documentation"
          href="https://support.remote.it/hc/en-us/articles/360056407672-The-Actions-Menu"
          size="small"
        />
      </Grid>
      <Grid item sm={12} md={12} lg={6}>
        <div className="df ai-center" style={{ maxWidth: 800 }}>
          <SearchForm searchOnly={searchOnly} />
        </div>
      </Grid>
    </Grid>
  )
}

function ModalNewVersion() {
  const css = useStyles()
  return (
    <Grid container spacing={1} alignItems="stretch" direction="row" justify="center" className="my-lg">
      <Grid item sm={12} md={12} lg={12}>
        <div className={css.modalMessage}>
          <Icon name="info-circle" className={css.icon} fixedWidth />
          <div className={css.message}>
            <b>You are using the legacy version of remote.it so some features may be limited.</b>
            <br />
            Take advantage of all the features by switching to the latest version.
          </div>
          <Button
            className={css.button}
            onClick={() => window.open('https://app.remote.it/#/devices/welcome')}
            color="primary"
            variant="contained"
            disableElevation
          >
            SWITCH TO LATEST VERSION
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}

function SearchForm({ searchOnly }: { searchOnly?: boolean }): JSX.Element {
  const inputId = searchOnly ? 'searchText' : 'HWName'
  const [query, setQuery] = React.useState<string>('')
  const [timeout, setTheTimeout] = React.useState<any>(0)

  return (
    <Box className="w-100" display="flex" alignItems="center">
      <form
        autoComplete="off"
        aria-autocomplete="none"
        className="w-100"
        onSubmit={e => {
          e.preventDefault()
          if (searchOnly) window.weaved.fetchDevices(true)
        }}
      >
        <FormControl fullWidth>
          <Input
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="off"
            type="search"
            name="search"
            value={query}
            id={inputId}
            placeholder="Search devices..."
            onChange={e => {
              e.persist()
              setQuery(e.target.value)
              clearTimeout(timeout)

              // Make a new timeout set to go off in 1000ms (1 second)
              setTheTimeout(
                setTimeout(function() {
                  // Hide no devices message when search query changes.
                  window.hideSearchMessages()

                  // Perform a local filter if not on device search.
                  if (!searchOnly) {
                    window.$('#table_bulk').jqGrid('filterInput', e.target.value)
                    // Show no devices message if no results
                    const results = window.$('#table_bulk').jqGrid('getGridParam', 'records')
                    if (!results || results < 1) window.showNoSearchResultsMessage()
                  }
                }, 600)
              )
            }}
            startAdornment={
              <InputAdornment position="start">
                <Icon name="search" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {query && (
                  <Tooltip title="Clear search">
                    <IconButton
                      onClick={() => {
                        setQuery('')

                        window.hideSearchMessages()

                        // Clear the filter form text.
                        if (window.$) window.$('#table_bulk').jqGrid('filterInput', '')

                        // Force a keyboard event and then focus the input again.
                        const elm = document.getElementById(inputId)
                        if (elm) {
                          elm.dispatchEvent(new KeyboardEvent('keyup'))
                          elm.focus()
                        }

                        if (searchOnly) window.weaved.fetchDevices()
                      }}
                    >
                      <Icon name="times" />
                    </IconButton>
                  </Tooltip>
                )}
                {searchOnly && (
                  <Tooltip
                    title={
                      <List>
                        <ListItem>Search terms are not case sensitive and searches device name only.</ListItem>
                      </List>
                    }
                  >
                    <IconButton>
                      <Icon name="question-circle" />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            }
          />
        </FormControl>
      </form>
      {searchOnly && (
        <Button
          size="medium"
          color="primary"
          variant="contained"
          className="ml-sm"
          onClick={e => {
            e.preventDefault()
            window.weaved.fetchDevices(true)
          }}
        >
          Search
        </Button>
      )}
    </Box>
  )
}

function GroupBySelector() {
  const [groupBy, setGroupBy] = React.useState<string>('')

  return (
    <FormControl variant="outlined">
      <Select
        displayEmpty
        id="chngroup2"
        name="groupBy"
        value={groupBy}
        input={<OutlinedInput labelWidth={0} />}
        IconComponent={() => null}
        onChange={e => {
          const value = e.target.value
          if (!value) return
          // TODO: Stop calling legacy code...
          value === 'clear'
            ? window.jQuery('#table_bulk').jqGrid('groupingRemove', true)
            : window.jQuery('#table_bulk').jqGrid('groupingGroupBy', value)
          setGroupBy(String(e.target.value))
        }}
      >
        <MenuItem value="" disabled className="my-xs">
          Group By
          <Icon name="chevron-down" weight="light" className="ml-sm" />
        </MenuItem>
        <MenuItem value="clear" className="my-xs">
          Clear Grouping
        </MenuItem>
        <MenuItem value="HW_macid" className="my-xs">
          Hardware ID
        </MenuItem>
        <MenuItem value="Shared" className="my-xs">
          Shared
        </MenuItem>
        <MenuItem value="IPext" className="my-xs">
          External IP
        </MenuItem>
        <MenuItem value="CategoryA" className="my-xs">
          Category A
        </MenuItem>
        <MenuItem value="CategoryB" className="my-xs">
          Category B
        </MenuItem>
        <MenuItem value="CategoryC" className="my-xs">
          Category C
        </MenuItem>
        <MenuItem value="StatusA" className="my-xs">
          Status A
        </MenuItem>
        <MenuItem value="StatusB" className="my-xs">
          Status B
        </MenuItem>
        <MenuItem value="StatusC" className="my-xs">
          Status C
        </MenuItem>
        <MenuItem value="StatusD" className="my-xs">
          Status D
        </MenuItem>
        <MenuItem value="StatusE" className="my-xs">
          Status E
        </MenuItem>
      </Select>
    </FormControl>
  )
}

function ActionsSelector() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { ui } = useDispatch<any>() // TODO: fix type defs
  const selectedDevices = useSelectedDevices()
  const selectedNotMine = selectedDevices.length === 1 && selectedDevices[0].Shared === 'shared-from'

  function handleClose() {
    setAnchorEl(null)
  }

  function handleAction(action: string, deviceId?: string): void {
    window.selectFileAndCommand(action, deviceId)
    handleClose()
  }

  function handleShowDropdown(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget)
  }

  function handleShowUnshare(): void {
    window.bulkShareAction = 'off'
    handleClose()
    if (selectedNotMine) window.showRejectSharingModal()
    else ui.setRemoveSharingDialogVisibility(true)
  }

  const nothingSelected = selectedDevices.length < 1 || selectedDevices.length > window.MAX_SELECTED

  return (
    <FormControl variant="filled">
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleShowDropdown}
        variant="outlined"
        size="large"
      >
        Actions
        <Icon name="chevron-down" weight="light" className="ml-sm gray-darkest" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="my-xs"
      >
        <MenuItem onClick={() => handleAction('bulkshare')} className="my-xs" disabled={nothingSelected}>
          <Icon name="user-plus" fixedWidth color="gray-dark" className="mr-sm" />
          Share
        </MenuItem>
        <MenuItem onClick={handleShowUnshare} className="my-xs" disabled={nothingSelected}>
          <Icon name="user-slash" fixedWidth color="gray-dark" className="mr-sm" />
          {selectedNotMine ? 'Leave Device' : 'Remove sharing'}
        </MenuItem>
        <MenuItem onClick={() => handleAction('executescript')} className="my-xs" disabled={nothingSelected}>
          <Icon name="scroll" fixedWidth color="gray-dark" className="mr-sm" />
          Execute Script
        </MenuItem>
        <MenuItem onClick={() => handleAction('setcategory')} className="my-xs" disabled={nothingSelected}>
          <Icon name="folder-open" fixedWidth color="gray-dark" className="mr-sm" />
          Set Category
        </MenuItem>
        <MenuItem onClick={() => handleAction('transferdevice')} className="my-xs" disabled={nothingSelected}>
          <Icon name="share" fixedWidth color="gray-dark" className="mr-sm" />
          Transfer Device
        </MenuItem>
        <MenuItem onClick={() => handleAction('clearstatus')} className="my-xs" disabled={nothingSelected}>
          <Icon name="eraser" fixedWidth color="gray-dark" className="mr-sm" />
          Clear Status
        </MenuItem>
        <Divider className="my-xs" />
        <MenuItem onClick={() => handleAction('deletedevice')} className="my-xs" disabled={nothingSelected}>
          <Icon name="trash-alt" fixedWidth color="danger" className="mr-sm" />
          <span className="danger">Delete</span>
        </MenuItem>
      </Menu>
    </FormControl>
  )
}

const useStyles = makeStyles({
  modalMessage: {
    backgroundColor: '#e6f5fd',
    padding: 11,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    fontSize: 14,
    color: '#1d5875',
    marginBottom: 10,
  },
  message: {
    color: '#1d5875',
    flexGrow: 1,
  },
  icon: {
    marginRight: 12,
    marginLeft: 4,
    padding: '7px 0',
    display: 'flex',
    fontSize: '22px',
    opacity: '0.9',
    color: '#5ac2f7',
  },
  button: {
    marginRight: 12,
    paddingLeft: 18,
    paddingRight: 18,
  },
})
