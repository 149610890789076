import { Divider, List, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IContact } from 'remote.it'
import { Alert } from '../Alert'
import { ContactCard } from './ContactCard'

export interface SharingDetails {
  access: SharingAccess
  contacts: string[]
  deviceID: string
}

export interface SharingAccess {
  scripting: boolean
  services: string[]
}

export function DeviceShareDetails({
  contacts = [],
  deviceID,
  services = [],
  shares = [],
  unshare,
  updateSharing,
}: {
  contacts?: IContact[]
  deviceID: string
  shares: ShareInfo[]
  services: SimplifiedService[]
  fetching?: boolean
  unshare: (email: string) => Promise<void>
  updateSharing: (share: SharingDetails) => Promise<void>
}): JSX.Element {
  const { t } = useTranslation()

  if (!shares.length) {
    return (
      <Alert type="muted" variant="outlined" className="my-md" stayOpen>
        <Typography>
          {t('pages.device-share.device-no-shares-heading')}
        </Typography>
      </Alert>
    )
  }

  return (
    <List component="div">
      <Divider />
      {shares.map(share => {
        const contact = contacts.find(c => c.email === share.email)
        return (
          <ContactCard
            allServices={services}
            contact={contact}
            deviceID={deviceID}
            email={share.email}
            key={share.userid}
            scripting={share.scripting}
            sharedServices={share.services}
            unshare={unshare}
            updateSharing={updateSharing}
          />
        )
      })}
    </List>
  )
}
