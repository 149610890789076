import React, { ReactElement } from 'react'
import { BrandIcon } from '../BrandIcon'
import { RaspberryPiInstructions } from './instructions/RaspberryPiInstructions'
import { MacOSInstructions } from './instructions/MacOSInstructions'
import { LinuxInstructions } from './instructions/LinuxInstructions'
import { WindowsInstructions } from './instructions/WindowsInstructions'

export interface SupportedPlatform {
  id: OperatingSystem
  title: string
  icon: ReactElement
  codeTitle?: string
  instructions?: ReactElement
}

export const platforms: SupportedPlatform[] = [
  {
    id: 'raspberrypi',
    title: 'Raspberry Pi',
    icon: <BrandIcon name="raspberry-pi" />,
    codeTitle: 'ssh - pi@raspberrypi',
    instructions: <RaspberryPiInstructions />,
  },
  {
    id: 'macOS',
    title: 'macOS',
    icon: <BrandIcon name="apple" />,
    instructions: <MacOSInstructions />,
  },
  {
    id: 'linux',
    title: 'Linux',
    icon: <BrandIcon name="linux" />,
    instructions: <LinuxInstructions />,
  },
  {
    id: 'windows',
    title: 'Windows',
    icon: <BrandIcon name="windows" />,
    instructions: <WindowsInstructions />,
  },
]
