import {
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from '@material-ui/core'
import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useTranslation } from 'react-i18next'
import { IContact } from 'remote.it'
import { Icon } from '../Icon'
import { LoadingMessage } from '../LoadingMessage'
import { DeviceShareAdd } from './DeviceShareAdd'
import {
  DeviceShareDetails,
  SharingAccess,
  SharingDetails,
} from './DeviceShareDetails'

// type DeviceShareDialogProps = ReturnType<typeof mapState> &
//   ReturnType<typeof mapDispatch>
interface DeviceShareDialogProps {
  closeDialog: () => void
  contacts: IContact[]
  deviceID: string
  deviceName: string
  fetch: (deviceID: string) => Promise<void>
  fetching: boolean
  services: SimplifiedService[]
  share: (share: SharingDetails) => Promise<void>
  shares: ShareInfo[]
  unshare: (email: string) => Promise<void>
  update: (share: SharingDetails) => Promise<void>
}

export function DeviceShareDialog({
  contacts = [],
  closeDialog,
  deviceID,
  deviceName,
  fetch,
  fetching,
  services = [],
  share,
  shares = [],
  unshare,
  update,
}: DeviceShareDialogProps): JSX.Element {
  const { t } = useTranslation()
  const [selectedScripting, setSelectedScripting] = React.useState<boolean>(
    false
  )
  const [sharing, setSharing] = React.useState<boolean>(false)
  const [selectedContacts, setSelectedContacts] = React.useState<string[]>([])
  const [selectedServices, setSelectedServices] = React.useState<string[]>([])

  React.useEffect((): void => {
    fetch(deviceID)
  }, [deviceID])

  function handleClose(): void {
    closeDialog()
  }

  async function handleShare(): Promise<void> {
    setSharing(true)
    await share({
      access: {
        scripting: selectedScripting,
        services: selectedServices,
      },
      contacts: selectedContacts,
      deviceID,
    })
    setSelectedContacts([])
    setSharing(false)
  }

  function handleChangeAccess(access: SharingAccess): void {
    setSelectedScripting(access.scripting)
    setSelectedServices(access.services)
  }

  function handleChangeContacts(contacts: string[]): void {
    setSelectedContacts(contacts)
  }

  async function handleUpdate(share: SharingDetails): Promise<void> {
    await update(share)
  }

  const notSharedYet = c => !shares.find(s => s.email === c.email)
  const unsharedContacts = contacts.filter(notSharedYet)

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open
      scroll="paper"
    >
      <DialogTitle className="df ai-center w-100" disableTypography>
        <Typography variant="h6" className="f-1">
          {t('pages.device-share.title', { deviceName: deviceName })}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="ml-auto"
        >
          <Icon name="times" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ [fetching ? 'height' : 'minHeight']: 500 }}
      >
        <div className="h-100 px-md py-lg">
          {fetching ? (
            <div className="df ai-center jc-center h-100">
              <LoadingMessage
                text={t('pages.device-share.fetching-loading-message')}
              />
            </div>
          ) : (
            <>
              <Box mb={6}>
                <DeviceShareAdd
                  contacts={unsharedContacts}
                  onChangeAccess={handleChangeAccess}
                  onChangeContacts={handleChangeContacts}
                  selectedContacts={selectedContacts}
                  selectedScripting={selectedScripting}
                  selectedServices={selectedServices}
                  services={services}
                  sharing={sharing}
                  share={handleShare}
                  shares={shares}
                />
              </Box>
              <DeviceShareDetails
                contacts={contacts}
                deviceID={deviceID}
                services={services}
                shares={shares}
                unshare={unshare}
                updateSharing={handleUpdate}
              />
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

// const mapState = (state: ApplicationState) => ({
//   // HACK: once we get type info from Rematch we can
//   // remove the hard coding of types.
//   contacts: state.contacts.all as IContact[],
//   deviceID: state.shares.deviceID as string,
//   deviceName: state.shares.deviceName as string,
//   fetching: state.shares.fetching as boolean,
//   open: state.shares.isShowingDetails as boolean,
//   services: state.shares.services as SimplifiedService[],
//   shares: state.shares.shares as ShareInfo[],
// })

// const mapDispatch = (dispatch: any) => ({
//   // HACK: once we get type info from Rematch we can
//   // remove the hard coding of types.
//   closeDialog: dispatch.shares.closeDialog as () => void,
//   share: dispatch.shares.share as (share: SharingDetails) => Promise<void>,
//   fetch: dispatch.shares.fetch as (deviceID: string) => Promise<void>,
//   unshare: dispatch.shares.unshare as (email: string) => Promise<void>,
//   update: dispatch.shares.update as (share: SharingDetails) => Promise<void>,
// })

// export const DeviceShareDialog = connect(
//   mapState,
//   mapDispatch
// )(ShareDialog)
