// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-1!../../../node_modules/postcss-loader/src/index.js??ref--8-2!../../styles/variables.css");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, ".src-components-ContactSelector-___ContactSelector-module__scriptingLabel___1cfT7 {\n  font-size: var(--font-size-md) !important;\n}\n", "",{"version":3,"sources":["ContactSelector.module.css"],"names":[],"mappings":"AAEA;EACE,yCAAyC;AAC3C","file":"ContactSelector.module.css","sourcesContent":["@import '../../styles/variables.css';\n\n.scriptingLabel {\n  font-size: var(--font-size-md) !important;\n}\n"]}]);
// Exports
exports.locals = {
	"scriptingLabel": "src-components-ContactSelector-___ContactSelector-module__scriptingLabel___1cfT7"
};
module.exports = exports;
