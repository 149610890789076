import React from 'react'
import { makeStyles } from '@material-ui/core'
import pattern from '../../images/pattern.svg'
import trimble from '../../images/logos/trimble_logo.png'
import MHIECE from '../../images/logos/MHIECE_logo.png'
import bullseyeTelecom from '../../images/logos/bullseye-telecom_logo.png'
import karit from '../../images/logos/karit_logo.png'
import spanio from '../../images/logos/spanio_logo.png'
import colourfi from '../../images/logos/colourfi_logo.png'
import remote_logo from '../../images/logos/remote_logo_abb.svg'
import './AuthLayout.css'
import { BannerTryNew } from '../BannerTryNew'
import Domain from '../../Domain'
export interface Props {
  children: React.ReactNode
  title?: string
  maxWidth?: string
}

export const trustedLogo = [MHIECE, trimble, bullseyeTelecom, karit, spanio, colourfi]

export function AuthLayout({ children }: Props): JSX.Element {
  const css = useStyles()

  return (
    <div className={css.body}>
      <header className="b--header-a">
        <div className="f--container">
          <div className="f--row">
            <div className="f--col-12">
              <a href="/auth/">
                <img src={remote_logo} />
              </a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section className="b--section-c">
          <div className="f--container">
            <div className="f--row">
              <div className="f--col-5 f--col-tabletp-12 f--order-tabletp-2">
                <div className="b--card-a">
                  <div className="b--card-a__hd">
                    <h3 className="b--card-a__hd__title">Remote access made easy </h3>
                  </div>
                  <div className="b--card-a__bd b--content-a b--content-a--second">
                    <div className="b--card-a__bd__content">
                      <p>
                        <a href="http://remote.it/" target="_blank" rel="noopener">
                          remote.it
                        </a>{' '}
                        helps businesses manage devices and share access to their resources easily and securely, no
                        matter where their users are. Sign up now and connect one or thousands of devices.
                      </p>
                    </div>
                  </div>
                  <div className="b--card-a__ft">
                    <div className="b--card-a__ft__title f--color-g">Trusted by</div>
                    <div className="f--row">
                      {trustedLogo.map((item, index) => {
                        return (
                          <div className="f--col-6" key={index}>
                            <div className="b--card-a__ft__media-wrapper">
                              <img className="b--card-a__ft__media-wrapper__media" src={item} />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="f--col-6 f--offset-tabletp-0 f--col-tabletp-12 f--order-tabletp-1">
                {!Domain.isEnterprise && <BannerTryNew />}
                <div className="b--card-b">{children}</div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className={css.bg_a}></div>
    </div>
  )
}

const useStyles = makeStyles({
  body: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
    flexDirection: 'column',
    background: '#0526a0',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '1.5',
    boxSizing: 'border-box',
    margin: '0',
    padding: '0',
    border: 'none',
    overflowY: 'auto',
  },
  bg_a: {
    position: 'fixed',
    top: '0',
    right: '0',
    zIndex: 1,
    width: '40%',
    height: '100%',
    backgroundImage: `url(${pattern})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
  },
})
