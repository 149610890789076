import { createModel } from '@rematch/core'
import { IDevice } from 'remote.it'

export interface UIState {
  removeSharingVisible: boolean
  currentDevice?: IDevice
}

const state: UIState = {
  removeSharingVisible: false,
  currentDevice: undefined,
}

export default createModel({
  state,
  reducers: {
    setRemoveSharingDialogVisibility(
      state: UIState,
      visible: boolean,
      currentDevice?: IDevice
    ) {
      state.removeSharingVisible = visible
      state.currentDevice = currentDevice
    },
  },
})
