import React from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Icon } from '../Icon'
import { useClipboard } from 'use-clipboard-copy'

export interface CopyButtonProps {
  color?: BrandColor
  text: string
  title?: string
  [key: string]: any
}

export function CopyButton({
  color = 'gray',
  text,
  title = 'Copy',
  ...props
}: CopyButtonProps) {
  const clipboard = useClipboard({ copiedTimeout: 1000 })
  return (
    <span {...props}>
      <Tooltip title={title}>
        <IconButton onClick={clipboard.copy}>
          <Icon
            name="copy"
            className="txt-lg"
            color={clipboard.copied ? 'success' : color}
          />
        </IconButton>
      </Tooltip>
      <input type="hidden" className="dn" ref={clipboard.target} value={text} />
    </span>
  )
}
