import React from 'react'
import { ButtonIcon } from '../ButtonIcon'
import { Button } from '../Button'
import { RefreshButton } from '../RefreshButton'
import { PageHeader } from '../PageHeader'
import { HelpIcon } from '../HelpIcon'
import { connect } from 'react-redux'
import { ApplicationState } from '../../store'
import { SignInMessage } from '../SignInMessage'

export type RegistrationsPageProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  signInStarted: state.auth.signInStarted,
})

const mapDispatch = (dispatch: any) => ({})

export const RegistrationsPage = connect(
  mapState,
  mapDispatch
)(({ signInStarted }: /*showBetaFeatures*/ RegistrationsPageProps) => {
  if (signInStarted) return <SignInMessage />
  return (
    <div>
      <PageHeader
        title="Registrations"
        actions={
          <>
            <HelpIcon
              title="View registration documentation"
              href="https://link.remote.it/docs/oem-overview"
            />
            <RefreshButton onClick={() => window.showRegistrations()} />
          </>
        }
      />
      <div className="my-lg df ai-center">
        <Button onClick={() => window.showBulkUpload()} className="mr-md">
          <ButtonIcon icon="plus" />
          Create Registration 
        </Button>
        <Button
          color="secondary"
          onClick={() => window.showCreateOpenRegistration()}
          className="mr-md"
        >
          <ButtonIcon icon="plus" />
          Create Auto Registration
        </Button>
        <Button
          href="https://cl.ly/s0fo/bulk-registration-template.csv"
          outline
          className="mr-md"
          color="secondary"
          download
        >
          <ButtonIcon icon="download" />
          Download Registration Template
        </Button>
      </div>
      <div id="bulk-registration-content"></div>
    </div>
  )
})
