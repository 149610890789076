import { CognitoUser } from '@aws-amplify/auth'
import cookies from 'js-cookie'
import debug from 'debug'
import { IUser, RemoteItPlanName } from 'remote.it'
import { r3 } from '../services/remote.it'
import { createModel } from '@rematch/core'
import Domain from '../Domain'
import Preferences from '../services/Preferences'
import analytics from '../services/Analytics'
import { axiosGraphQL, GET_USER_DATA_PLAN } from '../utils/graphQL'
import { getToken } from '../services/remote.it'
import axios from 'axios'
import { RootState } from '../store'
import cloudController from '../services/cloudController'
export interface ILicenses {
  created: Date
  expiration: string
  plan: IPlan
  updated: Date
  valid: boolean
}

export interface ILimit {
  name: string
  value: string
  actual: number
  license: {}
}
const d = debug('r3:models:auth')

export const EMAIL_COOKIE = 'remoteit.email'
export const SPLASH_DATE = 'splash'
export const SPLASH_TO_BE_DISPLAYED = 'splash_to_display'
export const S_HOSTURL = 's.remote.it'
const ENV = process.env.NODE_ENV

export interface PlanChangeResponse {
  expires: string
  plan: string // remot3.it.FREE
  status: boolean
  type: RemoteItPlanName
}

export interface RecoveryCode {
  emailVerificationCode: string
  recoveryCode: string
}

export interface AuthState {
  commercialDomain: boolean
  partnerPortalAccess: boolean
  transactions?: Transaction[]
  showBetaFeatures: boolean
  signInStarted: boolean
  user?: IUser
  limits?: ILimit[]
  licenses?: ILicenses[]
  loggedIn?: boolean
  preferences: { [key: string]: any }
  verificationEmail?: string
  mfaMethod: string
  AWSUser: AWSUser
  cognitoUser?: CognitoUser
  // authService?: AuthService
}

export interface AWSUser {
  authProvider: string
  email?: string
  email_verified?: boolean
  phone_number?: string
  phone_number_verified?: boolean
  given_name?: string //first_name
  family_name?: string //last_name
  gender?: string
  'custom:backup_code'?: string
}

const state: AuthState = {
  commercialDomain: Domain.isEnterprise,
  partnerPortalAccess: false,
  showBetaFeatures: false,
  signInStarted: false,
  transactions: undefined,
  user: undefined,
  limits: undefined,
  licenses: undefined,
  loggedIn: false,
  preferences: {},
  mfaMethod: '',
  AWSUser: {
    authProvider: '',
  } as AWSUser,
  cognitoUser: {} as CognitoUser,
}

export default createModel({
  state,
  effects: dispatch => ({
    async checkSignIn() {
      const { signInStarted, setLoggedIn, signInSuccess } = dispatch.auth
      if (state.loggedIn) {
        return true
      }
      signInStarted()
      // try {
      const result = await window.authService.checkSignIn()
      if (result.cognitoUser) {
        setLoggedIn(true)
        signInSuccess()
        await this.getAuthenticatedUserInfo(true)
        if (result.cognitoUser?.authProvider === 'Google') {
          window.localStorage.setItem('amplify-signin-with-hostedUI', 'true')
        }
      } else {
        throw result.error
      }
      return true
    },
    async getAuthenticatedUserInfo(_fetch, rootState: RootState) {
      const userInfo = await window.authService.currentUserInfo()
      if (userInfo && userInfo.attributes) {
        this.setLoginCookies(userInfo.attributes['email'])
      }

      const Authorization = await getToken()
      // Get MFA Preference'
      try {
        const response = await axios.get(`${process.env.AUTH_API_URL}/mfaPref`, {
          headers: {
            developerKey: process.env.DEVELOPER_KEY,
            Authorization,
          },
        })
        // console.log ('MFA PREF RESPONSE')
        // console.log(response)
        // console.log(response.data)
        // console.log(response.data['MfaPref'])
        this.setMfaMethod(response.data['MfaPref'])
        if (userInfo && userInfo.attributes) {
          delete userInfo.attributes['identities']
          delete userInfo.attributes['sub']
        }
      } catch (error) {
        console.log('Error getting mfa: ', error)
      }
      const AWSUser = {
        ...userInfo.attributes,
        ...{
          authProvider: userInfo.username.includes('Google') || userInfo.username.includes('google') ? 'Google' : '',
        },
      }
      const updatedAWSUser = { ...rootState.auth.AWSUser, ...AWSUser }
      // console.log('Updated AWSUser: ')
      // console.log(updatedAWSUser)
      if (rootState.auth.awsUser !== updatedAWSUser) {
        console.log('setAwsUser')
        dispatch.auth.setAWSUser(updatedAWSUser)
      }
      return updatedAWSUser
    },
    async updatePhone(phone: string) {
      try {
        // eslint-disable-next-line @typescript-eslint/camelcase
        await window.authService.updateCurrentUserAttributes({ phone_number: phone })
        await this.getAuthenticatedUserInfo(true)

        await window.authService.verifyCurrentUserAttribute('phone_number')
        this.setMFAPreference('NO_MFA')
        // await Auth.disableSMS(user)
        return true
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async verifyPhone(verificationCode: string) {
      try {
        await window.authService.verifyCurrentUserAttributeSubmit(
          'phone_number',
          verificationCode
        )
        const response = this.setMFAPreference('SMS_MFA')
        this.getAuthenticatedUserInfo(true)
        // console.log('setMFAPreference response')
        // console.log(response)
        return response
      } catch (error) {
        console.log(error)
        throw error
      }
    },
    async getTotpCode() {
      // const awsUser = await window.authService.currentAuthenticatedUser()
      // const code = await Auth.setupTOTP(awsUser) //.then(code => {
      // // You can directly display the `code` to the user or convert it to a QR code to be scanned.
      // // E.g., use following code sample to render a QR code with `qrcode.react` component:
      // //  import QRCode from 'qrcode.react';
      // //  const str = "otpauth://totp/AWSCognito:"+ username + "?secret=" + code + "&issuer=" + issuer;
      // //  <QRCode value={str}/>
      // return code
      return window.authService.setupTOTP()
    },
    async verifyTotpCode(code: string) {
      // const awsUser = await window.authService.currentAuthenticatedUser()
      try {
        await window.authService.verifyTotpToken(code)
      } catch (e) {
        console.error(e.message)
        return false
      }
      return this.setMFAPreference('SOFTWARE_TOKEN_MFA')
    },
    async setMFAPreference(mfaMethod: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'NO_MFA') {
      const Authorization = await getToken()

      const response = await axios.post(
        `${process.env.AUTH_API_URL}/mfaPref`,
        {
          MfaPref: mfaMethod,
        },
        {
          headers: {
            developerKey: process.env.DEVELOPER_KEY,
            Authorization,
          },
        }
      )

      // console.log ('setMFAPreference MFA PREF RESPONSE')
      // console.log(response)
      // console.log(response.data)
      this.setMfaMethod(response.data['MfaPref'])
      return response.data['backupCode']
    },
    async getUserData(_fetch, rootState: RootState) {
      this.getAuthenticatedUserInfo(true)
      try {
        try {
          // Now set their preferences as well as sync
          // with legacy code.
          this.getPreferences()

          // See if the have access to Partner Portal so
          // we can show them a link to it in the UI.
          this.checkPartnerPortalAccess()

          // Get credit card info for authenticated user
          // this.getCreditCard()
        } catch (error) {
          console.error(error)
        }
        const user = await r3.user.userData(rootState.auth.AWSUser.email)
        user.authHash = ''
        await this.setUser(user)
        await this.fetchLimitAndPlan(true)
        cloudController.init()
        await dispatch.licensing.fetch()
        return user
      } catch (error) {
        console.log('Error', error)
        alert('Could not get user data, logging you out...')
        this.signOut()
        return
      }
    },
    async fetchLimitAndPlan(setPlan = false) {
      try {
        const graphQL = await axiosGraphQL()
        const response = await graphQL?.post('', {
          query: GET_USER_DATA_PLAN,
        })

        this.setLimits(response.data.data.login.limits)
        this.setLicenses(response.data.data.login.licenses)
        if (setPlan && response.data.data.login.licenses) {
          const licenses = response.data.data.login.licenses
          let plan = 'free'
          if (licenses[0].plan.name === 'BUSINESS') plan = 'seat'
          if (licenses[0].plan.name === 'PROFESSIONAL') plan = 'device'

          await dispatch.auth.updatePlanName({
            plan,
          })
        }
      } catch (error) {
        this.setState({ error })
        console.error('Failed to Update:', error)
      }
    },
    async storePreference({ key, value }: { key: string; value: any }) {
      await Preferences.set(key, value)
      dispatch.auth.setPreference({ key, value })
    },
    async getPreferences() {
      let preferences
      try {
        const resp = await r3.get('/user/preferences/')
        if (resp.preferences.filter(preference => preference.key === 'advancedFeatures').length === 0) {
          resp.preferences.push({ key: 'advancedFeatures', data: 'off' })
        }
        preferences = resp.preferences
        if (window.processUserPreferences) window.processUserPreferences(resp)
      } catch (error) {
        if (error && error.message && error.message === 'no preferences for this user') {
          console.error(error)
          console.log('No preferences could be found for this user')
          preferences = [{ key: 'advancedFeatures', data: 'off' }]
        }
      }
      dispatch.auth.setPreferences(preferences)
      return preferences
    },
    async setPreferences(preferences: UserPreference[]) {
      preferences.map(pref => {
        dispatch.auth.setPreference({ key: pref.key, value: pref.data })
      })
    },
    async signInSuccess() {
      this.signInFinished()
    },
    async setLoginCookies(email: string) {
      d('Saving login details:', email)
      const expires = 90 // days
      const secure = ENV === 'production'
      // r3.token = user.token
      cookies.set(EMAIL_COOKIE, email, { expires, secure })

      // Default date is set as 1st October 2020, users who sign in after this date should see the splash screen
      cookies.set(SPLASH_DATE, String(new Date(2000, 1, 1, 0, 0, 0, 0)), {
        expires,
        secure,
      })

      const ifDisplay = cookies.get(SPLASH_TO_BE_DISPLAYED)
      const sDomain = window.location.hostname === S_HOSTURL
      // Checks if cookie exist then dont set else set the default value as false for new users
      if (!ifDisplay && !sDomain) {
        cookies.set(SPLASH_TO_BE_DISPLAYED, 'false', { expires, secure })
      }
    },
    async checkPartnerPortalAccess() {
      const { hasPartnerPortalAccess } = dispatch.auth
      return r3.entities
        .acl()
        .then(() => hasPartnerPortalAccess(true))
        .catch(() => hasPartnerPortalAccess(false))
    },
    async globalSignOut() {
      const Authorization = await getToken()
      // Get MFA Preference
      const response = await axios.get(`${process.env.AUTH_API_URL}/globalSignout`, {
        headers: {
          Authorization,
        },
      })
      console.log(response)
      this.signOut()
    },
    async signOut() {
      try {
        await window.authService.signOut()
        cloudController.close()
      } catch {
        console.log('Signout issue')
      }
      window.localStorage.removeItem('amplify-signin-with-hostedUI')
      this.removeLoginCookies()
      window.location.href = '/auth'
    },
    async removeLoginCookies() {
      d('Removing login cookies')
      cookies.remove(EMAIL_COOKIE)
    },
    async changePassword(passwordValues) {
      const existingPassword = passwordValues.currentPassword
      const newPassword = passwordValues.password
      await window.authService.changePassword(existingPassword, newPassword)
    },
    async fetchTransactions() {
      const { setTransactions } = dispatch.auth
      // TODO: move to remote.it.js

      try {
        const { invoices }: { invoices: RawTransaction[] } = await r3.get('/user/plan/invoices/')

        const cleaned: Transaction[] = invoices.map(t => ({
          id: t.id,
          paid: t.paid,
          currency: t.currency as Currency,
          totalInCents: t.total,
          description: t.description,
          subscriptionID: t.subscription,
          quantity: t.quantity,
          createdAt: new Date(t.created * 1000),
          daysRemaining: t.days_remaining,
          invoiceUrl: t.invoiceUrl,
        }))
        setTransactions(cleaned)
        await this.fetchLimitAndPlan()
      } catch (error) {
        if (error && error.message && error.message === 'no payment system id for this user')
          return console.log('User does not have a payment system yet')

        console.error(error)
      }
    },
    async changeLanguage(language: AvailableLanguage) {
      const { setLanguage } = dispatch.auth
      return r3.post('/user/language/', { language }).then(() => setLanguage(language))
    },
    async moveToSeatsPlan({ quantity, token }: { quantity: number; token: string }): Promise<PlanChangeResponse> {
      const amount = 50 * 100 // $50
      const type = 'seat'
      return dispatch.auth.chargeCard({
        type,
        amount,
        quantity,
        token,
      }) as PlanChangeResponse
    },
    async moveToDevicesPlan({ quantity, token }: { quantity: number; token: string }): Promise<PlanChangeResponse> {
      const amount = 2 * 100 // $2
      const type = 'device'
      return dispatch.auth.chargeCard({
        type,
        amount,
        quantity,
        token,
      }) as PlanChangeResponse
    },
    async moveToFreePlan(): Promise<PlanChangeResponse> {
      const type = 'free'
      const amount = 0
      const quantity = 1
      const token = 'cancel_token'
      return dispatch.auth.chargeCard({
        amount,
        quantity,
        token,
        type,
      }) as PlanChangeResponse
    },
  }),
  reducers: {
    updatePlan(
      state: AuthState,
      { expires, quantity, plan }: { expires: Date; quantity: number; plan: RemoteItPlanName }
    ) {
      if (!state.user || !state.user.plan) return
      state.user.plan.quantity = quantity
      state.user.plan.commercial = isCommercial(plan)
      state.user.plan.name = plan.toLowerCase() as RemoteItPlanName
      state.user.plan.expires = expires
    },
    updatePlanName(state: AuthState, { plan }: { plan: RemoteItPlanName }) {
      if (!state.user || !state.user.plan) return
      state.user.plan.name = plan.toLowerCase() as RemoteItPlanName
    },
    setPreference(state: AuthState, pref: { key: string; value: string }) {
      state.preferences[pref.key] = pref.value

      // TODO: Integrate into legacy code hooks:
      if (pref.key === 'advancedFeatures') {
        if (window.showAdvancedFeatures) window.showAdvancedFeatures(pref.value === 'on')
      }
    },
    signInStarted(state: AuthState) {
      state.signInStarted = true
    },
    signInFinished(state: AuthState) {
      state.signInStarted = false
    },
    signOutFinished(state: AuthState) {
      state.user = undefined
    },
    setUser(state: AuthState, user: IUser) {
      state.user = user
      // Identify the user so Segment can monitor usage properly.
      try {
        analytics.identify(user?.id ? user?.id : '')
      } catch (error) {
        console.error('ERROR: could not identify user')
        console.error(error)
      }
      if (user.plan != undefined) {
        state.user.plan.commercial = isCommercial(user.plan.name)
      }
      state.showBetaFeatures = state.user.email.includes('remote.it') || state.user.email.includes('remot3.it')
    },
    setLimits(state: AuthState, limits: ILimit[]) {
      state.limits = limits
    },
    setLicenses(state: AuthState, licenses: ILicenses[]) {
      state.licenses = licenses
    },
    setLoggedIn(state: AuthState, loggedIn: boolean) {
      state.loggedIn = loggedIn
    },
    setTransactions(state: AuthState, transactions: Transaction[]) {
      state.transactions = transactions
    },
    setLanguage(state: AuthState, language: AvailableLanguage) {
      if (!state.user) return
      state.user.language = language
    },
    hasPartnerPortalAccess(state: AuthState, hasAccess: boolean) {
      state.partnerPortalAccess = hasAccess
    },
    setAWSUser(state: AuthState, AWSUser: AWSUser) {
      state.AWSUser = AWSUser
    },
    setMfaMethod(state: AuthState, value: string) {
      state.mfaMethod = value
    },
    setCognitoUser(state: AuthState, cognitoUser: CognitoUser) {
      state.cognitoUser = cognitoUser
    },
  },
})

/**
 * Check to see what type of account the user has. If they are
 * on a "Commercial" domain, meaning one that is under contract,
 * they are considered commercial.
 */
function isCommercial(plan: RemoteItPlanName): boolean {
  if (Domain.isEnterprise) return true
  return plan.toLowerCase() !== 'free'
}
