import { IService } from 'remote.it'
import { r3 } from './remote.it'
export interface RawDeviceResponse {
  deviceID: string
  shares: ShareInfo[]
  services: SimplifiedService[]
}

export class SharingManager {
  public static async fetch(
    deviceID: string
  ): Promise<{ deviceID: string; shares: ShareInfo[]; services: IService[] }> {
    const data = await r3.get(
      '/developer/device/share/list/' + deviceID + '?cached=false'
    )
    return {
      deviceID,
      shares: data.shares as ShareInfo[],
      services: data.services as IService[],
    }
  }

  public static async share({
    allServices,
    // authHash,
    contacts,
    deviceID,
    scripting,
    sharedServices,
  }: {
    allServices: IService[]
    // authHash: string
    contacts: string[]
    deviceID: string
    scripting: boolean
    sharedServices: string[]
  }): Promise<RawDeviceResponse> {
    const services = this.formatServices(allServices, sharedServices)
    return await r3.post('/developer/devices/share/', {
      actionurl: this.actionURL,
      function: 'share_devices',
      label: 'sharing',
      options: JSON.stringify({
        devices: deviceID,
        emails: contacts.toString(),
        state: 'on',
        scripting,
        services,
      }),
      queue_name: this.taskQueue,
    })
  }

  /**
   * Stops sharing a given device with a particular user,
   * specified by their account email.
   */
  public static async unshare({
    deviceID,
    email,
  }: {
    deviceID: string
    email: string
  }): Promise<any> {
    return await r3.post(
      `/developer/device/share/${deviceID}/${encodeURIComponent(email)}`,
      {
        devices: deviceID,
        emails: email,
        notify: true,
        state: 'off',
        scripting: false,
      }
    )
  }

  public static async update({
    allServices,
    deviceID,
    email,
    scripting,
    sharedServices,
  }: {
    allServices: IService[]
    deviceID: string
    email: string
    scripting: boolean
    sharedServices: string[]
  }): Promise<any> {
    const services = this.formatServices(allServices, sharedServices)
    return await r3.post('/developer/devices/share/', {
      actionurl: this.actionURL,
      function: 'share_devices',
      label: 'sharing',
      options: JSON.stringify({
        devices: deviceID,
        emails: email,
        state: 'on',
        scripting,
        services,
      }),
      queue_name: this.taskQueue,
      state: 'on',
    })
  }

  private static formatServices(
    all: IService[],
    shared: string[]
  ): { [id: string]: boolean } {
    return all.reduce((all, s) => {
      all[s.id] = shared.includes(s.id)
      return all
    }, {} as { [id: string]: boolean })
  }

  private static get taskQueue(): string | undefined {
    return process.env.TASK_QUEUE_NAME
  }

  private static get actionURL(): string {
    return window.location.origin + '/invite/accept?type=shareDevices&id='
  }
}
