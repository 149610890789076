import { Button, Menu, MenuItem } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ServiceState } from 'remote.it'
import useTrackEvent from '../../hooks/useTrackEvent'
import getDeviceAndServices from '../../legacy/getDeviceAndServices'
import { r3 } from '../../services/remote.it'
import { Icon } from '../Icon'
import getPlatformCode from '../../legacy/getPlatformCode'

export function QuickConnectButton({ deviceID, status }: { deviceID?: string; status?: ServiceState }): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [launching, setLaunching] = React.useState<boolean>(false)

  const { t } = useTranslation()
  const track = useTrackEvent()
  const opened = Boolean(anchorEl)
  const awsCode = 1185
  const { device, services } = getDeviceAndServices(deviceID || '')


  function handleToggle(event: React.MouseEvent<HTMLButtonElement>): void {
    setAnchorEl(event.currentTarget)
  }

  function handleClose(): void {
    setAnchorEl(null)
  }

  async function handleLaunch(serviceID: string, serviceName: string, typeString: string): Promise<void> {
    if (!deviceID) return
    handleClose()
    const platformCode = getPlatformCode(typeString)
    if (platformCode && platformCode === awsCode) {
      window.location.href = `remoteit://connect/${serviceID}`
      window.showAWSModal()
      return
    }
    setLaunching(true)
    const serviceType = r3.devices.parseServiceType(typeString).id
    const eventData = {
      label: 'quickConnect',
      serviceID,
      serviceName,
      serviceType,
      deviceID,
      connectionType: 'Proxy',
    }

    try {
      track('connectionInitiated', eventData)
      await window.showConnectionModal(serviceID, serviceName, typeString, deviceID)
      track('connectionSucceeded', eventData)
    } catch (error) {
      track('connectionFailed', { ...eventData, error: error.message })
    }
    setLaunching(false)
  }

  if (device?.license === 'UNLICENSED') {
    return (
      <Button
        variant="contained"
        disabled
        size="small"
        style={{ color: '#ee9e15', backgroundColor: '#fef5e5' }}
        fullWidth
      >
        UNLICENSED
      </Button>
    )
  }

  if (status === 'inactive') {
    return (
      <Button variant="contained" disabled size="small" style={{ color: 'white' }} fullWidth>
        {t('global.status.offline')}
      </Button>
    )
  }

  if (!deviceID) {
    console.error('No device ID provided for QuickConnectButton!')
    return <></>
  }

  if (!device || !services) return <></>

  // We only want to show online services, so filter out
  // anything that is inactive
  const onlineServices = services.filter(s => s.Status !== 'inactive')
  return (
    <>
      <Button
        variant="contained"
        fullWidth
        disabled={launching}
        size="small"
        color="secondary"
        role="button"
        onClick={handleToggle}
      >
        {launching ? (
          <>
            {t('global.status.connecting')}
            <Icon name="spinner-third" spin weight="regular" className="ml-xs" />
          </>
        ) : (
          <>
            {t('global.status.connect')}
            <Icon
              name="chevron-down"
              // name={opened ? 'chevron-up' : 'chevron-down'}
              weight="regular"
              className="ml-xs"
              scale="sm"
            />
          </>
        )}
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={opened}
        onClose={handleClose}
      >
        {onlineServices.length ? (
          onlineServices.map((s, key) => (
            <MenuItem key={key} onClick={() => handleLaunch(s.Address, s.ServiceName, s.ServiceType)}>
              {s.ServiceName}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{t('quick-connect.no-available-services')}</MenuItem>
        )}
      </Menu>
    </>
  )
}
