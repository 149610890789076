// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".src-components-AppStoreBanner-___AppStoreBanner-module__x___30uAt {\n  position: absolute;\n  right: var(--spacing-md);\n  top: var(--spacing-md);\n}", "",{"version":3,"sources":["AppStoreBanner.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,wBAAwB;EACxB,sBAAsB;AACxB","file":"AppStoreBanner.module.css","sourcesContent":[".x {\n  position: absolute;\n  right: var(--spacing-md);\n  top: var(--spacing-md);\n}"]}]);
// Exports
exports.locals = {
	"x": "src-components-AppStoreBanner-___AppStoreBanner-module__x___30uAt"
};
module.exports = exports;
