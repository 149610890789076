import { Collapse, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { IContact } from 'remote.it'
import { generateContactOptions } from '../../models/contacts'
import theme from '../../styles/theme'
import { Alert } from '../Alert'
import { ShareSaveActions } from './ContactCardActions'
import { SharingAccess } from './DeviceShareDetails'
import { SharingForm } from './SharingForm'

export function DeviceShareAdd({
  contacts,
  onChangeAccess,
  onChangeContacts,
  share,
  shares = [],
  sharing = false,
  selectedContacts,
  selectedScripting,
  selectedServices,
  services,
}: {
  contacts: IContact[]
  onChangeAccess: (access: SharingAccess) => void
  onChangeContacts: (contacts: string[]) => void
  share: () => Promise<void>
  shares: ShareInfo[]
  sharing?: boolean
  selectedContacts: string[]
  selectedScripting: boolean
  selectedServices: string[]
  services: SimplifiedService[]
}): JSX.Element {
  const { t } = useTranslation()

  function handleAccessChange(access: SharingAccess): void {
    onChangeAccess(access)
  }

  function handleContactChange(contacts: string[]): void {
    onChangeContacts(contacts)
  }

  if (!contacts.length && !shares.length) {
    return (
      <Alert type="info" variant="outlined" stayOpen className="my-md">
        <Typography>
          <strong>
            {t('pages.contact-selector.has-no-selectable-contacts-heading')}
          </strong>
        </Typography>
        <Typography>
          {t('pages.contact-selector.has-no-selectable-contacts-body')}
        </Typography>
      </Alert>
    )
  }

  return (
    <>
      <ContactSelector
        contacts={contacts}
        onChange={handleContactChange}
        selectedContacts={selectedContacts}
      />
      <Collapse
        in={Boolean(selectedContacts.length)}
        timeout="auto"
        unmountOnExit
      >
        <div className="my-lg">
          <SharingForm
            saving={sharing}
            services={services}
            scripting={selectedScripting}
            onChange={handleAccessChange}
            selectedServices={selectedServices}
          />
          <div className="right">
            <ShareSaveActions
              onCancel={() => onChangeContacts([])}
              onSave={share}
              saving={sharing}
            />
          </div>
        </div>
      </Collapse>
    </>
  )
  // <Alert type="info" variant="outlined" stayOpen className="my-lg">
  //   <Typography>
  //     <strong>
  //       {t('pages.device-share.share-initial-message-heading')}
  //     </strong>
  //   </Typography>
  //   <Typography>
  //     {t('pages.device-share.share-initial-message-body')}
  //   </Typography>
  // </Alert>
}

function ContactSelector({
  contacts,
  onChange,
  selectedContacts = [],
}: {
  contacts: IContact[]
  onChange: (latest: any) => void
  selectedContacts?: string[]
}): JSX.Element {
  const { t } = useTranslation()
  const options = generateContactOptions(contacts)

  function handleChange(opts: IReactSelectOption[]): void {
    opts = opts && opts.length ? opts : []
    onChange(opts.map(o => o.value))
  }

  return (
    <Select
      options={options}
      value={options.filter(o => selectedContacts.includes(o.value))}
      placeholder={t('pages.contact-selector.placeholder.multi')}
      isMulti
      isClearable
      maxMenuHeight={200}
      onChange={handleChange}
      styles={{
        control: (styles: any) => ({
          ...styles,
          padding: theme.spacing.xs,
          ':hover': {
            borderColor: theme.colors.primary,
          },
        }),
        option: (
          styles: any,
          { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }
        ) => ({
          ...styles,
          backgroundColor:
            isFocused || isSelected ? theme.colors.primaryLightest : 'white',
          ':hover': {
            backgroundColor: theme.colors.primaryLightest,
          },
        }),
        multiValue: (styles: any) => ({
          ...styles,
          backgroundColor: theme.colors.primaryLightest,
          borderRadius: theme.radius.sm,
        }),
        multiValueLabel: (styles: any) => ({
          ...styles,
          paddingTop: theme.spacing.sm,
          paddingRight: theme.spacing.md,
          paddingBottom: theme.spacing.sm,
          paddingLeft: theme.spacing.md,
        }),
        multiValueRemove: (styles: any) => ({
          ...styles,
          paddingTop: theme.spacing.sm,
          paddingRight: theme.spacing.md,
          paddingBottom: theme.spacing.sm,
          paddingLeft: theme.spacing.md,
        }),
        placeholder: (styles: any) => ({
          ...styles,
          color: theme.colors.gray,
        }),
      }}
    />
  )
}
