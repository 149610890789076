import {
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core'
import FuzzySearch from 'fuzzy-search'
import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar } from '../Avatar'
import { Button } from '../Button'
import { ButtonIcon } from '../ButtonIcon'
import { ContactForm } from '../ContactForm'
// import { fromNow } from '../../utils/fromNow'
import { Icon } from '../Icon'
import { LoadingMessage } from '../LoadingMessage'
import { LoadingScreen } from '../LoadingScreen'

type FetchFunc = () => Promise<void>
type RemoveFunc = (email: string) => Promise<void>
type InviteFunc = ({
  email,
  id,
}: {
  email: string
  id: string
}) => Promise<void>
type CreateFunc = (contact: ContactFields) => Promise<void>
type UpdateFunc = (contact: ContactFields) => Promise<void>
type FilterFunc = (val: string) => void

export interface ContactsListProps {
  contacts: ContactFields[]
  create: CreateFunc
  fetch: FetchFunc
  invite: InviteFunc
  remove: RemoveFunc
  update: UpdateFunc
}

export function ContactsList({
  contacts,
  create,
  fetch,
  invite,
  remove,
  update,
}: ContactsListProps): JSX.Element {
  const { t } = useTranslation()
  const [fetching, setFetching] = React.useState<boolean>(false)
  const [creating, setCreating] = React.useState<boolean>(false)
  const [modalVisible, setModalVisible] = React.useState<boolean>(false)
  const [filter, setFilter] = React.useState<string>('')

  function handleFilter(val: string): void {
    setFilter(val)
  }

  function handleCloseCreateContactModal(): void {
    setModalVisible(false)
  }

  function handleOpenCreateContactModal(): void {
    setModalVisible(true)
  }

  async function handleCreateContact(contact: ContactFields): Promise<void> {
    setCreating(true)
    await create(contact)
    setCreating(false)
    setModalVisible(false)
  }

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setFetching(true)
      await fetch()
      setFetching(false)
    }
    fetchData()
  }, [])

  if (fetching)
    return <LoadingScreen text={t('pages.contacts.loading-message')} />

  const cleaned = contacts.map(c => {
    if (c.companyName === '-') c.companyName = ''
    if (c.firstName === '-') c.firstName = ''
    if (c.lastName === '-') c.lastName = ''
    return c
  })
  const searcher = new FuzzySearch(cleaned, ['firstName', 'lastName', 'email'])
  if (filter) contacts = searcher.search(filter)

  const grouped = group(contacts, 'companyName')
  const groupNames = Object.keys(grouped).sort()

  return (
    <Suspense fallback={<LoadingMessage text={t('global.actions.loading')} />}>
      <Box mb={6} maxWidth="800px" height="100%">
        {modalVisible && (
          <CreateContactDialog
            creating={creating}
            onClose={handleCloseCreateContactModal}
            onSubmit={handleCreateContact}
          />
        )}

        <Box display="flex" justifyContent="center" my={8}>
          <Box flex={1} pr={5}>
            <FilterInput filter={handleFilter} value={filter} />
          </Box>
          <Box ml="auto">
            <Box textAlign="right">
              <CreateContactButton onClick={handleOpenCreateContactModal} />
            </Box>
          </Box>
        </Box>

        {groupNames.map((name, key) => (
          <ContactGroup
            contacts={grouped[name]}
            key={key}
            name={name}
            invite={invite}
            remove={remove}
            update={update}
          />
        ))}
      </Box>
    </Suspense>
  )
}

function ContactGroup({
  contacts,
  invite,
  name,
  remove,
  update,
}: {
  contacts: ContactFields[]
  invite
  name: string
  remove: RemoveFunc
  update: UpdateFunc
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <Box mt={2}>
      <Typography variant="h6" className="mb-md">
        {!name || name === '-' ? t('pages.contacts.ungrouped-label') : name}
      </Typography>
      <List>
        <Divider />
        {contacts.map((contact, key) => (
          <ContactItem
            contact={contact}
            key={key}
            invite={invite}
            remove={remove}
            update={update}
          />
        ))}
      </List>
    </Box>
  )
}

function ContactItem({
  contact,
  invite,
  remove,
  update,
}: {
  contact: ContactFields
  invite: InviteFunc
  remove: RemoveFunc
  update: UpdateFunc
}): JSX.Element {
  const { t } = useTranslation()
  const [editing, setEditing] = React.useState<boolean>(false)
  const [saving, setSaving] = React.useState<boolean>(false)
  const [removing, setRemoving] = React.useState<boolean>(false)
  const { accountCreated, email, firstName, id, inviteSent, lastName } = contact
  const first = firstName && firstName !== '-' ? firstName : ''
  const last = lastName && lastName !== '-' ? lastName : ''
  const fullName = first || last ? `${first} ${last}` : ''
  // const updated = fromNow(contact.updatedAt)

  async function handleRemove(): Promise<void> {
    if (!id) return
    setRemoving(true)
    await remove(id)
    setRemoving(false)
  }

  function handleShowEditForm(): void {
    setEditing(true)
  }

  function handleHideEditForm(): void {
    setEditing(false)
  }

  async function handleSave(c: ContactFields): Promise<void> {
    // if (!accountCreated) return
    setSaving(true)
    await update(c)
    setEditing(false)
    setSaving(false)
  }

  async function handleInvite(): Promise<void> {
    if (!contact.email || !contact.id) return
    setSaving(true)
    await invite({ email: contact.email, id: contact.id })
    setSaving(false)
  }

  return (
    <>
      <ListItem className="p-md">
        <ListItemIcon className="c-pointer">
          <Avatar email={email} round size="38" className="bg-primary" />
        </ListItemIcon>
        <ListItemText>
          {fullName && (
            <div>
              <strong>{fullName}</strong>
            </div>
          )}
          <div className="txt-sm gray-dark mt-xs">
            <Icon name="envelope" className="mr-xs" /> {email}
          </div>
        </ListItemText>
        <ListItemSecondaryAction>
          {editing ? (
            <div>
              <Tooltip
                enterDelay={500}
                title={t('pages.contacts.cancel-changes-tooltip').toString()}
              >
                <IconButton
                  aria-label={t('pages.contacts.cancel-changes-tooltip')}
                  className="mr-md"
                  onClick={handleHideEditForm}
                  disabled={saving}
                >
                  <Icon name="times" fixedWidth className="txt-md" />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div>
              {!accountCreated && (
                <Tooltip
                  title={t('pages.contacts.invite-user-label').toString()}
                >
                  {inviteSent ? (
                    <Tooltip
                      enterDelay={500}
                      title={t('pages.contacts.invite-sent').toString()}
                    >
                      <IconButton>
                        <Icon name="check" className="txt-md" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Fab
                      onClick={handleInvite}
                      disabled={saving}
                      size="small"
                      color="primary"
                      className="mr-md"
                    >
                      {saving ? (
                        <Icon
                          name="spinner-third"
                          spin
                          fixedWidth
                          className="txt-md"
                        />
                      ) : (
                        <Icon name="share" fixedWidth className="txt-md" />
                      )}
                    </Fab>
                  )}
                </Tooltip>
              )}
              <Tooltip
                enterDelay={500}
                title={t('pages.contacts.show-edit-form-tooltip').toString()}
              >
                <IconButton onClick={handleShowEditForm} disabled={saving}>
                  <Icon name="pencil" fixedWidth className="txt-md" />
                </IconButton>
              </Tooltip>
              <Tooltip
                enterDelay={500}
                title={t('pages.contacts.remove-contact-tooltip').toString()}
              >
                <IconButton onClick={handleRemove} disabled={removing}>
                  {removing ? (
                    <Icon
                      name="spinner-third"
                      spin
                      fixedWidth
                      className="txt-md"
                    />
                  ) : (
                    <Icon name="trash-alt" fixedWidth className="txt-md" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={editing} timeout="auto" unmountOnExit>
        <Box pt={2} pr={2} pl={9} pb={5}>
          <ContactForm
            contact={contact}
            onSubmit={handleSave}
            saving={saving}
            variant="update"
          />
        </Box>
      </Collapse>
      <Divider />
    </>
  )
}

function CreateContactDialog({
  creating,
  onClose,
  onSubmit,
}: {
  creating: boolean
  onClose: () => void
  onSubmit: (contact: ContactFields) => Promise<void>
}): JSX.Element {
  const { t } = useTranslation()
  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open
      scroll="paper"
    >
      <DialogTitle className="df ai-center w-100" disableTypography>
        <Typography variant="h6" className="f-1">
          {t('pages.contacts.create-contact-dialog-title')}
        </Typography>
        <IconButton onClick={onClose} className="ml-auto">
          <Icon name="times" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box p={4}>
          <ContactForm
            contact={{
              companyName: '',
              email: '',
              firstName: '',
              language: 'en',
              lastName: '',
            }}
            onSubmit={onSubmit}
            saving={creating}
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

function FilterInput({
  filter,
  value,
}: {
  filter: FilterFunc
  value: string
}): JSX.Element {
  const { t } = useTranslation()

  return (
    <FormControl fullWidth>
      <Input
        autoCorrect="off"
        autoCapitalize="off"
        autoComplete="off"
        autoFocus
        type="search"
        name="search"
        value={value}
        placeholder={t('pages.contacts.contact-search-label')}
        onChange={e => {
          filter(e.currentTarget.value)
        }}
        startAdornment={
          <InputAdornment position="start">
            <Icon name="search" />
          </InputAdornment>
        }
        endAdornment={
          filter && (
            <InputAdornment position="end">
              <Tooltip title="Clear search">
                <IconButton onClick={() => filter('')} size="small">
                  <Icon name="times" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }
      />
    </FormControl>
  )
}

function CreateContactButton({
  onClick,
}: {
  onClick: () => void
}): JSX.Element {
  return (
    <Button type="button" onClick={onClick}>
      <ButtonIcon icon="plus" />
      Create Contact
    </Button>
  )
}

function group(
  contacts: ContactFields[],
  key: string
): { [name: string]: ContactFields[] } {
  const groups = contacts.reduce((all, x) => {
    ;(all[x[key]] = all[x[key]] || []).push(x)
    return all
  }, {} as { [name: string]: ContactFields[] })

  return Object.entries(groups).reduce((all, [name, contacts]) => {
    all[name] = contacts.sort(sortBy('firstName'))
    return all
  }, {})
}

function sortBy(key: string): (a: ContactFields, b: ContactFields) => number {
  return (a: ContactFields, b: ContactFields): number => {
    const contactA = a[key].toUpperCase()
    const contactB = b[key].toUpperCase()

    if (contactA > contactB) return 1
    if (contactA < contactB) return -1
    return 0
  }
}
