import React from 'react'
import { Icon } from '../Icon'
import { Section } from '../Section'
import { TextBlock } from '../TextBlock'

interface DeleteAccountSectionProps {
  email: string
  paidPlan?: boolean
}


export const DeleteAccountSection = ({ email, paidPlan = false }: DeleteAccountSectionProps) => {
  return (
    <Section title="Delete account" icon="sad-tear">
      <TextBlock>
        If you no longer want/need your remote.it account, you can request an account deletion. Once your delete request
        is processed, all your account information is removed permanently.
      </TextBlock>
      {paidPlan ? (
        <div className="df ai-center bg-warning-lightest warning-dark my-lg px-lg py-md">
          <Icon name="exclamation-triangle" className="mr-md txt-lg" />
          <strong>
            You have a paid subscription plan. Please delete or transfer your devices before making an account deletion request.
          </strong>
        </div>
      ) : (
        <div className="mt-lg">
          <a
            className="danger"
            href={encodeURI(
              `mailto:support@remote.it?subject=Delete my remote.it account&body=Please delete my account: ${email} \n\nI understand that this is permanent and that accounts and related devices can not be recovered.`
            )}
          >
            Delete my account
          </a>
        </div>
      )}
      <TextBlock className="mb-none txt-sm italic gray-dark">
        Deletion requests take 3 to 5 business days to complete.
      </TextBlock>
    </Section>
  )
}
