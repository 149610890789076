import axios from 'axios'
import { getToken } from '../../services/remote.it'

const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL

export const axiosGraphQL = async () =>
  axios.create({
    baseURL: GRAPHQL_API_URL,
    headers: {
      Authorization: await getToken(),
    },
  })

export const fetchAccessKeys = async () => {
  return (await axiosGraphQL())
    .post('', { query: GET_ACCESS_KEYS })
    .then(response => {
      return response
    })
    .catch(error => {
      console.log('Access Key fetch error', error)
      return error
    })
}

export const toggleAccessKeys = async properties => {
  return (await axiosGraphQL())
    .post('', { query: RETREIVE_ACCESS_KEYS, variables: properties })
    .then(response => {
      return response
    })
    .catch(error => {
      console.log('Access Key toggle error: ', error)
      return error
    })
}

export const deleteAccessKeys = async properties => {
  return (await axiosGraphQL())
    .post('', {
      query: DELETE_ACCESS_KEYS,
      variables: properties,
    })
    .then(response => {
      return response
    })
    .catch(error => {
      console.error('Access Key delete error:', error)
      return error
    })
}

export const createAccessKey = async () => {
  return (await axiosGraphQL())
    .post('', { query: CREATE_ACCESS_KEYS })
    .then(response => {
      return response
    })
    .catch(error => {
      console.log('Access Key create error:', error)
      return error
    })
}

export const GET_ACCESS_KEYS = `query {
  login {
    accessKeys {
      key
      enabled
      created
      lastUsed
    }
  }
}`
export const CREATE_ACCESS_KEYS = `mutation {
  createAccessKey {
    key
    secret
  }
}`
export const RETREIVE_ACCESS_KEYS = ` 
  mutation updateAccessKey($key: String!, $enabled: Boolean!) {
    updateAccessKey(key: $key, enabled: $enabled) {
      key
      enabled
    }
}`
export const DELETE_ACCESS_KEYS = ` 
  mutation deleteAccessKey($key: String!) {
    deleteAccessKey(key: $key) 
}`
