import { IconButton, Tooltip } from '@material-ui/core'
import React from 'react'
import { Icon } from '../Icon'

export interface HelpIconProps {
  className?: string
  href: string
  size?: 'small' | 'medium'
  title: string
}

export function HelpIcon({
  className = '',
  href,
  size = 'medium',
  title,
}: HelpIconProps): JSX.Element {
  return (
    <Tooltip title={title} className={className}>
      <IconButton href={href} target="_blank">
        <Icon name="question-circle" scale={size === 'medium' ? 'lg' : 'md'} />
      </IconButton>
    </Tooltip>
  )
}
