import React from 'react'
import { ApplicationState } from '../../store'
import { Button } from '../Button'
import { Section } from '../Section'
import { connect } from 'react-redux'
import { TextBlock } from '../TextBlock'
import { useTranslation } from 'react-i18next'

interface Props {}
export type GlobalSignOutProps = Props & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  // AWSUser: state.auth.AWSUser,
})

const mapDispatch = (dispatch: any) => ({
  globalSignOut: dispatch.auth.globalSignOut,
})

export const GlobalSignout = connect(
  mapState,
  mapDispatch
)(({ globalSignOut }: GlobalSignOutProps) => {
  const { t } = useTranslation()

  const callSignOut = event => {
    event.preventDefault()
    globalSignOut()
  }
  return (
    <Section title={t('pages.global-signout.title')} icon="key">
      <TextBlock>{t('pages.global-signout.text')}</TextBlock>
      <form style={{ maxWidth: '360px' }} onSubmit={callSignOut}>
        <div className="mt-lg">
          <Button type="submit">{t('pages.global-signout.signout-all-button')}</Button>
        </div>
      </form>
    </Section>
  )
})
