import React from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '../Icon'
import { IconButton, Tooltip } from '@material-ui/core'
import { useApolloClient } from '@apollo/react-hooks'
import { GET_LOGS_URL } from '../../services/apolloClient'

export function EventDownloadButton(): JSX.Element {
  const client = useApolloClient()
  const { t } = useTranslation()

  return (
    <Tooltip title={t('global.actions.download').toString()}>
      <IconButton
        onClick={async () => {
          const { data } = await client.query({
            query: GET_LOGS_URL,
            variables: {},
          })
          if (data && data.login) {
            const url = data.login.eventsUrl
            window.open(url)
          }
        }}
      >
        <Icon name="file-download" fixedWidth scale="lg" />
      </IconButton>
    </Tooltip>
  )
}
