import React, { useState } from 'react'
import './PasswordStrengthInput.css'
import zxcvbn from 'zxcvbn'
import { Input } from '../Input'
import { useTranslation } from 'react-i18next'
import { TextBlock } from '../TextBlock'

export interface Props {
  isNewPassword?: boolean
  onChange: (password: string, isValid: boolean) => void
}

export function PasswordStrengthInput({ isNewPassword, onChange }: Props) {
  const { t } = useTranslation()
  const PASSWORD_MIN_LENGTH = Number(process.env.PASSWORD_MIN_LENGTH)
  const PASSWORD_MAX_LENGTH = Number(process.env.PASSWORD_MAX_LENGTH)
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [valid, setValid] = useState<boolean>(false)
  const [tooShort, setTooShort] = useState<boolean>(true)
  const [tooLong, setTooLong] = useState<boolean>(false)
  // const [hasDigit, setHasDigit] = useState<boolean>(true)
  // const [hasLower, setHasLower] = useState<boolean>(true)
  // const [hasUpper, setHasUpper] = useState<boolean>(true)
  // const [hasSpecialChar, setHasSpecialChar] = useState<boolean>(true)
  const [hasMatch, setHasMatch] = useState<boolean>(false)

  // const passwordSpecialChars = '^$*.[]{}()?-"!@#%&/,><\':;|_~'

  const checkTestedResult = pass => {
    return zxcvbn(pass).score
  }
  const checkTooShort = pass => {
    const value = (pass && pass.length) < PASSWORD_MIN_LENGTH
    setTooShort(value)
    return value
  }
  const checkTooLong = pass => {
    const value = (pass && pass.length) > PASSWORD_MAX_LENGTH
    setTooLong(value)
    return value
  }
  // const checkHasDigit = pass => {
  //   const value = /(.*\d+.*)/g.test(pass)
  //   setHasDigit(value)
  //   return value
  // }
  // const checkHasLower = pass => {
  //   const value = /(.*[a-z]+.*)/g.test(pass)
  //   setHasLower(value)
  //   return value
  // }
  // const checkHasUpper = pass => {
  //   const value = /(.*[A-Z]+.*)/g.test(pass)
  //   setHasUpper(value)
  //   return value
  // }
  // const checkHasSpecialChar = pass => {
  //   const value = /(.*[$*.{}?"!@#%&/,><\':;|_~`^\]\[\)\(]+.*)/g.test(pass)
  //   setHasSpecialChar(value)
  //   return value
  // }
  const checkMatches = (pass, passConfirm) => {
    const value = pass !== '' && passConfirm !== '' && pass === passConfirm
    setHasMatch(value)
    return value
  }
  const checkPasswordConfirmation = e => {
    setPasswordConfirmation(e.target.value)
    const isValid = checkValid(password)
    const isMatching = checkMatches(password, e.target.value)
    sendChange(password, isValid && isMatching)
  }
  const checkPassword = e => {
    setPassword(e.target.value)
    const isValid = checkValid(e.target.value)
    const isMatching = checkMatches(e.target.value, passwordConfirmation)
    sendChange(password, isValid && isMatching)
  }
  const createPasswordStrengthLabel = score => {
    switch (score) {
      case 0:
        return t('pages.forgot-password-verify.password-strength-score.0')
      case 1:
        return t('pages.forgot-password-verify.password-strength-score.1')
      case 2:
        return t('pages.forgot-password-verify.password-strength-score.2')
      case 3:
        return t('pages.forgot-password-verify.password-strength-score.3')
      case 4:
        return t('pages.forgot-password-verify.password-strength-score.4')
      default:
        return t('pages.forgot-password-verify.password-strength-score.0')
    }
  }

  //Check full validation disabled for now
  // const checkValid = pass => {
  //   const isValid =
  //     !checkTooShort(pass) &&
  //     !checkTooLong(pass) &&
  //     checkHasDigit(pass) &&
  //     checkHasLower(pass) &&
  //     checkHasUpper(pass) &&
  //     checkHasSpecialChar(pass)
  //   setValid(isValid)
  //   return isValid
  // }
  const checkValid = pass => {
    const isValid = !checkTooShort(pass) && !checkTooLong(pass)
    setValid(isValid)
    return isValid
  }

  const sendChange = (pass, isValid) => {
    onChange(pass, isValid)
  }
  return (
    <>
      <div className="mb-lg">
        <TextBlock>
          {t('pages.forgot-password-verify.password-rules-reduced', {
            min_length: PASSWORD_MIN_LENGTH,
            max_length: PASSWORD_MAX_LENGTH,
          })}
          {/* <code className="bg-gray-lighter px-xs py-xxs special-chars">
            ${passwordSpecialChars.split('').join(' ')}
          </code> */}
        </TextBlock>
        {((password !== '' && !valid) ||
          (passwordConfirmation != '' && !hasMatch)) && (
          <div className="danger mt-sm">
            {t('pages.forgot-password-verify.password-error.invalid')}

            <ul>
              {tooShort && (
                <li>
                  {t('pages.forgot-password-verify.password-error.too-short', {
                    min_length: PASSWORD_MIN_LENGTH,
                  })}
                </li>
              )}
              {tooLong && (
                <li>
                  {t('pages.forgot-password-verify.password-error.too-long', {
                    max_length: PASSWORD_MAX_LENGTH,
                  })}
                </li>
              )}
              {/* {!hasDigit && (
                <li>
                  {t(
                    'pages.forgot-password-verify.password-error.missing-number'
                  )}
                </li>
              )}
              {!hasLower && (
                <li>
                  {t(
                    'pages.forgot-password-verify.password-error.missing-lower'
                  )}
                </li>
              )}
              {!hasUpper && (
                <li>
                  {t(
                    'pages.forgot-password-verify.password-error.missing-upper'
                  )}
                </li>
              )}
              {!hasSpecialChar && (
                <li>
                  {t(
                    'pages.forgot-password-verify.password-error.missing-special-char'
                  )}
                  <code className="bg-gray-lighter px-xs py-xxs special-chars">
                    ${passwordSpecialChars.split('').join(' ')}
                  </code>
                </li>
              )} */}
              {passwordConfirmation != '' && !hasMatch && (
                <li>
                  {t(
                    'pages.forgot-password-verify.password-error.passwords-do-not-match'
                  )}
                </li>
              )}
            </ul>
          </div>
        )}
        <div className="meter">
          <Input
            block
            type="password"
            autoComplete="new-password"
            className="txt-md px-md py-sm lh-md"
            onChange={e => checkPassword(e)}
            aria-label={t('global.user.password')}
            placeholder={t(
              `global.user.${isNewPassword ? 'new-' : ''}password-placeholder`
            )}
          />
          <div>
            {valid && (
              <>
                <progress
                  value={checkTestedResult(password)}
                  max="4"
                  className={`password-strength-meter strength-${checkTestedResult(
                    password
                  )}`}
                />
                <label className="password-strength-meter-label">
                  {t('pages.forgot-password-verify.password-strength-html', {
                    strength: createPasswordStrengthLabel(
                      checkTestedResult(password)
                    ),
                  })}
                </label>
              </>
            )}
          </div>
        </div>
      </div>
      <Input
        block
        type="password"
        autoComplete="new-password"
        onChange={e => checkPasswordConfirmation(e)}
        placeholder={t(
          `global.user.${
            isNewPassword ? 'new-' : ''
          }password-confirm-placeholder`
        )}
        aria-label={t('global.user.password-confirm')}
      />
    </>
  )
}
