import React from 'react'
import { Input } from './Input'
import { Button } from './Button'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'

type Props = {
  email: string
  totpCode: string
  loadTotpCode: () => Promise<void>
  totpVerified: boolean
  sendVerifyTotp: (event: any) => void
  setTotpVerificationCode: (event: any) => void
  totpVerificationCode: string
  loading: boolean
  cancel: (event: any) => void
}

export const MFAConfigureApp: React.FC<Props> = ({
  email,
  totpCode,
  loadTotpCode,
  totpVerified,
  sendVerifyTotp,
  setTotpVerificationCode,
  totpVerificationCode,
  loading,
  cancel,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <p>
        <b>{'Scan the QR below with your preferred Authenticator app to configure.'}</b>
      </p>
      {totpCode && (
        <div>
          <QRCode value={'otpauth://totp/AWSCognito:' + email + '?secret=' + totpCode + '&issuer=remote.it'}></QRCode>
          <p>
            <b>{'Code: ' + totpCode}</b>
          </p>
          <div>
            <a
              onClick={() => {
                loadTotpCode()
              }}
            >
              {'Generate new QR Code'}
            </a>
          </div>
        </div>
      )}
      {!totpVerified && (
        <>
          <div>
            <form onSubmit={sendVerifyTotp} style={{ maxWidth: '360px' }}>
              <div className="my-lg w-auto">
                <Input
                  autoCorrect="off"
                  autoCapitalize="none"
                  autoComplete="off"
                  required
                  onChange={e => setTotpVerificationCode(e.currentTarget.value.trim())}
                  value={totpVerificationCode}
                  className="mr-sm"
                  placeholder={t('pages.auth-mfa.verification-code-placeholder')}
                />
              </div>
              <div className="mt-lg">
                <Button
                  loading={loading}
                  disabled={!totpVerificationCode || totpVerificationCode.length < 6}
                  type="submit"
                >
                  {t('global.actions.submit')}
                </Button>
                <Button
                  onClick={event => {
                    cancel(event)
                  }}
                  link
                >
                  {t('global.actions.cancel')}
                </Button>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  )
}
