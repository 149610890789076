import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { Box } from '@material-ui/core'

export interface ExperienceRendererProps {
  current: React.ReactNode
  experience: string
  next: React.ReactNode
}

export function ExperienceRenderer({
  current,
  experience,
  next,
}: ExperienceRendererProps): JSX.Element {
  const { preferences, signInStarted } = useSelector(
    (state: ApplicationState) => ({
      preferences: state.auth.preferences,
      signInStarted: state.auth.signInStarted,
    })
  )

  if (signInStarted) return <></>

  if (!preferences) return <></>

  const showNext =
    preferences[`experience.${experience}`] &&
    preferences[`experience.${experience}`] === 'true'

  return <Box height="100%">{showNext ? next : current}</Box>
}
