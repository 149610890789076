import { createModel } from '@rematch/core'
import { IRawContact } from 'remote.it'
import { Contacts } from '../services/Contacts'
import { r3 } from '../services/remote.it'

interface ContactsState {
  all: ContactFields[]
  error: string
  fetching: boolean
  sharedContactsEmails: string[]
  selected: ContactOption[]
  contactOptions: ContactOption[]
}
interface ContactOption {
  label: string
  value: string
}

const state: ContactsState = {
  all: [],
  error: '',
  fetching: false,
  contactOptions: [],
  sharedContactsEmails: [],
  selected: [],
}

export default createModel({
  state,
  effects: dispatch => ({
    async fetch() {
      this.fetchStarted
      const list = await Contacts.fetch()
      dispatch.contacts.setList(list)
      this.fetchFinished
      return list
    },
    async update(updated: ContactFields) {
      await Contacts.update(updated)
      dispatch.contacts.addOrUpdate(updated)
    },
    async create(contact: ContactFields) {
      const newContact = await Contacts.create(contact)
      dispatch.contacts.addOrUpdate(newContact)
      await this.invite(newContact)
    },
    async destroy(id: string) {
      await Contacts.destroy(id)
      dispatch.contacts.remove(id)
    },
    async invite({ email, id }: { email: string; id: string }) {
      email = email.trim().toLowerCase()
      await Contacts.invite({ email, id })
      dispatch.contacts.inviteSent(email)
    },
    select(contacts: ContactOption[]) {
      return dispatch.contacts.setSelection(contacts)
    },
    async setContactsForDevices(devices: string[], rootState: any) {
      if (devices.length > 0) {
        dispatch.contacts.fetchStarted()

        try {
          const response = await r3.post(
            '/developer/device/share/list/merged',
            {
              devices: devices,
            }
          )

          if (response.status === 'true') {
            const contacts = response.shares.map(i => i.email)
            const filteredContacts = rootState.contacts.all.filter(contact =>
              contacts.includes(contact.email)
            )
            dispatch.contacts.setContactOptions(filteredContacts)
          }
        } catch (error) {
          dispatch.contacts.setContactOptions([])
          dispatch.contacts.fetchingError(error)
        }
        dispatch.contacts.fetchFinished()
      } else {
        dispatch.contacts.setContactOptions([])
      }
    },
    // exclude the emails which are passed in.
    async generateContactOptionsForSingleDevice(emails: string[], rootState) {
      dispatch.contacts.setSharedContactsEmails(emails)
      const filteredContacts =
        emails.length < 1
          ? rootState.contacts.all
          : rootState.contacts.all.filter(contact => {
              return !emails.includes(contact.email)
            })
      dispatch.contacts.setContactOptions(filteredContacts)
    },
  }),
  reducers: {
    remove(state: ContactsState, id: string) {
      const match = state.all.find(c => c.id === id)
      if (!match) return
      state.all.splice(state.all.indexOf(match), 1)
    },
    addOrUpdate(state: ContactsState, contact: ContactFields) {
      if (!contact.id) return
      let exists = state.all.find(c => c.id === contact.id)
      if (exists) exists = contact
      else state.all.push(contact)
    },
    setList(state: ContactsState, contacts: ContactFields[]) {
      state.all = contacts
    },
    setSelection(state: ContactsState, contacts: ContactOption[]) {
      state.selected = contacts
    },
    setSharedContactsEmails(state: ContactsState, emails: string[]) {
      state.sharedContactsEmails = emails
    },
    fetchStarted(state: ContactsState) {
      this.clearError
      state.fetching = true
    },
    fetchFinished(state: ContactsState) {
      state.fetching = false
    },
    inviteSent(state: ContactsState, email: string) {
      const contact = state.all.find(c => c.email === email)
      if (contact) contact.inviteSent = true
    },
    clearError(state: ContactsState) {
      state.error = ''
    },
    fetchingError(state: ContactsState, error) {
      state.error = error
    },
    addFromLegacy(state: ContactsState, contact: IRawContact) {
      state.all.push(convertLegacyContact(contact))
    },
    setFromLegacy(state: ContactsState, contacts: IRawContact[]) {
      state.all = contacts.map(convertLegacyContact)
    },
    setContactOptions(state: ContactsState, contacts: ContactFields[]) {
      state.contactOptions = generateContactOptions(contacts)
    },
  },
})

function convertLegacyContact(legacy: IRawContact): ContactFields {
  return {
    accountCreated: legacy.account_created === 'yes',
    companyName: legacy.company_name,
    createdAt: new Date(legacy.created),
    email: legacy.email,
    firstName: legacy.first_name,
    id: legacy.id,
    lastName: legacy.last_name,
    language: legacy.language,
    updatedAt: new Date(legacy.updated),
  }
}

export function generateContactOptions(
  allContacts: ContactFields[]
): IReactSelectOption[] {
  if (!allContacts.length) return []

  return allContacts
    .filter(contact => {
      if (contact.accountCreated === true) {
        return true
      }
      return false
    })
    .map(c => {
      return createOption(c)
    })
}

function createOption(contact): IReactSelectOption {
  const label =
    // Handle API generated blank names
    (contact.firstName === '-' && contact.lastName === '-') ||
    // Handle missing names
    (!contact.firstName && !contact.lastName)
      ? contact.email
      : `${contact.firstName} ${contact.lastName} <${contact.email}> `
  const opt: IReactSelectOption = { value: contact.email, label }
  return opt
}
