import analytics from './Analytics'

export interface Route {
  title: string
  action?: (params: any) => void
  modal?: boolean
}

/**
 * Setup a interface so we can test mocked instances
 * of the window object.
 */
interface HashWindow {
  addEventListener: (event: string, callback: () => void) => void
  location: {
    hash: string
    pathname: string
    search: string
  }
}

interface Routes {
  [key: string]: Route
}

export default class Router {
  private window: HashWindow
  private routes: Routes
  private fallback: string

  public constructor(
    routes: Routes,
    fallback: string,
    windowRef: HashWindow = window
  ) {
    this.window = windowRef
    this.routes = routes
    this.fallback = fallback
    this.window.addEventListener('hashchange', (): void => this.showPage())

    // Show on initial load of app.
    this.showPage()
  }

  public showPage = (): void => {
    const hash = this.window.location.hash

    // Find the given route or revert to the
    // fallback route.
    let { match, params } = this.matchRoute(hash)
    if (!match) match = this.routes[this.fallback]

    // Hide all other pages by default
    if (!match.modal) this.hideAllpages()

    // Trigger the given action
    if (match.action) match.action(params)

    this.changePageTitle(match.title)
    this.trackPageChange()
  }

  public changePageTitle = (title: string): void => {
    document.title = `${title} - remote.it`
  }

  private matchRoute = (hash: string): { match: Route; params: any } => {
    const match = this.routes[hash]

    // If no direct match, see if it might be a route
    // with a URL param.
    if (match) return { match, params: {} }

    const hashParts = hash.split('/')

    if (hashParts.length > 1) {
      for (const route of Object.keys(this.routes)) {
        const routeParts = route.split('/')

        // The route parts should be the same length as the actual
        // URL hash.
        if (routeParts.length != hashParts.length) {
          continue
        }

        const params = {}
        let matchedParts = 0

        for (const [index, routePart] of routeParts.entries()) {
          const hashPart = hashParts[index]

          // Part is a URL parameter so store it for later.
          if (routePart.includes(':')) {
            params[routePart.replace(':', '')] = hashPart
            matchedParts += 1
          }

          // The route part matches the corresponding URL path.
          if (routePart === hashPart) {
            matchedParts += 1
          }
        }

        // Every item in the route should match the URL hash to be
        // considered valid
        if (matchedParts != routeParts.length) {
          continue
        }

        return { match: this.routes[route], params }
      }
    }

    return { match, params: {} }
  }

  private trackPageChange = (): void => {
    const location = this.window.location
    const hash = location?.hash?.replace('#', '') || 'devices'
    analytics.page(hash)
  }

  private hideAllpages = (): void => {
    Array.from(document.querySelectorAll<HTMLDivElement>('.page')).map(p => {
      p.style.display = 'none'
    })
  }
}
