// import debug from 'debug'
import cookie from 'js-cookie'
import { createModel } from '@rematch/core'
import { ApplicationState } from '../store'

// const d = debug('r3:models:nav')

export const SIDEBAR_COLLAPSE_COOKIE_NAME = 'remoteit.collapse-sidebar'
const className = 'sidebar-collapsed'

export type SelectorType = 'device-transfer' | 'sharing' | 'remove-sharing'
export interface NavState {
  collapsed: boolean
  bulkActionType?: SelectorType
  showTransferConfirmation: boolean
}

const state: NavState = {
  collapsed: false,
  showTransferConfirmation: false,
}

export default createModel({
  state,
  effects: (dispatch: any) => ({
    async toggleCollapse(payload: any, rootState: ApplicationState) {
      rootState.nav.collapsed ? dispatch.nav.expand() : dispatch.nav.collapse()
    },
    toggleShowTransferConfirmation(show: boolean) {
      return dispatch.nav.setShowTransferConfirmation(show)
    },
  }),
  reducers: {
    collapse(state: NavState) {
      state.collapsed = true

      cookie.set(SIDEBAR_COLLAPSE_COOKIE_NAME, 'true')
      // HACK: Once we are all in React we won't need this crap:
      document.body.classList.add(className)
    },
    expand(state: NavState) {
      state.collapsed = false

      cookie.set(SIDEBAR_COLLAPSE_COOKIE_NAME, 'false')

      // HACK: Once we are all in React we won't need this crap:
      document.body.classList.remove(className)
    },
    setShowTransferConfirmation(state: NavState, showState: boolean) {
      state.showTransferConfirmation = showState
    },
  },
})
