import React from 'react'
import { makeStyles, Grid, Button, Box } from '@material-ui/core'
import { Icon } from './Icon'

export const BannerTryNew: React.FC = () => {
  const css = useStyles()

  return (
    <Grid container spacing={1} alignItems="stretch" direction="row" justify="center" className="my-sm">
      <Grid item sm={12} md={12} lg={12}>
        <div className={css.modalMessage}>
          <Icon name="info-circle" className={css.icon} fixedWidth scale="lg" />
          <div className={css.message}>
            <b>You have reached the legacy web portal.</b>
            <br />
            We will continue supporting this site until all legacy features have been ported.
          </div>
          <Box display="flex" alignItems="center">
            <Button
              color="primary"
              className={css.button}
              onClick={() => (window.location.href = 'https://beta.remote.it/#/devices/welcome')}
              variant="text"
            >
              LATEST VERSION
            </Button>
          </Box>
        </div>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles({
  modalMessage: {
    backgroundColor: '#e5f5fd',
    padding: 20,
    display: 'flex',
    borderRadius: 4,
    fontSize: 14,
    color: '#1d5875',
    marginBottom: 10,
  },
  message: {
    color: '#1d5875',
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    display: 'flex',
    fontSize: '22px',
    opacity: '0.9',
    color: '#409ce8',
    marginTop: 8,
    marginLeft: 6,
  },
  button: { whiteSpace: 'nowrap', padding: '12px 24px' },
})
