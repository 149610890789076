import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { ApplicationState } from '../../store'
import { PasswordStrengthInput } from '../PasswordStrengthInput'
import { Alert } from '../Alert'
import { Button } from '../Button'
import { Input } from '../Input'
import { Section } from '../Section'
import { TextBlock } from '../TextBlock'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface Props {
  changePassword: (password: string) => Promise<void>
}
export type ChangePasswordProps = Props & ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  AWSUser: state.auth.AWSUser,
})

const mapDispatch = (dispatch: any) => ({
  changePassword: dispatch.auth.changePassword,
  globalSignOut: dispatch.auth.globalSignOut,
})

export const ChangePassword = connect(
  mapState,
  mapDispatch
)(({ AWSUser, changePassword, globalSignOut }: ChangePasswordProps) => {
  const [error, setError] = useState<string | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false)
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { t } = useTranslation()

  const setPasswordValidation = (password, valid) => {
    setPassword(password)
    setIsValidPassword(valid)
    setDisabled(valid && currentPassword !== '')
  }
  const evaluateCurrentPassword = e => {
    setCurrentPassword(e.target.value)
    setDisabled(isValidPassword && e.target.value !== '')
  }
  const updatePassword = event => {
    event.preventDefault()
    setMessage(null)
    setError(null)
    setLoading(true)
    changePassword({ currentPassword: currentPassword, password: password })
      .then(() => {
        setMessage(t('pages.change-password.success'))
        setCurrentPassword('')
        setPassword('')
        setPasswordChanged(true)
      })
      .catch(error => {
        console.error(error)
        setError(t(`pages.change-password.errors.${error.code}`))
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Section title={t('pages.change-password.title')} icon="key">
      {AWSUser && AWSUser.authProvider === 'Google' ? (
        <TextBlock>{t('pages.change-password.is-google-user')}</TextBlock>
      ) : (
        <form style={{ maxWidth: '360px' }} onSubmit={updatePassword}>
          {error && (
            <Alert className="my-md" type="danger" icon="danger" stayOpen={true}>
              {error}
            </Alert>
          )}
          {message && (
            <Alert className="my-md" type="success" icon="success" stayOpen={true}>
              {message}
            </Alert>
          )}
          {passwordChanged && (
            <Box padding={3} bgcolor="var(--color-gray-lightest)">
              {t('pages.change-password.signout-all-text')}
              <br />
              <br />
              <a className="danger" onClick={globalSignOut}>
                {t('pages.change-password.signout-all-button')}
              </a>
            </Box>
          )}
          <div className="my-lg">
            <input
              readOnly
              hidden
              type="text"
              name="username"
              value={AWSUser.email}
              autoComplete="username email"
            ></input>
            <Input
              block
              type="password"
              onChange={e => evaluateCurrentPassword(e)}
              placeholder={t('global.user.password-current-placeholder')}
              autoComplete="current-password"
            />
          </div>
          <div>
            <PasswordStrengthInput
              onChange={(password, valid) => setPasswordValidation(password, valid)}
              isNewPassword={true}
            />
          </div>
          <div className="mt-lg">
            <Button loading={loading} disabled={!disabled} type="submit">
              {t('pages.change-password.update-password-button')}
            </Button>
          </div>
        </form>
      )}
    </Section>
  )
})
