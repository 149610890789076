import React, { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { Icon } from '../Icon'
import { Box } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { LoadingMessage } from '../LoadingMessage'
import classnames from 'classnames'
import { Input } from '../Input'
import { axiosGraphQL, UPDATE_NOTIFICATION } from '../../../src/utils/graphQL'

const useStyles = makeStyles(theme => ({
  textField: {
    height: 30,
    paddingBottom: '10px',
    marginTop: '2px',
    marginLeft: '5px',
    fontSize: '13px',
    width: 300,
  },
  notificationUpdateLoader: {
    height: 40,
    marginLeft: '20px',
    paddingTop: '11px',
  },
  errorMsg: {
    marginLeft: '11.5em',
  },
}))
const is_url = str => {
  if (str.length) {
    const regexp = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi
    if (regexp.test(str)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export function NotificationMode({ ...props }): JSX.Element {
  const [emailChecked, setEmailChecked] = useState(true)
  const [webHook, setWebhook] = useState(false)
  const [webHookUrl, setWebhookUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [panelDisable, setPanelDisable] = useState(false)
  const [saveButtonText, setSaveButtonText] = useState('Save')
  const classes = useStyles()

  useEffect(() => {
    if (props.notificationEmail !== undefined) {
      setEmailChecked(props.notificationEmail)
      setSaveButtonText('Saved')
    } else {
      setEmailChecked(true)
    }
    
    if (props.notificationUrl && props.notificationUrl.length) { 
      setWebhookUrl(props.notificationUrl)
      setSaveButtonText('Saved')
    }
    setWebhook(props.urlNotifications)
  }, [props.notificationEmail, props.notificationUrl, props.urlNotifications])

  useEffect(() => {
    if (
      !props.onlineDeviceNotification &&
      !props.onlineSharedDeviceNotification
    ) {
      setPanelDisable(true)
    } else {
      setPanelDisable(false)
    }
  })

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailChecked(event.target.checked)
    setError(false)
    setSuccess(false)
    setSaveButtonText('Save')
  }

  const handleWebChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWebhook(event.target.checked)
    setWebhookUrl('')
    setError(false)
    setSuccess(false)
    event.target.checked ? setErrorFlag(true) : setErrorFlag(false)
    setSaveButtonText('Save')
  }

  const handleChange = value => {
    setWebhookUrl(value)
    const isValidUrl = is_url(value)
    setError(false)
    setSuccess(false)
    isValidUrl ? setErrorFlag(false) : setErrorFlag(true)
    setSaveButtonText('Save')
  }

  const handleSave = async () => {
    setLoading(true)
    if (errorFlag) {
      setError(true)
      setLoading(false)
      setSaveButtonText('Save')
    } else {
      const properties = {
        emailNotifications: emailChecked,
        notificationUrl: webHook && webHookUrl?.length ? webHookUrl : '',
        urlNotifications: webHook
      }

      const graphQL = await axiosGraphQL()
      graphQL
        ?.post('', {
          query: UPDATE_NOTIFICATION,
          variables: properties,
        })
        .then(response => {
          setLoading(false)
          setSuccess(true)
          setError(false)
          setSaveButtonText('Saved')
        })
    }
  }
  
  return (
    <Box>
      <Box height={40}>
        <Checkbox
          checked={emailChecked}
          disabled={panelDisable}
          color="primary"
          onChange={handleEmailChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />

        <Icon
          name="envelope"
          color="gray"
          className="w-5 center txt-lg"
          weight="regular"
        />
        <Typography component="span">Email</Typography>
      </Box>

      <Box>
        <Checkbox
          checked={webHook}
          disabled={panelDisable}
          color="primary"
          onChange={handleWebChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Icon
          name="link"
          color="gray"
          className="w-5 center txt-lg"
          weight="regular"
        />
        <Typography component="span">Webhook</Typography>
        <Input
          disabled={!webHook}
          onChange={e => handleChange(e.currentTarget.value)}
          value={webHookUrl}
          placeholder="Webhook Endpoint URL"
          type="text"
          required
          style={{ padding: '4px' }}
          className={classes.textField}
        />
      </Box>
      {error && (
        <Typography className={classnames('txt-sm danger', classes.errorMsg)}>
          Please provide a valid URL
        </Typography>
      )}
      <Box className="mt-sm" display="flex" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={panelDisable || saveButtonText === 'Saved'}
        >
          {!loading ? saveButtonText : 'Saving'}
        </Button>
        {loading && (
          <LoadingMessage
            size="md"
            text=""
            className={classes.notificationUpdateLoader}
          />
        )}
        {success && (
          <>
            <Icon name="check-circle" className={`success ml-md txt-lg`} />
            <span className="txt-sm success">&nbsp;&nbsp;Changes Saved</span>
          </>
        )}
      </Box>
    </Box>
  )
}
