import React from 'react'
import { LoadingMessage } from '../LoadingMessage'
import { useTranslation } from 'react-i18next'
import { Icon } from '../Icon'
import { Button, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import cookies from 'js-cookie'
import { ApplicationState } from '../../store'
import { EMAIL_COOKIE } from '../../models/auth'
enum TEXT_MODAL {
  title = `You've exceeded your 5 free device limit of your remote.it Personal plan.`,
  subTitle = `Please upgrade your plan to access this device.`,
  sharedTitle = `Connections to unlicensed devices are not allowed.`,
  sharedSubtitle = `Contact the device owner to license this device.`,
}
export function DeviceDetailsModal(): JSX.Element {
  const { t } = useTranslation()
  const css = useStyles()
  const { selectedDevices } = useSelector((state: ApplicationState) => ({
    selectedDevices: state.devices.selectedDevices,
  }))
  const username = cookies.get(EMAIL_COOKIE)
  const owner = selectedDevices.Owner === username

  return (
    <div
      className="modal inmodal"
      data-backdrop="static"
      id="Device-Services-Modal"
      tabIndex={-1}
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog" id="services-modal-body">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => window.jQuery('#Device-Services-Modal').modal('hide')}
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            <h4 className="modal-title">Device Services</h4>
            {/* <strong>Connect to your services.</strong> */}
            <br />
            <br /> The following Services are available on Device{' '}
            <strong>
              <span id="device-services-selected-name" className="notranslate"></span>
            </strong>
            .
          </div>
          <div className="modal-body">
            {selectedDevices.license === 'UNLICENSED' && (
              <div className={css.modalMessage}>
                <Icon name="exclamation-triangle" className={css.icon} fixedWidth />
                <div className={css.message}>
                  <b>{owner ? TEXT_MODAL.title : TEXT_MODAL.sharedTitle}</b>
                  <br />
                  {owner ? TEXT_MODAL.subTitle : TEXT_MODAL.sharedSubtitle}
                </div>
                {owner && (
                  <Button
                    className={css.learnMore}
                    onClick={() => window.jQuery('#Device-Services-Modal').modal('hide')}
                    href="#account"
                    size="small"
                    variant="text"
                  >
                    UPGRADE
                  </Button>
                )}
              </div>
            )}
            <table
              className="table table-stripped notranslate"
              data-empty="Nothing to Display"
              id="device-service-table"
              data-sorting="true"
              data-expand-first="true"
              data-filtering="true"
            >
              <tbody className="notranslate"></tbody>
            </table>
            <div id="device-services-div" className="of-auto w-100"></div>
            <span
              className="label label-warning move-left"
              style={{ fontSize: 'small', textAlign: 'center' }}
              id="device-services-selection-status"
            ></span>
            <div id="device-connecting-status" style={{ display: 'none' }}>
              <div className="my-lg center txt-lg gray-dark">
                <LoadingMessage />
              </div>
            </div>
            <div className="hidden" id="connection-ip-restriction">
              <p>
                <strong>IP restriction:</strong> Limits the connection to the originating connection IP address.
              </p>
              <h3 className="fw-bold mt-md">Having trouble connecting?</h3>
              <p>
                Mobile providers may use multiple source IP addresses. You may need to disable IP restrictions to
                connect to your devices from these platforms. NOTE: IP restriction will remain off for the duration of
                the connection.
              </p>
              <p>
                <label>
                  <input type="checkbox" id="disable-ip-restriction" className="mr-md" />
                  {t('pages.device-detail.make-public-checkbox-label')}
                </label>
              </p>
            </div>
          </div>
          <div className="modal-footer ">
            <button
              type="button"
              className="btn btn-white"
              onClick={() => {
                window.jQuery('#Device-Services-Modal').modal('hide')
                window.jQuery('#connection-error').remove()
              }}
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  modalMessage: {
    backgroundColor: '#fef4e5',
    padding: 11,
    display: 'flex',
    borderRadius: 4,
    fontSize: 14,
    color: '#efa831',
    marginBottom: 10,
  },
  message: {
    color: '#693e03',
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    display: 'flex',
    fontSize: '22px',
    opacity: '0.9',
  },
  learnMore: {
    color: '#77bee8',
    marginLeft: 'auto',
  },
})
