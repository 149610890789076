/**
 * getDeviceAndServices returns a legacy representation of the give
 * remote.it device and services. If anything goes wrong, it returns
 * an empty device and services list.
 */
export default function getDeviceAndServices(
  deviceID: string
): { device?: LegacyDevice; services?: LegacyService[] } {
  let device: LegacyDevice | undefined = undefined
  let services: LegacyService[] = []

  try {
    device = window.rState.getDevice(deviceID)

    if (!device || !device.HWID) return { device, services }

    // Get the services for this device, omitting bulk services.
    const ids = window
      .findServiceIDsByHWID(device.HWID)
      .filter(id => !id.startsWith('00:23'))

    services = ids
      .map(id => window.rState.getService(id))
      .filter(s => Object.keys(s).length)
  } catch (error) {
    if (!error.message.includes('Unexpected end of JSON input')) {
      console.error(error)
    }
    return { device, services }
  }

  return { device, services }
}
