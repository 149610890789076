export default function renderShareIcon(
  id: string,
  name: string,
  state: 'shared-to' | 'shared-from' | 'not-shared',
  owner: string
): string {
  const icon = 'fal fa-user-friends fa-1x'
  let label = ''
  let title = ''
  let action = ''

  switch (state) {
    case 'not-shared':
      title = 'Manage sharing'
      label = ''
      action = 'showSharingModal'
      break
    case 'shared-to':
      title = 'Manage sharing'
      action = 'showSharingModal'
      break
    case 'shared-from':
      title = 'Shared to me'
      action = 'showRejectSharingModal'
      break
  }

  return `<a href="#" onclick="${action}('${id}','${name}');"><span class="${icon} ${
    icon ? 'fal fa-1x' : ''
  }" title="${title}">${label}</span></a>`
}
