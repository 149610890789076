import debug from 'debug'
import { store } from '../../store'
import DeviceListRenderer from './DeviceListRenderer'
import DeviceRequestHelper from './DeviceRequestHelper'

const MAX_DEVICE_LIST_COUNT = 8000
// const DEVICE_LIST_REFRESH_INTERVAL = 2 * 60 * 1000 // 2 min

const d = debug('r3:legacy:DeviceSearch')

export default class DeviceSearch {
  private static query = ''
  private static metadata: RawMetaData = {}
  private static services: RawService[] = []
  private static searchOnly = false

  /**
   * The primary method of this class which triggers a device search or
   * list depending on the current parameters/state. This will in turn
   * render the device list with the results of the query/search.
   *
   * @param refresh Whether to do a hard refresh of the device list.
   */
  public static async deviceSearch(refresh?: boolean): Promise<void> {
    d('running device search with refresh:', refresh)

    const renderer = new DeviceListRenderer()

    renderer.startLoadSpinner(refresh)

    console.log('Search Only: ' + this.searchOnly)
    // Fetch the list of devices baaed on the given configuration
    // and then store this data for other methods to access.

    if (this.searchOnly) {
      d('search only mode enabled')
      this.query = window.$('#searchText').val() || ''
      renderer.query = this.query
      d('search query:', this.query)

      // Empty the device list in search only mode since we always
      // want it empty for each new search.
      renderer.clearDeviceList()
    }

    let findDeicesPromise = DeviceRequestHelper.findDevices(
      this.searchOnly,
      this.query,
      refresh
    )

    const newSearchOnly = await this.isSearchOnly()
    if (newSearchOnly != this.searchOnly) {
      this.searchOnly = newSearchOnly

      if (this.searchOnly) {
        d('search only mode enabled')
        this.query = window.$('#searchText').val() || ''
        renderer.query = this.query
        d('search query:', this.query)
        // Empty the device list in search only mode since we always
        // want it empty for each new search.
        renderer.clearDeviceList()
      }

      findDeicesPromise = DeviceRequestHelper.findDevices(
        this.searchOnly,
        this.query,
        refresh
      )
    }
    renderer.searchOnly = this.searchOnly

    d('seach only enabled:', this.searchOnly)

    await store.dispatch.devices.setSearchOnly(this.searchOnly)

    const [services, metadata, error] = await findDeicesPromise

    this.services = services
    this.metadata = metadata

    renderer.services = this.services
    renderer.metadata = this.metadata
    renderer.error = error

    // Construct a device list renderer with the current
    // device search data and configuration and then
    // render the device list.
    renderer.render()

    // Indicate loading of the device list is complete.
    renderer.stopLoadSpinner()

    // Indicate loading of the device list is complete.
    //this.stopSpinner()

    // NOTE: For now, it is safest NOT to refresh since it is likely
    // to mess things up for the user. Instead we should likely have
    // realtime updates instead.
    // Handle automatically refreshing device list data regularly
    // but only if a standard "device list" view not a search view.
    // if (!this.searchOnly) {
    //   setTimeout(() => this.deviceSearch(), DEVICE_LIST_REFRESH_INTERVAL)
    // }
  }

  /**
   * A utility method to allow legacy code to access all the
   * services that belong to a HWID, which represents all
   * the services for a device.
   *
   * @param hardwareID HWID of the device
   */
  public static findServiceIDsByHWID(HWID: string): string[] {
    d('finding service IDs by HWID:', HWID)
    return Object.entries(this.metadata).reduce((all, [serviceID, meta]) => {
      if (meta.hardwareid === HWID) all.push(serviceID)
      return all
    }, [] as string[])
  }

  /**
   * A utility method to allow legacy code to access the data
   * associated with a given service in the local collection of
   * found services.
   *
   * @param id The UID (address) of the service
   */
  public static findService(id: string): RawService | undefined {
    d('finding a service with the ID:', id)
    return this.services.find(
      serv => serv.deviceaddress?.toLowerCase() === id.toLowerCase()
    )
  }

  /**
   * Whether or not to perform a search-only device search or
   * just return a full device list.
   */
  private static async isSearchOnly(): Promise<boolean> {
    d('checking if search only mode enabled')
    const count = await DeviceRequestHelper.devicesCount()
    const searchOnly = count > MAX_DEVICE_LIST_COUNT
    d('searh only mode enabled?', searchOnly)
    return searchOnly
  }
}
