import React, { useEffect, useState } from 'react'
import { IconButton, Tooltip, Box } from '@material-ui/core'
import { Icon, IconProps } from '../Icon'
import fullLogo from '../../images/portal-logo.svg'
import logoMark from '../../images/portal-r3-logo.svg'
import { Avatar } from '../Avatar'
import { ApplicationState } from '../../store'
import { connect } from 'react-redux'
import { CoBrandingLogo } from '../CoBrandingLogo'
import classNames from 'classnames'
import styles from './Sidebar.module.css'
export const COLLAPSED_WIDTH = '70px'
export const FULL_WIDTH = '220px'

export type SidebarMenuProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch> & {
    email?: string
  }

function Sidebar({
  collapsed,
  checkForUpdates,
  partnerPortalAccess,
  signOut,
  user,
  viewReleaseNotes,
  updatedReleaseNotesAvailable,
}: SidebarMenuProps): JSX.Element {
  const [cobranded, setCobranded] = React.useState<boolean>(false)
  const [hash, setHash] = useState<string>(window.location.hash)
  const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false)

  useEffect(() => {
    checkForUpdates()

    function hashChanged() {
      setHash(window.location.hash)
    }

    window.addEventListener('hashchange', hashChanged, false)

    return () => window.removeEventListener('hashchange', hashChanged, false)
  }, [])

  const mainMenuItems: MenuItem[] = [
    { label: 'Devices', href: '#devices', icon: 'chart-network' },
    { label: 'Scripting', href: '#scripting', icon: 'code' },
    { label: 'Registrations', href: '#registrations', icon: 'upload' },
    { label: 'Products', href: '#products', icon: 'server' },
  ]

  const accountMenuItems: MenuItem[] = [{ label: 'Sign Out', action: signOut, icon: 'sign-out' }]

  const email = user ? user.email : undefined

  return (
    <nav
      className={classNames('w-100 h-100', styles.nav, cobranded && styles.cobrand)}
      style={{ width: collapsed ? COLLAPSED_WIDTH : FULL_WIDTH }}
    >
      <a
        className={styles.header}
        href="#"
        onClick={e => {
          e.preventDefault()
          setShowAccountMenu(!showAccountMenu)
        }}
      >
        <Box onClick={() => setShowAccountMenu(!showAccountMenu)}>
          <CoBrandingLogo
            onLoaded={() => setCobranded(true)}
            fallback={<Avatar email={email} size={collapsed ? '36' : '100'} round />}
            // className={styles.avatar}
          />
        </Box>
        {collapsed ? '' : <div className={styles.email}>{email}</div>}
        <AccountToggle visible={showAccountMenu} setVisibility={setShowAccountMenu} />
      </a>

      <div className={styles.accountMenu} style={{ maxHeight: showAccountMenu ? '22em' : '0' }}>
        <hr />

        <div className={`my-lg ${cobranded && 'cobrand'}`}>
          <MenuList collapsed={collapsed} items={accountMenuItems} hash={hash} />
        </div>
      </div>
      <div className={styles.menu}>
        <MenuList collapsed={collapsed} items={mainMenuItems} hash={hash} />
        {partnerPortalAccess && (
          <MenuList
            collapsed={collapsed}
            items={[
              {
                label: 'Partner Portal',
                href: '/partners',
                icon: 'chart-line',
              },
            ]}
            hash={hash}
          />
        )}
      </div>

      <BottomLogo collapsed={collapsed} />
    </nav>
  )
}

interface MenuItem {
  action?: () => void
  href?: string
  icon: string
  iconProps?: IconProps
  label: React.ReactNode
  external?: boolean
}

interface MenuListProps {
  collapsed: boolean
  hash: string
  items: MenuItem[]
}

function MenuList({ collapsed, hash, items }: MenuListProps): JSX.Element {
  return (
    <>
      {items.map((item, key) => (
        <div key={key} className={styles.menuItem}>
          <a
            href={item.href}
            onClick={() => item.action && item.action()}
            className={classNames(collapsed ? styles.collapsed : '', isActive(hash, item.href) ? styles.active : '')}
            target={item.external ? '_blank' : undefined}
          >
            <Icon fixedWidth className={styles.icon} name={item.icon} {...item.iconProps} />
            {!collapsed && (
              <span className={styles.label}>
                {item.label}
                {item.external && <Icon name="arrow-right" className={styles.icon} />}
              </span>
            )}
          </a>
        </div>
      ))}
    </>
  )
}

interface AccountToggleProps {
  visible: boolean
  setVisibility: (visible: boolean) => void
}

function AccountToggle({ visible = false, setVisibility }: AccountToggleProps): JSX.Element {
  return (
    <div id="account-toggle" className={styles.accountToggle}>
      <Tooltip title="Account Menu">
        <IconButton
          size="small"
          className={styles.chevron}
          onClick={e => {
            e.preventDefault()
            setVisibility(!visible)
          }}
        >
          <Icon name={visible ? 'chevron-up' : 'chevron-down'} weight="light" />
        </IconButton>
      </Tooltip>
    </div>
  )
}

function isActive(hash: string, href?: string): boolean {
  if (!href) return false
  if (href === '#devices') {
    return hash === '#devices' || hash === '#' || !hash
  }
  return hash === href
}

interface BottomLogoProps {
  collapsed: boolean
}

function BottomLogo({ collapsed }: BottomLogoProps): JSX.Element {
  return (
    <div className={styles.logo} style={{ width: collapsed ? COLLAPSED_WIDTH : FULL_WIDTH }}>
      <a href="#" style={{ width: collapsed ? '75%' : '60%' }}>
        <img src={collapsed ? logoMark : fullLogo} alt="remote.it" />
      </a>
    </div>
  )
}

const mapState = (state: ApplicationState) => ({
  collapsed: state.nav.collapsed,
  partnerPortalAccess: state.auth.partnerPortalAccess,
  updatedReleaseNotesAvailable: state.releaseNotes.updatedReleaseNotesAvailable,
  user: state.auth.user,
})

const mapDispatch = (dispatch: any) => ({
  signOut: dispatch.auth.signOut,
  viewReleaseNotes: dispatch.releaseNotes.viewReleaseNotes,
  checkForUpdates: dispatch.releaseNotes.checkForUpdates,
})

export const SidebarMenu = connect(mapState, mapDispatch)(Sidebar)
