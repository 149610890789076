import classnames from 'classnames'
import React from 'react'

type RotateOption = 90 | 180 | 270
type FlipOption = 'horizontal' | 'vertical' | 'both'

export interface IconProps extends React.HTMLProps<HTMLSpanElement> {
  className?: string
  color?: BrandColor
  fixedWidth?: boolean
  flip?: FlipOption
  name: string
  rotate?: RotateOption
  scale?: FontSize
  spin?: boolean
  weight?: IconWeight
}

export function Icon({
  className,
  color,
  fixedWidth = false,
  flip,
  name,
  scale = 'md',
  spin,
  weight = 'light',
  rotate,
  ...props
}: IconProps): JSX.Element {
  const classes = classnames(
    `fa${weight[0]}`,
    `fa-${name}`,
    color && color,
    {
      'fa-spin': spin,
      'fa-fw': fixedWidth,
      [`txt-${scale}`]: scale,
      [`fa-flip-${flip}`]: flip,
      [`fa-rotate-${rotate}`]: rotate,
    },
    className
  )
  return <span className={classes} {...props} />
}
