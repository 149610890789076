import ReconnectingWebSocket from 'reconnecting-websocket'
import { getWebSocketURL } from '../helpers/apiHelper'
import { store } from '../store'
import { getToken } from './remote.it'

class CloudController {
  socket?: ReconnectingWebSocket
  token?: string

  init() {
    console.log('\n-------------------------> SOCKET INIT\n\n')
    this.connect()
  }

  connect() {
    console.log('\n-------------------------> SOCKET CONNECT\n\n')
    if (this.socket instanceof ReconnectingWebSocket) return
    this.socket = new ReconnectingWebSocket(getWebSocketURL())
    this.socket.addEventListener('open', this.onOpen)
    this.socket.addEventListener('message', this.onMessage)
  }

  onMessage = response => {
    console.log('\n-------------------------> SOCKET MESSAGE\n\n', response.data)
    let event: any = this.parse(response)
    console.log('EVENT', event)
    if (!event) return
    event = this.update(event)
  }

  close() {
    this.socket?.close()
  }

  onOpen = event => {
    console.log('\n-------------------------> SOCKET OPEN\n\n')
    console.log('CLOUD WS opened', event)
    this.authenticate()
  }

  parse(response) {
    try {
      const json: any = JSON.parse(response.data)
      const event = json.data.event
      return {
        type: event.type,
        quantity: event.quantity,
        expiration: event.expiration && new Date(event.expiration),
        plan: event.plan,
      }
    } catch (error) {
      console.warn('Event parsing error', response, error)
      return {}
    }
  }

  update(event: ICloudEvent) {
    const { licensing } = store.dispatch

    switch (event.type) {
      case 'LICENSE_UPDATED':
        console.log('LICENSE UPDATED EVENT', event)
        licensing.updated(true)
        break
    }
    return event
  }

  authenticate = async () => {
    const message = JSON.stringify({
      action: 'subscribe',
      headers: { authorization: await getToken() },
      query: `
      {
        event {
          type
          state
          timestamp
          target {
            id
            name
            application
            platform
            owner {
              id
              email
            }
            device {
              id
              name
            }
          }
          actor {
            id
            email
          }
          ... on DeviceConnectEvent {
            platform
            session
            proxy
            sourceGeo {
              city
              stateName
              countryName
            }
  
          }
          ... on DeviceShareEvent {
            scripting
          }
          ... on LicenseUpdatedEvent {
            plan {
              name
              product {
                name
              }
            }
            quantity
            expiration
          }
        }
      }`,
    })
    this.socket?.send(message)
  }
}

const cloudController = new CloudController()
export default cloudController
