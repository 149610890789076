export default {
  colors: {
    primary: '#0096e7',
    primaryLight: '#73cdff',
    primaryLightest: '#ccedff',
    secondary: '#75BD00',
    danger: '#d6290a',

    // Gray
    grayDarkest: '#1c1c1c',
    grayDarker: '#383838',
    grayDark: '#595959',
    gray: '#777',
    grayLight: '#c1c1c1',
    grayLighter: '#e0e0e0',
    grayLightest: '#f5f5f5',
  },
  spacing: {
    xs: '6px',
    sm: '12px',
    md: '18px',
    lg: '28px',
    xl: '42px',
    xxl: '60px',
  },
  radius: {
    xs: '0.15em',
    sm: '0.3em',
    md: '0.6em',
    lg: '1em',
    xl: '1.8em',
    pill: '100em',
  },
  fonts: {
    base: '16px',
    xxs: '10px',
    xs: '11px',
    sm: '12px',
    md: '14px',
    lg: '18px',
    xl: '22px',
    xxl: '32px',
  },
}
