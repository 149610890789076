import md5 from 'md5'
import React from 'react'
import fallbackImage from '../../images/user.png'

export interface Props {
  className?: string
  email?: string
  round?: boolean
  size?: string
}

export function Avatar({
  className,
  email,
  round = false,
  size = '96',
  ...props
}: Props) {
  // Get image from Gravatar
  const hash = email ? md5(email) : ''
  const url = `https://www.gravatar.com/avatar/${hash}?s=${size}&d=${window
    .location.origin + fallbackImage}`

  if (round) className += ' rad-100'

  return (
    <img
      {...props}
      className={className}
      height={size}
      width={size}
      src={url}
    />
  )
}
