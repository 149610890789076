import React from 'react'
import { MenuItem, Select } from '@material-ui/core'
import { Button } from './Button'
import { useTranslation } from 'react-i18next'

type Props = {
  verificationMethod: string
  changeVerificationMethod: (e: any) => void
  nextVerificationMethod: () => void
  setShowEnableSelection: (e: any) => void
  setShowMFASelection: (e: any) => void
}

export const MFASelectMethod: React.FC<Props> = ({
  verificationMethod,
  changeVerificationMethod,
  nextVerificationMethod,
  setShowEnableSelection,
  setShowMFASelection,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <p>
        <b>{'Configure your Two-Factor Authentication by choosing a verification method: '}</b>
      </p>
      <div className="mt-lg">
        <Select value={verificationMethod} onChange={e => changeVerificationMethod(e.target.value)}>
          <MenuItem value="sms">{'SMS Number'}</MenuItem>
          <MenuItem value="app">{'Authenticator app'}</MenuItem>
        </Select>
      </div>
      <div className="mt-lg">
        <Button onClick={nextVerificationMethod}>{t('global.actions.next')}</Button>
        <Button
          onClick={() => {
            setShowEnableSelection(true)
            setShowMFASelection(false)
          }}
          link
        >
          {t('global.actions.cancel')}
        </Button>
      </div>
    </div>
  )
}
