import React from 'react'
import { SupportedPlatform } from '../platforms'
import s from './OSSelect.module.css'

export interface OSSelectProps {
  platforms: SupportedPlatform[]
  selected?: OperatingSystem
  onSelect: (string: OperatingSystem) => any
}

export function OSSelect({
  platforms,
  selected,
  onSelect,
}: OSSelectProps): JSX.Element {
  return (
    <ul className={s.css}>
      {platforms.map((platform, key) => (
        <li
          key={key}
          className={selected === platform.id ? s.selected : ''}
          onClick={e => onSelect(platform.id)}
        >
          <figure>{platform.icon}</figure>
          {platform.title}
        </li>
      ))}
    </ul>
  )
}
