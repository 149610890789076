import { createModel } from '@rematch/core'

interface SharesState {
  deviceID: string
  deviceName: string
  dialogVisible: boolean
  selectedScripting: boolean
}

const state: SharesState = {
  deviceID: '',
  deviceName: '',
  dialogVisible: false,
  selectedScripting: false,
}

export default createModel({
  state,
  reducers: {
    openDialog(state: SharesState, { id, name }: { id: string; name: string }) {
      state.dialogVisible = true
      state.deviceID = id
      state.deviceName = name
    },
    closeDialog(state: SharesState) {
      state.dialogVisible = false
      state.deviceID = ''
      state.deviceName = ''
    },
    updateScripting(state: SharesState, enabled: boolean) {
      state.selectedScripting = enabled
    },
  },
})
