import classnames from 'classnames'
import React from 'react'
import { DebianLogo } from './DebianLogo'

export type BrandIcons =
  | 'raspberry-pi'
  | 'linux'
  | 'windows'
  | 'apple'
  | 'debian'

export interface Props extends React.HTMLProps<HTMLSpanElement> {
  className?: BrandIcons
  color?: BrandColor
  name: BrandIcons
}

const lookup = {
  'raspberry-pi': '&#63419;',
  linux: '&#61820;',
  windows: '&#61818;',
  apple: '&#61817;',
}

export function BrandIcon({ className, color, name, ...props }: Props) {
  const classes = classnames('fab', `fab-${name}`, color && color, className)

  if (name == 'debian') return <DebianLogo />
  return (
    <span
      className={classes}
      {...props}
      dangerouslySetInnerHTML={{ __html: lookup[name] }}
    />
  )
}
