import axios from 'axios'
import { getToken } from '../services/remote.it'

const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL

export const axiosGraphQL = async () => {
  return axios.create({
    baseURL: GRAPHQL_API_URL,
    headers: {
      Authorization: await getToken(),
    },
  })
}

export const GET_USER_METADATA = `query {
  login {
    notificationSettings {
      emailNotifications
      urlNotifications
      notificationUrl
    }
  }
}`

export const SUBSCRIBE = ` mutation query($priceId: String!, $quantity: Int, $successUrl: String!, $cancelUrl: String!) {
  createSubscription(priceId: $priceId, quantity: $quantity, successUrl: $successUrl, cancelUrl: $cancelUrl) {
    url
  }
}`

export const UNSUBSCRIBE = ` mutation {
  cancelSubscription
}`

export const CREDITCARD = ` mutation query($successUrl: String!, $cancelUrl: String!) {
  updateCreditCard(successUrl: $successUrl, cancelUrl: $cancelUrl) {
    url
  }
}`

export const UPDATE_SUBSCRIPTION = ` mutation query($priceId: String!, $quantity: Int) {
  updateSubscription(priceId: $priceId, quantity: $quantity)
}`

export const GET_USER_DATA_PLAN = `
{
  login {
    licenses {
      id
      created
      updated
      expiration
      valid
      plan {
        id
        name
        description
        duration
        product {
          id
          name
          description
        }
      }
    }
    limits {
      name
      value
      actual
      license {
        id
      }
    }
  }
}
`
export const UPDATE_USER_METADATA = `
  mutation UpdateUserMetadata($onlineDeviceNotification: Boolean, $onlineSharedDeviceNotification: Boolean, $portalUrl: String, $notificationEmail: Boolean, $notificationUrl: String) {
    setAttributes(
      attributes: {
        onlineDeviceNotification: $onlineDeviceNotification
        onlineSharedDeviceNotification: $onlineSharedDeviceNotification
        portalUrl: $portalUrl,
        notificationEmail: $notificationEmail
        notificationUrl: $notificationUrl
      }
    )
  }`

export const UPDATE_NOTIFICATION = `
  mutation UpdateUserMetadata(
      $emailNotifications: Boolean
      $urlNotifications: Boolean
      $notificationUrl: String
  ) {
    setNotificationSettings(
      emailNotifications: $emailNotifications, 
      urlNotifications: $urlNotifications, 
      notificationUrl: $notificationUrl
    )
  }
`
