import { IconButton, Tooltip } from '@material-ui/core'
import { IconButtonProps } from '@material-ui/core/IconButton'
import React from 'react'
import { Icon } from '../Icon'

export interface RefreshButtonProps {
  onClick: () => void
  loading?: boolean
  size?: 'small' | 'medium'
  title?: string
}

export function RefreshButton({
  onClick,
  loading,
  size = 'medium',
  title = 'Refresh',
  ...props
}: RefreshButtonProps & IconButtonProps): JSX.Element {
  return (
    <Tooltip title={title}>
      <IconButton {...props} onClick={onClick}>
        <Icon
          name="sync"
          spin={loading}
          scale={size === 'medium' ? 'lg' : 'md'}
        />
      </IconButton>
    </Tooltip>
  )
}
