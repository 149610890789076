import cookies from 'js-cookie'
import { EMAIL_COOKIE } from '../models/auth'

export default function renderDeleteIcon(
  id: string,
  nameAction: string,
  owner: string
): string {
  const username = cookies.get(EMAIL_COOKIE)

  if (!username) return ''

  const isOwner = username.toLowerCase() === owner

  const title = isOwner ? 'Delete this device' : 'Leave Device'

  const action = isOwner
    ? `showRemoveSharingDialog(\"${id}\")`
    : `showRejectSharingModal(\"${id}\");`

  return `<a href='#' onclick='${action}'><span alt='Delete' class='fal fa-trash-alt fa-1x' title='${title}'></span></a>`
}
