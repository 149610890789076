import React, { useState } from 'react'
import { ApplicationState, Dispatch } from '../../store'
import { Alert } from '../Alert'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button } from '../Button'
import { TextBlock } from '../TextBlock'
import { Section } from '../Section'
import './style.css'
import { MFAMethod } from '../MFAMethod'
import { MFASelectMethod } from '../MFASelectMethod'
import { MFAConfigureApp } from '../MFAConfigureApp'
import { MFAConfigureSms } from '../MFAConfigureSms'

export type MFASectionProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  AWSUser: state.auth.AWSUser,
  mfaMethod: state.auth.mfaMethod,
})

const mapDispatch = (dispatch: any) => ({
  verifyPhone: dispatch.auth.verifyPhone,
  updatePhone: dispatch.auth.updatePhone,
  setMFAPreference: dispatch.auth.setMFAPreference,
  checkSignIn: dispatch.auth.checkSignIn,
  getTotpCode: dispatch.auth.getTotpCode,
  verifyTotpCode: dispatch.auth.verifyTotpCode,
})
export const MFAPreference = connect(
  mapState,
  mapDispatch
)(
  ({
    AWSUser,
    mfaMethod,
    verifyPhone,
    updatePhone,
    setMFAPreference,
    getTotpCode,
    verifyTotpCode,
    checkSignIn,
  }: MFASectionProps) => {
    const { t } = useTranslation()
    const [showEnableSelection, setShowEnableSelection] = useState<boolean>(mfaMethod === 'NO_MFA')
    const [showAuthenticatorConfig, setShowAuthenticatorConfig] = useState<boolean>(false)
    const [totpCode, setTotpCode] = React.useState<string | null>(null)
    const [totpVerified] = React.useState<boolean>(false)

    const [totpVerificationCode, setTotpVerificationCode] = React.useState<string>('')

    const [cancelShowVerificationCode, setCancelShowVerificationCode] = useState<boolean>(false)
    const [error, setError] = React.useState<string | null>(null)
    const [backupCode, setBackupCode] = React.useState<string>(AWSUser['custom:backup_code'] || '')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [hasOldSentVerification, setHasOldSentVerification] = React.useState<boolean>(
      AWSUser && !AWSUser.phone_number_verified
    )
    const [verificationMethod, setVerificationMethod] = React.useState<string>('sms')
    const { mfa } = useDispatch<Dispatch>()
    const { verificationCode, showMFASelection, showSMSConfig } = useSelector((state: ApplicationState) => ({
      verificationCode : state.mfa.verificationCode,
      showMFASelection: state.mfa.showMFASelection,
      showSMSConfig: state.mfa.showSMSConfig
    }))

    const loadTotpCode = async () => {
      setTotpCode(await getTotpCode())
    }

    const setVerificationCode = ( param: string) => {
      mfa.set({ verificationCode: param })
    }

    const setShowPhone = ( param: boolean ) => {
      mfa.set({ showPhone: param})
    }

    const setShowMFASelection = ( param: boolean ) => {
      mfa.set({ showMFASelection: param })
    }

    const setShowVerificationCode = ( param: boolean ) => {
      mfa.set({ showVerificationCode: param })
    }

    const setShowSMSConfig = ( param: boolean ) => {
      mfa.set( { showSMSConfig: param } )
    }

    const sendVerifyTotp = event => {
      event.preventDefault()
      setError(null)
      setLoading(true)
      verifyTotpCode(totpVerificationCode)
        .then(async (backupCode: string | boolean) => {
          if (typeof backupCode == 'string') {
            setBackupCode(backupCode)
            setShowAuthenticatorConfig(false)
          } else {
            setError('Invalid Totp Code')
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }

    const cancelTotp = event => {
      setError(null)
      setShowEnableSelection(true)
      setShowAuthenticatorConfig(false)
    }

    const successfulPhoneUpdate = async (orginalNumber, newNumber) => {
      setShowPhone(false)
      setVerificationCode('')
      if (AWSUser && AWSUser.phone_number_verified && orginalNumber === newNumber && mfaMethod !== 'SMS_MFA') {
        //no update to verified phone number, so just enable MFA
        const backupCode = await setMFAPreference('SMS_MFA')
        setBackupCode(backupCode)
        setShowSMSConfig(false)
      } else if (AWSUser && orginalNumber === newNumber && !AWSUser.phone_number_verified) {
        //not updating the phone but it needs to verify
        setHasOldSentVerification(true)
        setShowVerificationCode(true)
      } else {
        //new phone number and needs to verify
        setHasOldSentVerification(false)
        setShowVerificationCode(true)
      }
    }

    const sendVerifyPhone = event => {
      event.preventDefault()
      setError(null)
      setLoading(true)
      verifyPhone(verificationCode)
        .then(async (backupCode: string) => {
          setVerificationCode('')
          setCancelShowVerificationCode(false)
          setHasOldSentVerification(false)
          setShowPhone(false)
          setShowVerificationCode(false)
          setBackupCode(backupCode)
        })
        .catch(error => {
          console.error(error)
          setError(t(`pages.auth-mfa.errors.${error.code}`))
        })
        .finally(() => {
          setLoading(false)
        })
    }

    const resendCode = event => {
      event.preventDefault()
      setError(null)
      setLoading(true)
      updatePhone(AWSUser.phone_number)
        .then(() => {
          setHasOldSentVerification(false)
          setVerificationCode('')
          setShowVerificationCode(true)
          setShowPhone(false)
          setCancelShowVerificationCode(true)
        })
        .catch(error => {
          console.error(error)
          setError(t(`pages.auth-mfa.errors.${error.code}`))
        })
        .finally(() => {
          setLoading(false)
        })
    }

    const cancelEditPhone = () => {
      if (cancelShowVerificationCode) {
        setCancelShowVerificationCode(false)
        setShowPhone(false)
        setShowVerificationCode(true)
      } else {
        setShowPhone(false)
        setShowVerificationCode(false)
        setShowEnableSelection(true)
      }
    }

    const changeVerificationMethod = (type: any) => {
      setVerificationMethod(type)
    }

    const nextVerificationMethod = () => {
      if (verificationMethod === 'sms') {
        setShowMFASelection(false)
        setShowSMSConfig(true)
        setShowPhone(true)
      } else {
        loadTotpCode()
        setShowMFASelection(false)
        setShowAuthenticatorConfig(true)
      }
    }

    const turnOff = () => {
      return (
        <>
          <form
            onSubmit={e => {
              e.preventDefault()
              // console.log('Disable Software Token MFA')
              setShowEnableSelection(true)
              setMFAPreference('NO_MFA')
              setError(null)
            }}
          >
            <div className="mt-lg">
              <Button disabled={loading} type="submit">
                {t('pages.auth-mfa.disable-button')}
              </Button>
            </div>
          </form>
        </>
      )
    }

    if (AWSUser && AWSUser.authProvider === 'Google') {
      return (
        <Section title={'Two-factor Authentication'} icon="lock">
          <TextBlock>{t('pages.auth-mfa.is-google-user')}</TextBlock>
        </Section>
      )
    } else if (AWSUser) {
      // let totp_code = await getTotpCode()
      return (
        <Section title={'Two-factor Authentication'} icon="lock">
          <p>{t('pages.auth-mfa.short-description')}</p>

          <MFAMethod
            method={mfaMethod}
            phoneNumber={AWSUser.phone_number?.toString()}
            backupCode={backupCode}
            verified={AWSUser.phone_number_verified}
            turnOff={turnOff}
          />

          {/* Show Enable Two-Factor*/}
          {mfaMethod === 'NO_MFA' && showEnableSelection && (
            <form
              onSubmit={e => {
                e.preventDefault()
                // startEnable()
                setShowEnableSelection(false)
                setShowMFASelection(true)
              }}
            >
              <div className="mt-lg">
                <TextBlock>
                  {t('pages.auth-mfa.toggleMFA-message')} <b>OFF</b>
                </TextBlock>
                <Button disabled={loading} type="submit">
                  {t('pages.auth-mfa.enable-button')}
                </Button>
              </div>
            </form>
          )}

          {/* Select Two-Factor Method */}
          {mfaMethod === 'NO_MFA' && showMFASelection && (
            <MFASelectMethod
              verificationMethod={verificationMethod}
              changeVerificationMethod={changeVerificationMethod}
              nextVerificationMethod={nextVerificationMethod}
              setShowEnableSelection={setShowEnableSelection}
              setShowMFASelection={setShowMFASelection}
            />
          )}
          {/* CONFIGURE Authenticator App */}
          {mfaMethod === 'NO_MFA' && showAuthenticatorConfig && (
            <MFAConfigureApp
              email={AWSUser.email}
              totpCode={totpCode || ''}
              loadTotpCode={loadTotpCode}
              totpVerified={totpVerified}
              sendVerifyTotp={sendVerifyTotp}
              setTotpVerificationCode={setTotpVerificationCode}
              totpVerificationCode={totpVerificationCode}
              loading={loading}
              cancel={cancelTotp}
            />
          )}

          {/* CONFIGURE SMS */}
          {mfaMethod === 'NO_MFA' && showSMSConfig && (
            <MFAConfigureSms
              cancelEditPhone={cancelEditPhone}
              setShowEnableSelection={setShowEnableSelection}
              successfulPhoneUpdate={successfulPhoneUpdate}
              sendVerifyPhone={sendVerifyPhone}
              hasOldSentVerification={hasOldSentVerification}
              verificationCode={verificationCode}
              loading={loading}
              resendCode={resendCode}
              setCancelShowVerificationCode={setCancelShowVerificationCode}
            />
          )}

          {/* Display Error */}
          {error && (
            <Alert className="my-md" type="danger" icon="danger" stayOpen={true}>
              {error}
            </Alert>
          )}
        </Section>
      )
    } else {
      checkSignIn()
      return <></>
    }
  }
)
