import React from 'react'
import { Icon } from '../Icon'

export interface ButtonIconProps {
  className?: string
  icon: string
  spin?: boolean
}

export function ButtonIcon({
  icon,
  className = '',
  spin = false,
  ...props
}: ButtonIconProps) {
  return (
    <Icon name={icon} className={`mr-sm ${className}`} spin={spin} {...props} />
  )
}
