import debug from 'debug'
import renderQuickConnectButtons from '../renderQuickConnectButtons'

const d = debug('r3:legacy:DeviceListRenderer')

export default class DeviceListRenderer {
  public searchOnly = false
  public services: RawService[] = []
  public metadata: RawMetaData = {}
  public query = ''
  public error: Error | undefined = undefined

  public render(): void {
    this.searchOnly
      ? this.handleDeviceSearchRender()
      : this.handleDeviceListRender()
  }

  public startLoadSpinner(refresh): void {
    const msg = document.getElementById('loading_spinner')
    if (msg && refresh) {
      msg.style.display = 'block'
    }
  }

  public stopLoadSpinner(): void {
    const spinner = document.getElementById('loading_spinner')
    if (spinner) {
      setTimeout(function() {
        spinner.style.display = 'none'
      }, 1000)
    }
  }

  public clearDeviceList(): void {
    d('clearing device list')
    window.DEVICE_LIST.jqGrid('clearGridData')
  }

  private handleDeviceListRender(): void {
    d('device list rendering')
    this.hideAllSearchMessages()
    this.renderDeviceList()
  }

  private handleDeviceSearchRender(): void {
    d('device search rendering')
    this.hideAllSearchMessages()
    this.clearDeviceList()

    if (this.error) {
      d('search error:', this.error.message)
      this.showGenericSearchError(this.error.message)
      return
    }

    if (!this.query) {
      d('search only mode on an no query exists, show initial message')
      this.setInitialMessageVisibility(true)
      return
    }

    if (!this.services || !this.services.length) {
      d('search only mode on an no results found, show no devices message')
      this.showNoSearchResultsMessage()
      return
    }

    this.renderDeviceList()
  }

  /**
   * Render the device list on the page. Take the list of services and
   * combine them with the metadata to construct devices by bulk service.
   *
   * @param services List of services to render
   * @param metadata The metadata for services used for grouping into devices and showing other details
   */
  private renderDeviceList(): void {
    d('rendering device list')

    // Clear out the previous state of the device list data.
    window.rState.resetServiceList()
    window.rState.resetDeviceList()

    const deviceData: any[] = [] // TODO: add types

    this.services.forEach((serv, i) => {
      const id = serv.deviceaddress.toLowerCase()
      const isBulkService = serv.devicetype.substring(0, 5) === '00:23'

      // ------------------------------------------------------------------
      // HANDLE BULK SERVICE
      // ------------------------------------------------------------------

      if (isBulkService) {
        const delim = serv.lastcontacted.indexOf('T')
        const lastDate = serv.lastcontacted.substring(0, delim)
        const lastTime = serv.lastcontacted.substring(delim + 1)

        let createDate = ''
        let createTime = ''
        if (serv.createdate && serv.createdate.length > 0) {
          createDate = serv.createdate.substring(0, delim)
          createTime = serv.createdate.substring(delim + 1)
        }

        const version =
          parseInt(serv.devicetype.substring(12, 17).replace(':', ''), 16) +
          '.' +
          parseInt(serv.devicetype.substring(18, 23).replace(':', ''), 16)

        const meta = this.metadata[id] || {}
        
        const device = new window.r3_device(
          i + 1,
          serv.devicealias,
          serv.deviceaddress,
          serv.ownerusername,
          serv.shared,
          serv.scripting,
          serv.lastinternalip,
          serv.devicetype,
          serv.devicelastip,
          serv.servicetitle,
          serv.devicestate,
          lastDate,
          lastTime,
          createDate,
          createTime,
          meta.categoryA,
          meta.categoryB,
          meta.categoryC,
          meta.categoryD,
          meta.categoryE,
          meta.statusA,
          meta.statusB,
          meta.statusC,
          meta.statusD,
          meta.statusE,
          version,
          meta?.hardwareid || '',
          serv.license
        )

        // Store the device data in the legacy device store for future use.
        window.rState.setDevice(serv.deviceaddress, device)
        
        // Add the item to the data for the device list jQuery grid.
        deviceData.push({
          id: device.id,
          uid: device.Address,
          service: device.service,
          ServiceName: device.ServiceName,
          Mac: device.Address,
          Address: device.Address,
          HWID: device.HWID,
          IP: device.IP,
          ServiceType: device.ServiceType,
          IPext: device.IPext,
          Type: device.Type,
          Status: device.Status,
          Scripting: device.Scripting,
          Owner: device.Owner,
          Shared: device.Shared,
          Version: device.Version,
          group: device.CatA,
          group2: device.CatB,
          group3: device.CatC,
          HW_macid: device.HWID,
          StatusA: meta.statusA ? window.sanitizeAndDecode(meta.statusA) : '',
          StatusB: meta.statusB ? window.sanitizeAndDecode(meta.statusB) : '',
          StatusC: meta.statusC ? window.sanitizeAndDecode(meta.statusC) : '',
          StatusD: meta.statusD ? window.sanitizeAndDecode(meta.statusD) : '',
          StatusE: meta.statusE ? window.sanitizeAndDecode(meta.statusE) : '',
          CategoryA: meta.categoryA ? window.sanitizeAndDecode(meta.categoryA) : '',
          CategoryB: meta.categoryB ? window.sanitizeAndDecode(meta.categoryB) : '',
          CategoryC: meta.categoryC ? window.sanitizeAndDecode(meta.categoryC) : '',
          CategoryD: meta.categoryD ? window.sanitizeAndDecode(meta.categoryD) : '',
          CategoryE: meta.categoryE ? window.sanitizeAndDecode(meta.categoryE) : '',
          note: '',
          Connected: '0',
          settings: '1',
          ConnectIP: '',
          Portid: '',
          LastOnlineDate: device.LastOnlineDate,
          LastOnlineTime: device.LastOnlineTime,
          CreateDate: device.CreateDate,
          CreateTime: device.CreateTime,
          license: device.license,
        })
      }

      // ------------------------------------------------------------------
      // HANDLE NON-BULK SERVICE
      // ------------------------------------------------------------------
      // Filter for IoT and Bulk Enabled Only
      if (serv.devicetype.length > 23 && serv.devicetype.substring(0, 5) != '00:23') {
        const delim = serv.lastcontacted?.indexOf('T')
        const lastDate = serv.lastcontacted?.substring(0, delim)
        const lastTime = serv.lastcontacted?.substring(delim + 1)
        const createDate = serv.createdate?.substring(0, delim)
        const createTime = serv.createdate?.substring(delim + 1)

        const item = new window.r3_service(
          i + 1,
          serv.devicealias,
          serv.deviceaddress,
          serv.lastinternalip,
          serv.devicetype,
          serv.devicelastip,
          serv.servicetitle,
          serv.devicestate,
          lastDate,
          lastTime,
          createDate,
          createTime,
          serv.license
        )

        window.rState.setService(serv.deviceaddress, item)
      }
    })

    //set sorting on device list table
    let deviceTableSortColumn = 'Status'
    let deviceTableSortDirection = 'asc'
    const deviceCookie = window.getCookie('remoteit.device-sort')
    if (deviceCookie) {
      const cookieVal = deviceCookie.split(',')
      deviceTableSortColumn = cookieVal[0]
      deviceTableSortDirection = cookieVal[1]
    }

    if (deviceData.length > 100) window.jQuery('#pager_bulk').show()

    window.DEVICE_LIST.jqGrid('clearGridData')
      .jqGrid('setGridParam', { data: deviceData })
      // Default to sorting by active devices at the top of
      // the table.
      .jqGrid(
        'sortGrid',
        deviceTableSortColumn,
        false,
        deviceTableSortDirection
      )
      .hideCol('Version')
      // .jqGrid('setLabel', 'Shared', '', { 'text-align': 'center' })
      // .jqGrid('setLabel', 'Owner', '', { 'text-align': 'center' })
      // .jqGrid('setLabel', 'cb', '', { 'text-align': 'center' })
      .trigger('reloadGrid', [{ page: 1 }])
      // Update sort setting in cookie when user changes sort.
      .bind('jqGridSortCol', function(e, index, iCol, sortOrder) {
        window.setCookie(
          'remoteit.device-sort',
          index + ',' + sortOrder,
          2629746
        )
      })

    if (window.savedColModel.length > 0) {
      window.DEVICE_LIST.jqGrid('setGridParam', 'colModel', {
        colModel: Base64.decode(window.savedColModel),
      })
    }

    // Render the "Quick Connect" buttons after evertyhing else
    // has rendered.
    renderQuickConnectButtons()

    // Reload the grid to render the device list.
    window.DEVICE_LIST.trigger('reloadGrid')
  }

  private showNoSearchResultsMessage(): void {
    d('showing "no search results" message')
    window.showNoSearchResultsMessage()
  }

  private showGenericSearchError(message: string): void {
    d('showing generic error message:', message)
    window.showGenericSearchError(message)
  }

  private hideAllSearchMessages(): void {
    d('hiding all search messages')
    this.hideSearchOnlyErrors()
    this.setInitialMessageVisibility(false)
  }

  private hideSearchOnlyErrors(): void {
    d('hiding search messages')
    window.hideSearchMessages()
  }

  private setInitialMessageVisibility(show: boolean): void {
    d('setting "initial" message visibility:', show)
    const msg = document.getElementById('devicelistOffMessage')
    if (msg) msg.style.display = show ? 'block' : 'none'
  }
}
