import React from 'react'
import { Icon } from '../Icon'

export function Section({
  title,
  icon,
  children,
  mWidth,
}: {
  title: string | React.ReactNode
  icon: string
  children: React.ReactNode
  mWidth?: string
}) {
  return (
    <section className="df p-xl bb bc-gray-lightest" style={mWidth ? { maxWidth: mWidth } : { maxWidth: '800px' }}>
      <div className="mr-xl">
        <div className="rad-100 bg-gray-lightest">
          <Icon name={icon} color="gray" className="txt-xxl p-lg" />
        </div>
      </div>
      <div className="txt-md w-100">
        <h3 className="mt-none gray-darker mb-md txt-xl fw-light">{title}</h3>
        {children}
      </div>
    </section>
  )
}
