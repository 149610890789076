import { IContact } from 'remote.it'
import { r3 } from './remote.it'

export class Contacts {
  public static async fetch(): Promise<IContact[]> {
    return await r3.contacts.list()
  }

  public static async create({
    companyName,
    email,
    firstName,
    lastName,
    language = 'en',
  }: ContactFields): Promise<IContact> {
    return await r3.contacts.create({
      companyname: companyName,
      email,
      firstname: firstName,
      lastname: lastName,
      language,
    })
  }

  public static async update({
    companyName,
    email,
    firstName,
    id,
    lastName,
    language = 'en',
  }: ContactFields): Promise<IContact> {
    return await r3.contacts.update({
      companyname: companyName,
      email,
      firstname: firstName,
      id,
      lastname: lastName,
      language,
    })
  }

  public static async invite ({
    email,
    id,
  }: {
    email: string
    id: string
  }): Promise<any> {
    return r3.post('/developer/customer/sendinvite', {
      email: email.toLowerCase().trim(),
      origin: window.location.origin,
    })
  }

  public static async destroy(id: string): Promise<void> {
    return r3.contacts.destroy(id)
  }
}
