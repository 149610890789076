import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../../store'
import { LoadingMessage } from '../LoadingMessage'
import { Section } from '../Section'
import { LicensingSetting } from '../LicensingSetting'

export const LicencingSection: React.FC = () => {
  const { licensing } = useSelector((state: ApplicationState) => ({
    licensing: state.licensing,
    user: state.auth.user,
    transactions: state.auth.transactions,
  }))

  if (!licensing.initialized) return <LoadingMessage text="Loading plans..." />

  return (
    <>
      <Section title={<>Licensing</>} icon="shopping-basket">
        <LicensingSetting />
      </Section>
    </>
  )
}
