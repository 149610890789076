import cookie from 'js-cookie'
import { store } from '../store'
import { windowWidth } from './windowWidth'
import { SIDEBAR_COLLAPSE_COOKIE_NAME } from '../models/nav'

/**
 * Setup the initial sidebar state based on the page width
 * and then watch for resize events to change size.
 */
export function determineSidebarCollapseState() {
  const breakpoint = 800

  // @ts-ignore
  const collapse = () => store.dispatch.nav.collapse()
  // @ts-ignore
  const expand = () => store.dispatch.nav.expand()

  // Check initial size and collapse if needed
  if (windowWidth() <= breakpoint) collapse()

  // Check to see if user previously set the sidebar to
  // be collapsed, and if they did, collapse it for them.
  const lastState = cookie.get(SIDEBAR_COLLAPSE_COOKIE_NAME)
  if (lastState && lastState === 'true') collapse()

  // Watch for resize event
  let lastWidth = windowWidth()
  window.addEventListener(
    'resize',
    () => {
      const width = windowWidth()

      // Do nothing if the width hasn't changed. This
      // would occur if the height changes not the width.
      if (lastWidth === width) return

      width <= breakpoint
        ? // @ts-ignore
          collapse()
        : // @ts-ignore
          expand()

      // Update the last width for the next resize event
      lastWidth = width
    },
    true
  )
}
