import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { ApolloError } from 'apollo-client'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { GenericLogEvent } from '../../services/apolloClient'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { LoadingScreen } from '../LoadingScreen'
import { EventIcon } from './EventIcon'
import { EventMessage } from './EventMessage'
import { EventPageNavigation } from './EventPageNavigation'

interface EventLogsTableProps {
  error?: ApolloError
  events?: GenericLogEvent[]
  fetch: () => void
  hasNext: boolean
  hasPrev: boolean
  itemOffset: number
  loading?: boolean
  onNext: () => void
  onPrev: () => void
  pageSize: number
  total: number
}

export function EventLogsTable({
  error,
  events = [],
  fetch,
  hasNext,
  hasPrev,
  itemOffset,
  onNext,
  onPrev,
  loading = false,
  pageSize,
  total,
}: EventLogsTableProps): JSX.Element {
  const { t } = useTranslation()

  if (error) {
    return (
      <>
        <div className="m-xl center">
          <div>{t('global.notices.error')}</div>
          <div>{t('global.actions.error', { message: error.message })}</div>
        </div>
        <div className="m-l center">
          <Button onClick={fetch}>
            <Icon name="sync" className="mr-sm" />
            {t('global.actions.refresh')}
          </Button>
        </div>
      </>
    )
  }

  if (loading) {
    return <LoadingScreen text={t('pages.logs.loading-message')} />
  }

  let endCount = 0
  if (total) {
    endCount =
      itemOffset + pageSize - 1 > total ? total : itemOffset + pageSize - 1
  }

  if (!events || !events.length) {
    return <div className="m-xl center">{t('pages.logs.no-logs')}</div>
  }

  return (
    <>
      <Box mt={6}>
        <List disablePadding>
          {events.map(item => (
            <EventCell {...item} key={item.id} />
          ))}
        </List>
      </Box>

      <Box
        mt={4}
        mb={8}
        display="flex"
        alignItems="center"
        color="gray"
        className="gray"
      >
        <EventPageNavigation
          direction="back"
          enabled={hasPrev}
          onClick={onPrev}
        />
        <div className="as-center">
          {itemOffset} - {endCount} of {total}
        </div>
        <EventPageNavigation
          direction="next"
          enabled={hasNext}
          onClick={onNext}
        />
      </Box>
    </>
  )
}

function EventCell(item: GenericLogEvent): JSX.Element {
  const date = DateTime.fromISO(item.timestamp).toLocaleString(
    DateTime.DATETIME_MED
  )

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <EventIcon {...item} />
        </ListItemIcon>
        <ListItemText>
          <div className="df ai-center">
            <div style={{ minWidth: 200 }} className="mr-md txt-sm gray">
              {date}
            </div>
            <EventMessage item={item} />
          </div>
        </ListItemText>
      </ListItem>
    </>
  )
}
