import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { HelpPopover } from '../HelpPopover/HelpPopover'
import { SharingAccess } from './DeviceShareDetails'

export function SharingForm({
  onChange,
  services,
  saving,
  scripting,
  selectedServices,
}: {
  onChange: (access: SharingAccess) => void
  services: SimplifiedService[]
  saving: boolean
  scripting: boolean
  selectedServices: string[]
}): JSX.Element {
  const { t } = useTranslation()

  React.useEffect(() => {
    onChange({ scripting, services: selectedServices })
  }, [])

  function handleChangeServices(services: string[]): void {
    onChange({ scripting, services })
  }

  function handleChangeScripting(e: React.ChangeEvent<HTMLInputElement>): void {
    onChange({
      scripting: e.target.checked,
      services: selectedServices,
    })
  }

  return (
    <List component="div">
      <ListItem>
        <ListItemText>
          <>
            <span className="mr-md">
              {t('pages.device-share.script-execution-label-new')}
            </span>
            <HelpPopover>
              <Typography>
                <strong>
                  {t('pages.device-share.script-execution-help-heading')}
                </strong>
              </Typography>
              <Typography>
                {t('pages.device-share.script-execution-help-body')}
              </Typography>
            </HelpPopover>
            <Switch
              color="primary"
              className="ml-lg"
              onChange={handleChangeScripting}
              checked={scripting}
              disabled={saving}
            />
          </>
        </ListItemText>
      </ListItem>
      <ListItem>
        <ListItemText
          primary={t('pages.device-share.select-services-checkboxes-label')}
        />
      </ListItem>
      <div className="ml-md my-sm pl-md py-xs bw-xxs bl bc-gray-lighter">
        <ServiceCheckboxes
          onChange={handleChangeServices}
          services={services.map(s => ({ label: s.name, value: s.id }))}
          saving={saving}
          selectedServices={selectedServices}
        />
      </div>
    </List>
  )
}

interface CheckboxItem {
  label: string
  value: string
}

function ServiceCheckboxes({
  onChange,
  services = [],
  saving,
  selectedServices = [],
}: {
  onChange: (services: string[]) => void
  services: CheckboxItem[]
  saving: boolean
  selectedServices: string[]
}): JSX.Element {
  // Update the list of services. If it has been added,
  // push it into the list, otherwise remove it from
  // the list of selected services
  function update(checked: boolean, id: string): void {
    const all = checked
      ? [...selectedServices, id]
      : selectedServices.filter(v => v != id)
    onChange(all)
  }

  return (
    <>
      {services.map((service, key) => (
        <div key={key} className="pl-md">
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedServices.includes(service.value)}
                disabled={saving}
                onChange={e => update(e.currentTarget.checked, service.value)}
                value={service.value}
                color="primary"
              />
            }
            label={service.label}
          />
        </div>
      ))}
    </>
  )
}
