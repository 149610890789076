import React from 'react'
import { Icon } from '../Icon'

// import styles from './ServiceConnectionModal.module.css'
// export interface ServiceConnectionModalProps {}
export function ServiceConnectionModal(): JSX.Element {
  const handleFocus = event => event.target.select()
  return (
    <div
      className="modal inmodal"
      data-backdrop="static"
      id="VNC-Connection-Modal"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={() => window.closeConnectionModals()}
              data-dismiss="modal"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            <h4 className="modal-title">VNC Connection</h4>
            Your VNC connection to{' '}
            <strong>
              <span id="vnc-connection-name"></span>
            </strong>{' '}
            is ready to use. Use the following options in your VNC client
            application.
          </div>
          <div className="modal-body">
            <div className="margin-bottom-sm">
              <p>Copy and paste the values below to your client application:</p>

              <div className="row">
                <div className="col-sm-6">
                  <label>Host</label>
                  <input
                    type="text"
                    id="vnc_host"
                    className="proxy-host px-md py-sm w-100 db"
                    onClick={handleFocus}
                  />
                </div>
                <div className="col-sm-6">
                  <label>Port</label>
                  <input
                    type="text"
                    id="vnc_port"
                    className="proxy-port px-md py-sm w-100 db"
                    onClick={handleFocus}
                  />
                </div>
              </div>
              <div>
                <p className="mt-lg">
                  Or use a single connection string in your client application:
                </p>
                <input
                  id="vnc_url"
                  type="text"
                  className="w-100 px-md py-sm"
                  onClick={handleFocus}
                />
                <div className="mt-sm">
                  <input
                    id="vnc_url_no_protocol"
                    type="text"
                    className="w-100 px-md py-sm"
                    onClick={handleFocus}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-white"
              onClick={() => window.showServicesModal()}
              data-dismiss="modal"
            >
              <Icon name="chevron-left" className="mr-sm" /> Device Services
            </button>
            <button
              type="button"
              className="btn btn-white"
              onClick={() => window.closeConnectionModals()}
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
