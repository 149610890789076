import React from 'react'
import { ApplicationState } from '../../store'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { GenericLogEvent } from '../../services/apolloClient'

type Props = {
  item: GenericLogEvent
}
export type EventMessageProps = Props & ReturnType<typeof mapState>

const mapState = (state: ApplicationState) => ({
  loggedinUser: state.auth.user,
})

export const EventMessage = connect(mapState)(({ loggedinUser, ...props }: EventMessageProps) => {
  const { t } = useTranslation()
  const item = props.item
  let message = ''
  const targets = item && item.target

  let deviceName = 'Unknown'
  if (targets && targets.length > 1) {
    const targetNames = targets.map(target => {
      if(target.name) {
        if (target.name != target.device?.name) {
          return target.device?.name + ':' + target.name
        } else {
          return target.name
        }
      } else {
        return t('pages.logs.deleted', { id: target.id })
      }
    })
    deviceName =
      targetNames.slice(0, -1).join(t('global.formatting.sentence.delimiter')) +
      t('global.formatting.sentence.and') +
      targetNames.slice(-1)
  } else if (targets && targets.length == 1 && targets[0].name) {
    if (targets[0].name != targets[0].device?.name) {
      deviceName = targets[0].device?.name + ':' + targets[0].name
    } else {
      deviceName = targets[0].name
    }
  } else if (targets && targets[0].id) {
    deviceName = targets[0].id + ' ' + t('pages.logs.deleted')
  }
  if (item.type === 'DEVICE_STATE') {
    if (item.state === 'active') {
      message = t('pages.logs.messages.DEVICE_STATE.active', {
        device: deviceName,
      })
    } else {
      message = t('pages.logs.messages.DEVICE_STATE.inactive', {
        device: deviceName,
      })
    }
  } else if (item.type === 'DEVICE_CONNECT') {
    const context = item.actor?.id === loggedinUser.id ? 'self' : null

    if (item.state === 'connected') {
      message = t('pages.logs.messages.DEVICE_CONNECT.connected', {
        user: item.actor?.email,
        device: deviceName,
        context,
      })
    } else {
      message = t('pages.logs.messages.DEVICE_CONNECT.disconnected', {
        user: item.actor?.email,
        device: deviceName,
        context,
      })
    }
  } else if (item.type === 'DEVICE_SHARE') {
    //actor is the person initiating
    //owner is the device owner
    //users are the people affected by the action
    const owner = item.owner && item.owner
    const actor = item.actor && item.actor
    const users =
      item.users && item.users.map(user => (user.email ? user.email : t('pages.logs.deleted', { id: user.id })))
    const userList =
      users && users.length !== 1
        ? users.slice(0, -1).join(t('global.formatting.sentence.delimiter')) +
          t('global.formatting.sentence.and') +
          users.slice(-1)
        : users && users[0]

    const ownerDisplay = owner && owner.email ? owner.email : t('pages.logs.deleted', { id: owner && owner.id })
    if (item.action !== 'remove') {
      //Adding Sharing
      //owner messages
      if ((owner && owner.id) === loggedinUser.id && targets && targets.length > 1 && item.scripting) {
        //this is the owner viewing and has shared multiple devices and allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.owner-messages.by-owner-scripting_plural', {
          devices: deviceName,
          users: userList,
        })
      } else if ((owner && owner.id) === loggedinUser.id && item.scripting) {
        //this is the owner viewing and has shared a single device and allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.owner-messages.by-owner-scripting', {
          devices: deviceName,
          users: userList,
        })
      } else if ((owner && owner.id) === loggedinUser.id && targets && targets.length > 1 && item.scripting === false) {
        //this is the owner viewing and has shared multiple devices and not allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.owner-messages.by-owner-no-scripting_plural', {
          devices: deviceName,
          users: userList,
        })
      } else if ((owner && owner.id) === loggedinUser.id && item.scripting === false) {
        //this is the owner viewing and has shared a single device and not allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.owner-messages.by-owner-no-scripting', {
          devices: deviceName,
          users: userList,
        })
      }
      //recipient messages
      else if (targets && targets.length > 1 && item.scripting) {
        //this is the recipient of a share viewing and has been shared multiple devices and allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.recipient-messages.by-owner-scripting_plural', {
          devices: deviceName,
          owner: ownerDisplay,
        })
      } else if (item.scripting) {
        //this is the recipient of a share viewing and has been shared a single device and allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.recipient-messages.by-owner-scripting', {
          devices: deviceName,
          owner: ownerDisplay,
        })
      } else if (targets && targets.length > 1) {
        //this is the recipient of a share viewing and has been shared multiple devices and not allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.recipient-messages.by-owner-no-scripting_plural', {
          devices: deviceName,
          owner: ownerDisplay,
        })
      } else {
        //this is the recipient of a share viewing and has been shared a single device and not allowed scripting
        message = t('pages.logs.messages.DEVICE_SHARE.add.recipient-messages.by-owner-no-scripting', {
          devices: deviceName,
          owner: ownerDisplay,
        })
      }
    } else {
      //Revoking sharing
      //owner's messages
      if (
        (actor && actor.id) === (owner && owner.id) &&
        (owner && owner.id) === loggedinUser.id &&
        targets &&
        targets.length > 1
      ) {
        //this is the owner viewing and has removed multiple devices
        message = t('pages.logs.messages.DEVICE_SHARE.remove.owner-messages.by-owner', {
          devices: deviceName,
          users: userList,
        })
      } else if ((actor && actor.id) === (owner && owner.id) && (owner && owner.id) === loggedinUser.id) {
        //this is the owner viewing and has removed a single devices
        message = t('pages.logs.messages.DEVICE_SHARE.remove.owner-messages.by-owner_plural', {
          devices: deviceName,
          users: userList,
        })
      } else if ((actor && actor.id) === (owner && owner.id) && (owner && owner.id) === loggedinUser.id) {
        //this is the owner viewing and the sharee has removed themselves from a single device
        message = t('pages.logs.messages.DEVICE_SHARE.remove.owner-messages.by-user', {
          devices: deviceName,
          users: userList,
        })
      }
      //recipient messages
      else if ((actor && actor.id) === (owner && owner.id) && targets && targets.length > 1) {
        //this is the recipient viewing and owner has removed multiple devices
        message = t('pages.logs.messages.DEVICE_SHARE.remove.recipient-messages.by-owner', {
          devices: deviceName,
          owner: ownerDisplay,
        })
      } else if ((actor && actor.id) === (owner && owner.id)) {
        //this is the recipient viewing and owner has removed a single devices
        message = t('pages.logs.messages.DEVICE_SHARE.remove.recipient-messages.by-owner_plural', {
          devices: deviceName,
          owner: ownerDisplay,
        })
      } else {
        //this is the recipient viewing and they have removed themselves from a single device
        message = t('pages.logs.messages.DEVICE_SHARE.remove.recipient-messages.by-user', {
          devices: deviceName,
        })
      }
    }
  }
  return <div className="event-message">{message}</div>
})
