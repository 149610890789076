import React from 'react'
import { NotificationSubscriptions } from '../NotificationSubscriptions'
import { Section } from '../Section'

export const NotificationSection = () => {
  return (
    <Section title="Notifications" icon="bell">
      <NotificationSubscriptions />
    </Section>
  )
}
