import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../PageHeader'
import { RefreshButton } from '../RefreshButton'
import { Button } from '../Button'
import { ButtonIcon } from '../ButtonIcon'
import { HelpIcon } from '../HelpIcon'
import { ApplicationState } from '../../store'
import { connect } from 'react-redux'
import { SignInMessage } from '../SignInMessage'

export type ProductsPageProps = ReturnType<typeof mapState> &
  ReturnType<typeof mapDispatch>

const mapState = (state: ApplicationState) => ({
  signInStarted: state.auth.signInStarted,
})
const mapDispatch = (dispatch: any) => ({})

export const ProductsPage = connect(
  mapState,
  mapDispatch
)(({ signInStarted }: ProductsPageProps) => {
  if (signInStarted) return <SignInMessage />
  const { t } = useTranslation()
  return (
    <div>
      <PageHeader
        title="Products"
        actions={
          <>
            <HelpIcon
              title="View product documentation"
              href="https://support.remote.it/hc/en-us/sections/360001751831-Create-a-Product-Definition"
            />
            <RefreshButton
              onClick={() => window.showProducts()}
              title={t('global.actions.refresh')}
            />
          </>
        }
      />
      <div className="my-lg">
        <Button onClick={() => window.showCreateProductModal()}>
          <ButtonIcon icon="plus" />
          Create Product
        </Button>
      </div>
      <div id="products-table-content"></div>
    </div>
  )
})
