// import debug from 'debug'
import axios from 'axios'
import { createModel } from '@rematch/core'
import cookie from 'js-cookie'
import { ApplicationState } from '../store'

// const d = debug('r3:models:release-notes')
export const ARTICLE_ID = '360018984331'
export const WHATS_NEW_COOKIE = 'remoteit.release-notes-whats-new'

export interface ReleaseNotesState {
  articleUpdateDate?: Date
  updatedReleaseNotesAvailable: boolean
}

const state: ReleaseNotesState = {
  articleUpdateDate: undefined,
  updatedReleaseNotesAvailable: false,
}

export default createModel({
  state,
  effects: dispatch => ({
    async checkForUpdates() {
      const { setArticleUpdateDate, setUpdateAvailable } = dispatch.releaseNotes
      const url = `${
        process.env.API_URL
      }/zendesk/articles/${ARTICLE_ID}?locale=${getLocale()}`

      // Check for cookie, set date if exists (last viewed)
      // Check API for last update date
      // Compare last update to cookie, if exists and cookie happened LATER than API update, update is available
      // If clicking on whats new, set date to API value or now

      const cookieValue = cookie.get(WHATS_NEW_COOKIE)
      let cookieDate
      if (cookieValue) cookieDate = new Date(cookieValue)

      const updateDate = await axios
        .get(url)
        .then(resp => new Date(resp.data.article.updated_at))

      setArticleUpdateDate(updateDate)

      // We consider an update avaialbe if it either:
      // a) The user has never seen the release notes page, thus their
      //    cookie isn't set
      // b) Their cookie is set but the date in it is in the past relative
      //    to the last update timestamp on the article on ZenDesk.
      if (!cookieDate) setUpdateAvailable()
      if (cookieDate < updateDate) setUpdateAvailable()
    },
    async viewReleaseNotes(_, rootState: ApplicationState) {
      const { setUpdateUnavailable } = dispatch.releaseNotes

      // Indicate no updates are available now.
      setUpdateUnavailable()

      // Remember when they have viewed the release notes.
      const date = rootState.releaseNotes.articleUpdateDate || new Date()
      cookie.set(WHATS_NEW_COOKIE, date.toISOString(), { expires: 3650 })

      // Open the release notes page.
      window.open(
        `https://remot3it.zendesk.com/hc/${getLocale()}/articles/${ARTICLE_ID}`
      )
    },
  }),
  reducers: {
    setArticleUpdateDate(state: ReleaseNotesState, date: Date) {
      state.articleUpdateDate = date
    },
    setUpdateAvailable(state: ReleaseNotesState) {
      state.updatedReleaseNotesAvailable = true
    },
    setUpdateUnavailable(state: ReleaseNotesState) {
      state.updatedReleaseNotesAvailable = false
    },
  },
})

function getLocale() {
  if (window.navigator.language.substr(0, 2) === 'ja') return 'ja'
  return 'en-us'
}
