import React from 'react'
import { Section } from '../Section'
import { TextBlock } from '../TextBlock'
import { ApplicationState } from '../../store'
import { MenuItem, Select } from '@material-ui/core'
import { connect } from 'react-redux'

type ChangeEmailLanguageSectionProps = ReturnType<typeof mapChangeEmailState> &
  ReturnType<typeof mapChangeEmailDispatch>

const mapChangeEmailState = (state: ApplicationState) => ({
  language: state.auth.user ? (state.auth.user.language as AvailableLanguage) : ('en' as AvailableLanguage),
})

const mapChangeEmailDispatch = (dispatch: any) => ({
  changeLanguage: dispatch.auth.changeLanguage,
})

export const ChangeEmailLanguageSection = connect(
  mapChangeEmailState,
  mapChangeEmailDispatch
)(({ changeLanguage, language = 'en' as AvailableLanguage }: ChangeEmailLanguageSectionProps) => {
  return (
    <Section title="Change email language" icon="language">
      <TextBlock>
        Select your preferred language for remote.it emails. This does not change the language for the remote.it web
        application. Changes will take effect immediately for any emails sent after a language is selected.
      </TextBlock>
      <Select value={language} onChange={e => changeLanguage(e.target.value as AvailableLanguage)}>
        <MenuItem value="en">
          English <span className="gray ml-md">英語</span>
        </MenuItem>
        <MenuItem value="ja">
          Japanese <span className="gray ml-md">日本語</span>
        </MenuItem>{' '}
      </Select>
    </Section>
  )
})
