import React from 'react'
import { IContact, IService } from 'remote.it'
import { Contacts } from '../../services/Contacts'
import { SharingManager } from '../../services/SharingManager'
import { SharingDetails } from './DeviceShareDetails'
import { DeviceShareDialog } from './DeviceShareDialog'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '../../store'

export function DeviceShareContainer(): JSX.Element {
  const { shares: actions } = useDispatch<any>() // TODO: fix type defs
  const [fetching, setFetching] = React.useState<boolean>(false)
  const [contacts, setContacts] = React.useState<IContact[]>([])
  const [shares, setShares] = React.useState<ShareInfo[]>([])
  const [services, setServices] = React.useState<IService[]>([])
  const { deviceID, deviceName, dialogVisible } = useSelector(
    (state: ApplicationState) => ({
      deviceID: state.shares.deviceID,
      deviceName: state.shares.deviceName,
      dialogVisible: state.shares.dialogVisible,
      user: state.auth.user,
    })
  )

  function updateShareColumn(count: number): void {
    const cleanID = deviceID.toUpperCase() //.replace(/:/g, '\\3A')
    const query = `#table_bulk [id="${cleanID}"] .share-icon-container`
    const elm = document.querySelector(query)

    if (!elm) return console.log('no element found matching:', query)

    elm.innerHTML = count > 0 ? 'Yes' : 'No'
  }

  function handleClose(): void {
    const element = document.getElementById(deviceID)
    element && element.classList.remove("success");
    actions.closeDialog()
  }

  async function handleFetch(): Promise<void> {
    setFetching(true)

    const contacts = await Contacts.fetch()

    setContacts(contacts)

    const { services, shares } = await SharingManager.fetch(deviceID)

    setShares(shares)
    setServices(services)
    setFetching(false)

    // TODO: handle errors
  }

  async function handleShare(share: SharingDetails): Promise<void> {
    // const authHash = rootState.auth.user.authHash
    const { access, contacts, deviceID } = share
    const scripting = access.scripting
    const allServices = services
    const sharedServices = access.services

    await SharingManager.share({
      allServices,
      // authHash,
      contacts,
      deviceID,
      scripting,
      sharedServices,
    })

    const s: ShareInfo[] = share.contacts.map(
      (email): ShareInfo => ({
        created: Date.now().toString(),
        email,
        scripting: share.access.scripting,
        services: share.access.services,
        uid: String(Math.random() * 1000), // TODO: HACK!
        updated: Date.now().toString(),
        userid: email,
      })
    )

    const updated = [...shares, ...s]
    setShares(updated)

    updateShareColumn(updated.length)

    // await handleFetch()

    // setShares([...shares, resp])
  }

  async function handleUpdate(share: SharingDetails): Promise<void> {
    const { access, contacts } = share
    const email = contacts[0]
    const scripting = access.scripting
    const allServices = services
    const sharedServices = access.services

    await SharingManager.update({
      allServices,
      deviceID,
      email,
      scripting,
      sharedServices,
    })

    const updated = shares.map(s => {
      if (s.email === email) {
        s.scripting = scripting
        s.services = sharedServices
      }
      return s
    })
    setShares(updated)

    updateShareColumn(updated.length)
  }

  async function handleUnshare(email: string): Promise<void> {
    const all = shares

    await SharingManager.unshare({ deviceID, email })

    const updated = all.filter(s => s.email !== email)
    setShares(updated)

    updateShareColumn(updated.length)
  }

  if (!dialogVisible) return <></>

  return (
    <DeviceShareDialog
      closeDialog={handleClose}
      contacts={contacts}
      deviceID={deviceID}
      deviceName={deviceName}
      fetch={handleFetch}
      fetching={fetching}
      services={services}
      share={handleShare}
      shares={shares}
      unshare={handleUnshare}
      update={handleUpdate}
    />
  )
}
