import { Typography } from '@material-ui/core'
import React from 'react'
import { Alert } from '../../Alert'
import { Button } from '../../Button'
import { Icon } from '../../Icon'
import { TextBlock } from '../../TextBlock'

export function WindowsInstructions(): JSX.Element {
  return (
    <>
      <Typography variant="h4">
        Download the latest remote.it Desktop app
      </Typography>
      <TextBlock>
        <Button
          href="https://downloads.remote.it/desktop/latest/remoteit-installer.exe"
          target="_blank"
          download
        >
          <Icon name="download" className="mr-sm" />
          Download Desktop app
        </Button>
      </TextBlock>
      <TextBlock>
        On the initial launch of the application, you will be prompted to
        install our service onto your machine in order to maintain background
        connections. Click <strong>Install Now</strong> to start the
        installation.
      </TextBlock>
      <Alert type="info" variant="outlined" stayOpen>
        You will be prompted for permission to continue the installation. Run
        remote.it Desktop as Administrator to avoid future prompting.
      </Alert>
      <Typography variant="h4">
        Optional: Register your Windows PC with remote.it
      </Typography>
      <TextBlock>
        If you would like to set up remote connections (incoming connections) to
        your Mac, you can do the following:
      </TextBlock>
      <TextBlock>
        <ol>
          <li>
            Navigate to <strong>Settings</strong> tab and click{' '}
            <strong>Set up remote access</strong>
          </li>
          <li>Create a name to identify your Windows PC.</li>
          <li>Choose the services you would like to have remote access to.</li>
          <li>
            Click <strong>Register</strong>
          </li>
        </ol>
      </TextBlock>
      <TextBlock>
        Congratulations! Your Windows PC is now set up for remote.it access!
      </TextBlock>
    </>
  )
}
