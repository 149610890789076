export default {
  '#devices': {
    title: 'Devices',
    action: () => window.showDevices(),
  },
  '#add': {
    title: 'Add Devices',
    action: () => showPage('#tab-add-device'),
  },
  '#account': {
    title: 'Account',
    action: () => {
      window.stopSpinner()
      showPage('#tab-account')
    },
  },
  '#products': {
    title: 'Products',
    action: () => window.showProducts(),
  },
  '#scripting': {
    title: 'Scripting',
    action: () => window.showJobs(),
  },
  '#contacts': {
    title: 'Contacts',
    action: () => showPage('#tab-customers'),
  },
  '#registrations': {
    title: 'Registrations',
    action: () => window.showRegistrations(),
  },
  '#activity-logs': {
    title: 'Logs',
    action: () => showPage('#tab-activity-logs'),
  },
}

function showPage(selector: string) {
  // Hide Splash page
  const elem = document.getElementById('wrapper-splash')
  if (elem) elem.style.display = 'none'

  // Hide all pages
  Array.from(document.querySelectorAll<HTMLDivElement>('.page')).map((e: HTMLDivElement) => {
    e.style.display = 'none'
  })

  // Show the given page
  const elm = document.querySelector<HTMLDivElement>(selector)
  if (elm) elm.style.display = 'block'
}
