import React from 'react'
import { makeStyles, ListItemText, IconButton, InputLabel, Tooltip } from '@material-ui/core'
import { colors, spacing, fontSizes } from '../styling'
import { Icon } from '../components/Icon'
import { useTranslation } from 'react-i18next'

type Props = {
  title: string
  value?: string
  label: string
  icon: string | React.ReactElement
  iconColor?: BrandColor
  iconPosition?: string
  labelWeight?: number
  mfaMethod?: string
  onClick: (event?: any) => void
}

export const DataButton: React.FC<Props> = ({
  title,
  value,
  label,
  icon,
  iconColor,
  iconPosition = 'left',
  labelWeight = 400,
  mfaMethod = '',
  onClick,
}) => {
  const css = useStyles()
  const { t } = useTranslation()
  const theIcon = () => {
    return (
      <>
        {typeof icon === 'string' ? (
          <Icon name={icon} className={css.icon} color={iconColor} scale="md" fixedWidth />
        ) : (
          icon
        )}
      </>
    )
  }

  const messageInfo = () => {
    const text = `${t('pages.auth-mfa.recovery-code-description')} ${t('pages.auth-mfa.recovery-code-warning')}`
    switch (mfaMethod) {
      case 'SMS_MFA':
        return <div className={css.caption}>{text}</div>
        break
      case 'SOFTWARE_TOKEN_MFA':
        return <div className={css.modalMessage}>
          <Icon name="info-circle" className={css.iconModal} fixedWidth />
          <div className={css.message}>{text}</div>
        </div>
        break
      default:
        return <></>
        break
    }
  }
  return (
    <>
      <Tooltip title={title} enterDelay={500} placement="top" arrow>
        <div>
          <InputLabel shrink>
            <span style={{ fontWeight: labelWeight }}>{label}</span>
          </InputLabel>
          {mfaMethod === 'SOFTWARE_TOKEN_MFA' && messageInfo()}
          <IconButton className={css.box} onClick={onClick}>
            {iconPosition === 'left' && theIcon()}
            <ListItemText>
              <div className={css.preBox}>
                <pre className={css.key}>{value}</pre>
                {iconPosition === 'right' && theIcon()}
              </div>
            </ListItemText>
          </IconButton>
        </div>
      </Tooltip>
      {mfaMethod === 'SMS_MFA' && messageInfo()}
    </>
  )
}

const useStyles = makeStyles({
  box: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    padding: spacing.xs,
    paddingLeft: spacing.xxs,
    paddingRight: spacing.xxs,
    width: '100%',
    '& svg': { minWidth: 60 },
    '&:hover, &:focus': {
      backgroundColor: 'white',
    },
  },
  icon: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  key: {
    fontSize: fontSizes.sm,
    color: colors.grayDarker,
    margin: 0,
    whiteSpace: 'pre-wrap',
    width: '80%',
    float: 'left',
  },
  caption: {
    fontWeight: 400,
    fontSize: 11,
    color: '#999',
  },
  modalMessage: {
    backgroundColor: '#e9f6fd',
    padding: 11,
    display: 'flex',
    borderRadius: 4,
    fontSize: 14,
    color: '#3aa1db',
    marginBottom: 10,
    lineHeight: 'normal',
  },
  message: {
    color: '',
  },
  iconModal: {
    marginRight: 12,
    padding: '7px 0',
    display: 'flex',
    fontSize: '22px',
    opacity: '0.9',
  },
  preBox: {
    width: '100%',
    marginLeft: 0
  }
})
