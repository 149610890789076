import React from 'react'
import { Alert } from '../Alert'
import { useTranslation } from 'react-i18next'

export interface Props {}

export function DeviceTransferWarning({ ...props }: Props) {
  const { t } = useTranslation()
  return (
    <Alert type="danger" icon="danger" className="my-md" stayOpen={true}>
      {t('pages.device-transfer.transfer-alert')}
    </Alert>
  )
}
