import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  DialogActions,
} from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSelectedDevices from '../../hooks/useSelectedDevices'
import useSharedAccounts from '../../hooks/useSharedAccounts'
import { ApplicationState } from '../../store'
import { Alert } from '../Alert'
import { Avatar } from '../Avatar'
import { Button } from '../Button'
import { ContactCardDetails } from '../ContactCardDetails'
import { Icon } from '../Icon'
import { LoadingMessage } from '../LoadingMessage'
import { useTranslation } from 'react-i18next'

export function RemoveSharingDialog(): JSX.Element {
  const { ui } = useDispatch<any>() // TODO: fix type defs
  const { visible, selectedDevices } = useSelector((state: ApplicationState) => ({
    visible: state.ui.removeSharingVisible,
    selectedDevices: state.ui.currentDevice ? [state.ui.currentDevice] : useSelectedDevices(),
  }))

  function handleClose(): void {
    ui.setRemoveSharingDialogVisibility(false)
  }

  const ownedDevices = selectedDevices.filter(d => d.Shared !== 'shared-from')

  return visible && (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      open={visible}
      scroll="paper"
    >
      <DialogTitle className="df ai-center w-100" disableTypography>
        <Typography variant="h6" className="f-1">
          Remove Sharing
          {ownedDevices.length > 1 && <> ({ownedDevices.length} devices)</>}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="ml-auto"
        >
          <Icon name="times" />
        </IconButton>
      </DialogTitle>
      {ownedDevices.length > 0 ? (
        <UnshareDevicesForm
          allSelected={selectedDevices}
          deviceIDs={ownedDevices.map(d => d.Address)}
          ownedDevices={ownedDevices}
          onClose={handleClose}
        />
      ) : (
        <DialogContent style={{ minHeight: 400 }} dividers>
          <NoUnsharableDevices devices={selectedDevices} />
        </DialogContent>
      )}
    </Dialog>
  )
}

function UnshareDevicesForm({
  allSelected,
  deviceIDs,
  ownedDevices,
  onClose,
}: {
  allSelected: LegacyDevice[]
  deviceIDs: string[]
  ownedDevices: LegacyDevice[]
  onClose: () => void
}): JSX.Element {
  const { contacts } = useSelector((state: ApplicationState) => ({
    contacts: state.contacts.all as ContactFields[],
    // user: state.auth.user as IUser,
  }))
  const { emails, loading, error } = useSharedAccounts(deviceIDs)
  const [selected, setSelected] = React.useState<string[]>([])
  const [removingSharing, setRemovingSharing] = React.useState<boolean>(false)
  const [removalError, setRemovalError] = React.useState<Error | null>(null)
  const { t } = useTranslation()

  // Set list of selected emails to remove sharing from.
  // If sel is true, add the email to list of selected emails
  // otherwise filter it out.
  function handleSelect(email: string, sel: boolean): void {
    setSelected(all => (sel ? [...all, email] : all.filter(e => e !== email)))
  }

  async function handleRemoveSharing(e): Promise<void> {
    e.preventDefault()

    const devices = deviceIDs.join(',')
    const emails = selected.join(',')

    try {
      setRemovingSharing(true)
      await window.weaved.shareDeveloperDevices(devices, emails, 'off', false)
      setSelected([])
    } catch (e) {
      setRemovalError(e)
    }
    setRemovingSharing(false)
    onClose()
  }

  return (
    <>
      <DialogContent style={{ minHeight: 400 }} dividers>
        <ModifiableDeviceList devices={allSelected} owned={ownedDevices} />

        {loading && (
          <Box my={6}>
            <LoadingMessage text="Getting sharing details..." />
          </Box>
        )}
        {error && <Alert className="my-lg">{error.message}</Alert>}
        {removalError && (
          <Alert className="my-lg">{removalError.message}</Alert>
        )}
        {Boolean(emails.length) && (
          <ShareeList
            emails={emails}
            contacts={contacts}
            onSelect={handleSelect}
            selected={selected}
          />
        )}
      </DialogContent>
      <DialogActions>
        <form onSubmit={handleRemoveSharing}>
          <Box display="flex" alignItems="center" p={2}>
            <Button
              type="submit"
              disabled={loading || !selected.length || removingSharing}
              loading={removingSharing}
            >
              <Icon name="user-slash" className="mr-sm" />
              {removingSharing ? 'Removing Sharing...' : 'Remove Sharing'}
            </Button>
            <Box ml={6} color="text.secondary" fontSize={12}>
            {t('pages.device-share.share-info')}
            </Box>
          </Box>
        </form>
      </DialogActions>
    </>
  )
}

function ShareeList({
  emails,
  contacts,
  selected,
  onSelect,
}: {
  contacts: ContactFields[]
  emails: string[]
  selected: string[]
  onSelect: (email: string, selected: boolean) => void
}): JSX.Element {
  return (
    <Box my={4}>
      <List component="div">
        {emails.map(e => (
          <ShareeItem
            key={e}
            email={e}
            contact={contacts.find(c => c.email === e)}
            onSelect={(sel: boolean) => onSelect(e, sel)}
            selected={selected.includes(e)}
          />
        ))}
      </List>
    </Box>
  )
}

function ShareeItem({
  email,
  contact,
  onSelect,
  selected,
}: {
  email: string
  contact?: ContactFields
  onSelect: (selected: boolean) => void
  selected: boolean
}): JSX.Element {
  const [hovered, setHovered] = React.useState<boolean>(false)

  function toggleSelect(): void {
    onSelect(!selected)
  }

  return (
    <ListItem
      className={`${hovered ? 'bg-gray-lightest' : ''} py-md c-pointer`}
      onClick={toggleSelect}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <ListItemIcon>
        <Box mr={3}>
          <Checkbox checked={selected} />
          <Avatar email={email} round size="36" className="bg-primary" />
        </Box>
      </ListItemIcon>
      <ListItemText>
        <ContactCardDetails email={email} contact={contact} />
      </ListItemText>
    </ListItem>
  )
}

function NoUnsharableDevices({
  devices,
}: {
  devices: LegacyDevice[]
}): JSX.Element {
  return (
    <>
      <Alert stayOpen>
        Please select at least one device you own to unshare.
      </Alert>
      <Box mt={4} mb={2}>
        The following devices are not owned by you, so you cannot unshare them:
      </Box>
      <ModifiableDeviceList devices={devices} owned={[]} />
    </>
  )
}

function ModifiableDeviceList({
  devices = [],
  owned = [],
}: {
  devices: LegacyDevice[]
  owned: LegacyDevice[]
}): JSX.Element {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  // console.log('devices', devices)
  // console.log('owned', owned)
  return (
    <>
      {owned.length > 1 ? (
        <Box display="flex" alignItems="center">
          <Box>
            Remove sharing from <strong>{owned.length} devices</strong>
          </Box>
          <Box ml="auto">
            <Button
              small
              outline
              color="secondary"
              onClick={() => setExpanded(e => !e)}
              type="button"
            >
              {expanded ? 'Hide' : 'Show all'}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          Remove sharing from <strong>{owned[0]?.ServiceName}</strong>
        </Box>
      )}
      {expanded && (
        <Box>
          {devices.map((device, key) => (
            <Box key={key} my={1}>
              {device.Shared === 'shared-from' ? (
                <Icon name="times" color="danger" />
              ) : (
                <Icon name="check" color="success" />
              )}
              <span className="ml-sm">{device.ServiceName}</span>
            </Box>
          ))}
        </Box>
      )}
    </>
  )
}
