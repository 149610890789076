import React from 'react'
import { Icon } from '../Icon'

export function ContactCardDetails({
  contact,
  email,
}: {
  contact?: ContactFields
  email: string
}): JSX.Element {
  const firstName = contact?.firstName !== '-'
  const lastName = contact?.lastName !== '-'

  return (
    <>
      {contact ? (
        <>
          <div className="mb-xs">
            {firstName || lastName ? (
              <>
                {contact.firstName} {contact.lastName}
              </>
            ) : (
              <>{email}</>
            )}
          </div>
          <div className="txt-sm gray">
            {contact.companyName && contact.companyName !== '-' && (
              <span className="mr-lg">
                <Icon name="industry" className="mr-xs" />
                {contact.companyName}
              </span>
            )}
            <span>
              <Icon name="envelope" className="mr-xs" /> {email}
            </span>
          </div>
        </>
      ) : (
        <>{email}</>
      )}
    </>
  )
}
