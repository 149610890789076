import React from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import { ApplicationState } from '../../store'
import { Gutters } from '../Gutters'
import { Plans } from '../Plans'
import { Container } from '../Container/Container'
import { LoadingMessage } from '../LoadingMessage'
import { Section } from '../Section'
import { getRemoteitLicense } from '../../models/licensing'

export const PlanSection: React.FC = () => {
  const { licensing } = useSelector((state: ApplicationState) => ({
    licensing: state.licensing,
    user: state.auth.user,
    transactions: state.auth.transactions,
    license: getRemoteitLicense(state),
  }))

  if (!licensing.initialized) return <LoadingMessage text="Loading plans..." />
  return (
    <>
      <Section mWidth="1500px" title={<>Subscriptions</>} icon="shopping-cart">
        <Container
          gutterBottom
          integrated={false}
          footer={
            <Gutters>
              <Typography variant="caption">
                * Devices are virtual (like AWS EC2) or physical (like Raspberry Pi, Nvidia Jetson, Windows PC, etc){' '}
                <br />
                &nbsp; Pricing is represented and billed in US$ on most popular credit cards.
              </Typography>
            </Gutters>
          }
        >
          <Plans />
        </Container>
      </Section>
    </>
  )
}
