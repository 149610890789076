import React from 'react'
import { LoadingMessage, LoadingMessageProps } from '../LoadingMessage'

export interface Props extends LoadingMessageProps {
  text?: string
}

export function LoadingScreen({ text }: Props): JSX.Element {
  return (
    <div className="h-100 bg-white df ai-center jc-center center">
      <LoadingMessage text={text} />
    </div>
  )
}
