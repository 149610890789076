import React from 'react'
import { ApplicationState } from '../../store'
import { connect } from 'react-redux'
import { IUser } from 'remote.it'
import { PageHeader } from '../PageHeader'
import { SignInMessage } from '../SignInMessage'
import { AccountAccessKey } from '../AccountAccessKey'
import { MFAPreference } from '../MFAPreference'
import { ChangePassword } from '../ChangePassword'
import { GlobalSignout } from '../GlobalSignout'
import { PlanSection } from './PlanSection'
import { BillingPage } from './BillingPage'
import { CreditCard } from '../CreditCard'
import { LicencingSection } from './LicensingSection'
import { ProfilePictureSection } from './ProfilePictureSection'
import { DeveloperKeySection } from './DeveloperKeySection'
import { ChangeEmailLanguageSection } from './ChangeEmailLanguageSection'
import { DeleteAccountSection } from './DeleteAccountSection'
import { NotificationSection } from './NotificationSection'

export const AccountPage = connect((state: ApplicationState) => ({
  user: state.auth.user,
  commercialDomain: state.auth.commercialDomain,
}))(({ user, commercialDomain }: { user?: IUser; commercialDomain?: boolean }) => {
  if (!user) {
    return (
      <div className="p-xl h-100">
        <LicencingSection />
        <PageHeader title="Account" />
        <SignInMessage />
      </div>
    )
  }

  return (
    <div className="fw-lighter">
      <div className="p-xl">
        <PageHeader title="Account" />
      </div>

      {!commercialDomain && (
        <>
          <LicencingSection />
          <PlanSection />
          <CreditCard />
          <BillingPage />
        </>
      )}
      <ChangePassword />
      <GlobalSignout />
      <MFAPreference />
      <ChangeEmailLanguageSection />
      <NotificationSection />
      <ProfilePictureSection email={user.email} />
      <DeveloperKeySection apiKey={user.apiKey} />
      <AccountAccessKey />
      <DeleteAccountSection email={user.email} paidPlan={user.plan.name !== 'free'} />
      {/*<SectionHeading className="mt-xxl mb-lg">Webhooks</SectionHeading>*/}
    </div>
  )
})
