import React from 'react'
import { Icon } from '../Icon'
import { IconButton, Popover, Typography } from '@material-ui/core'

export interface HelpPopoverProps {
  children: React.ReactNode
  size?: 'sm' | 'md' | 'lg'
  title?: string
}

export function HelpPopover({
  children,
  size = 'md',
  title,
}: HelpPopoverProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState(null)

  function handleClick(e: any): void {
    setAnchorEl(e.currentTarget)
  }

  function handleClose(): void {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'help-popover' : undefined

  let maxWidth = 300
  if (size === 'sm') maxWidth = 200
  if (size === 'lg') maxWidth = 500

  return (
    <>
      <IconButton aria-describedby={id} size="small" onClick={handleClick}>
        <Icon name="question-circle" color="gray" fixedWidth />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div className="px-md py-sm" style={{ maxWidth }}>
          {title && (
            <Typography className="txt-lg mb-sm">
              <strong>{title}</strong>
            </Typography>
          )}
          {children}
        </div>
      </Popover>
    </>
  )
}
