import classNames from 'classnames'
import React, { ReactNode, useState, useEffect } from 'react'
import styles from './Alert.module.css'
import { Icon } from '../Icon'

export interface Props extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  children: ReactNode
  className?: string
  variant?: 'default' | 'inverted' | 'outlined'
  onClose?: () => void
  size?: 'sm' | 'md'
  stayOpen?: boolean
  timeout?: number
  type?: 'success' | 'info' | 'warning' | 'danger' | 'muted'
  icon?: 'success' | 'info' | 'warning' | 'danger' | 'muted' | 'none'
  id?: string
}

export function Alert({
  children,
  className,
  icon,
  variant = 'default',
  onClose,
  size = 'md',
  stayOpen = false,
  type = 'danger',
  timeout,
  ...props
}: Props): JSX.Element {
  const [visible, setVisible] = useState<boolean>(true)

  useEffect(() => {
    if (timeout) {
      setTimeout(() => {
        setVisible(false)
      }, timeout * 1000)
    }
  })

  if (!visible) return <></>

  // Use the given icon or default to the type of alert.

  icon = icon || type

  // const background = inverted ? 'bg-gray-darker' : `bg-${type}`
  // const iconColor = inverted ? type + '-light' : 'white'
  // const closeColor = inverted
  //   ? 'gray-dark hov-gray-light'
  //   : `${type}-dark hov-${type}-darkest`

  let iconKey = 'exclamation-triangle'
  if (icon === 'warning') iconKey = 'exclamation-circle'
  if (icon === 'success') iconKey = 'check'
  if (icon === 'info') iconKey = 'info-circle'
  if (icon === 'muted') iconKey = 'info-circle'

  const container = classNames(
    styles.base,
    {
      [styles[size]]: size,
      [styles[type]]: type,
      [styles[variant]]: variant,
    },
    className
  )

  return (
    <div className={container} {...props}>
      {icon !== 'none' && (
        <span className={styles.icon}>
          <Icon name={iconKey} className="mr-md" fixedWidth />
        </span>
      )}
      <span className={'alert-body ' + styles.body} data-alert-body>
        {children}
      </span>
      {!stayOpen && (
        <a
          href=""
          onClick={e => {
            e.preventDefault()
            setVisible(false)
            if (onClose) onClose()
          }}
          className={styles.close}
        >
          <Icon name="times" />
        </a>
      )}
    </div>
  )
}
