import { init, RematchRootState, Middleware } from '@rematch/core'
import immerPlugin from '@rematch/immer'
import selectPlugin from '@rematch/select'
import logger from 'redux-logger'
import * as unfilteredModels from './models'

const models = filterOutESModules(unfilteredModels)
const plugins = [immerPlugin(), selectPlugin()]
const middlewares: Middleware[] = []

if (process.env.NODE_ENV !== 'test') middlewares.push(logger)

export const store = init({
  models,
  plugins,
  redux: { middlewares },
})

export const { select } = store

// Export types
export type Store = typeof store
export type Dispatch = typeof store.dispatch
export type ApplicationState = RematchRootState<typeof models>

// HACK: Hack to remove the exported __esModule item
// when importing eveything from a module and then passing
// it into rematch
interface Models {
  [key: string]: any
}

function filterOutESModules(models: Models): Models {
  return Object.keys(models).reduce((all, key) => {
    if (key !== '__esModule') all[key] = models[key]
    return all
  }, {})
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
