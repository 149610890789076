import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { Icon } from '../Icon'
import { Link } from 'react-router-dom'
import styles from './Button.module.css'

/* 

  WARNING THIS COMPONENT IS DEPRECATED 
  PLEASE REPLACE WITH MUI BUTTON COMPONENTS

*/

type ButtonType = 'button' | 'submit' | 'reset' | undefined

export interface ButtonProps {
  block?: boolean
  children: ReactNode
  className?: string
  color?: 'primary' | 'secondary' | 'danger' | 'white'
  href?: string
  large?: boolean
  link?: boolean
  loading?: boolean
  outline?: boolean
  round?: boolean
  small?: boolean
  to?: string
  type?: ButtonType
  [key: string]: any
}

export function Button({
  block = false,
  children,
  className = '',
  color = 'primary',
  large = false,
  link,
  loading = false,
  outline = false,
  round = false,
  small = false,
  type = 'text' as ButtonType,
  ...props
}: ButtonProps): JSX.Element {
  const classes = classNames(
    styles.button,
    {
      [styles[color]]: color && !link,
      [styles.link]: link,
      [styles.round]: round,
      [styles.small]: small,
      [styles.large]: large,
      [styles.block]: block,
      [styles.outline]: outline,
    },
    className
  )
  props.className = classes

  // Make the input disabled if it is "loading"
  props.disabled = props.disabled || loading

  // console.warn(
  //   'WARNING THIS BUTTON COMPONENT IS DEPRECATED PLEASE REPLACE IT WITH MUI BUTTON COMPONENTS'
  // )

  const loader = loading && <Icon name="spinner-third" className="ml-md" spin />

  if (props.to) {
    return (
      // @ts-ignore
      <Link {...props}>
        {children} {loader}
      </Link>
    )
  }

  if (props.href) {
    return (
      <a type={type} {...props}>
        {children} {loader}
      </a>
    )
  }

  return (
    <button type={type} {...props}>
      {children} {loader}
    </button>
  )
}
