import { createModel } from '@rematch/core'
import { IDevice } from 'remote.it'
import { r3 } from '../services/remote.it'

interface DevicesState {
  error: string
  fetching: boolean
  all: IDevice[]
  selectedDevices: string[]
  acceptableSelectedDevices: string[]
  rejectedSelectedDevices: string[]
  searchOnly: boolean
  currentDeviceId?: string
}

const state: DevicesState = {
  all: [],
  error: '',
  fetching: false,
  selectedDevices: [],
  acceptableSelectedDevices: [],
  rejectedSelectedDevices: [],
  searchOnly: false,
  currentDeviceId: undefined,
}

export default createModel({
  state,
  effects: dispatch => ({
    async fetch() {
      this.fetchStarted
      const list = await r3.devices
        .all()
        .then(() => dispatch.devices.setList(list))
        .then(() => this.fetchFinished)
    },
    select(deviceIDs: string[]) {
      return dispatch.contacts.setSelection(deviceIDs)
    },
  }),
  reducers: {
    fetchStarted(state: DevicesState) {
      this.clearError
      state.fetching = true
    },
    setSearchOnly(state: DevicesState, searchOnly: boolean) {
      state.searchOnly = searchOnly
    },
    fetchFinished(state: DevicesState) {
      state.fetching = false
    },
    clearError(state: DevicesState) {
      state.error = ''
    },
    fetchingError(state: DevicesState, error) {
      state.error = error
    },
    setList(state: DevicesState, devices: IDevice[]) {
      state.all = devices
    },
    setSelection(state: DevicesState, devices: string[]) {
      state.selectedDevices = devices
    },
    setAcceptableDevices(state: DevicesState, devices: string[]) {
      state.acceptableSelectedDevices = devices
    },
    setRejectedDevices(state: DevicesState, devices: string[]) {
      state.rejectedSelectedDevices = devices
    },
    setCurrentDeviceId(state: DevicesState, devicesId: string) {
      state.currentDeviceId = devicesId
    },
  },
})
