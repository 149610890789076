import tracker, { TrackerFunctionData } from '../services/Analytics'

/**
 * A hook to make tracking of analytics events in React
 * components a bit easier.
 *
 * Usage:
 * const track = useTracker()
 * track('event', 'label', 100)
 */
export default function useTrackEvent() {
  return (event: string, opts: TrackerFunctionData) =>
    tracker.track(event, opts)
}
