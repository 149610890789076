import setup from 'remote.it'
import { store } from '../store'
const API_URL = process.env.API_URL

export const r3 = setup(
  {
    apiURL: API_URL,
    developerKey: process.env.DEVELOPER_KEY,
    taskQueue: process.env.TASK_QUEUE_NAME,
    jobQueue: process.env.JOB_QUEUE_NAME,
    successURL: window.location.origin,
  },
  getToken
)

export async function getToken(withBearer = true): Promise<string> {
  try {
    const currentSession = await window.authService.currentCognitoSession()
    if (currentSession !== undefined) {
      let token = currentSession.getAccessToken().getJwtToken()
      if (withBearer) {
        token = 'Bearer ' + token
      }
      return token
    } else {
      return ''
    }
  } catch (error) {
    console.error('getToken Error:')
    console.error(error)
    if (error.code === 'NetworkError') {
      // Network error, notify?
      return ''
    } else {
      await store.dispatch.auth.signOut(undefined)
      return ''
    }
  }
}
