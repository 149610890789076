import {
  Collapse,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { IContact } from 'remote.it'
import { Avatar } from '../Avatar'
import { ContactCardDetails } from '../ContactCardDetails'
import { LoadingMessage } from '../LoadingMessage'
import { ContactCardActions } from './ContactCardActions'
import { SharingAccess, SharingDetails } from './DeviceShareDetails'
import { SharingForm } from './SharingForm'

export function ContactCard({
  allServices,
  contact,
  deviceID,
  email,
  scripting,
  sharedServices,
  unshare,
  updateSharing,
}: {
  allServices: SimplifiedService[]
  contact?: IContact
  deviceID: string
  email: string
  scripting: boolean
  sharedServices: string[]
  unshare: (email: string) => void
  updateSharing: (access: SharingDetails) => Promise<void>
}): JSX.Element {
  const { t } = useTranslation()

  const [open, setOpen] = React.useState<boolean>(false)
  const [saving, setSaving] = React.useState<boolean>(false)
  const [unsharing, setUnsharing] = React.useState<boolean>(false)
  const [unshared, setUnshared] = React.useState<boolean>(false)
  const [scripts, setScripts] = React.useState<boolean>(scripting)
  const [selectedServices, setSelectedServices] = React.useState<string[]>(
    sharedServices
  )

  function handleChange(access: SharingAccess): void {
    setScripts(access.scripting)
    setSelectedServices(access.services)
  }

  async function handleUnshare(email: string): Promise<void> {
    setUnsharing(true)
    await unshare(email)
    setUnsharing(false)
    setUnshared(true)
  }

  async function handleSharingUpdate(): Promise<void> {
    setSaving(true)
    // They have removed scripting and services, unshare totally.
    if (!scripts && !selectedServices.length) {
      await handleUnshare(email)
    } else {
      await updateSharing({
        access: {
          scripting: scripts,
          services: selectedServices,
        },
        contacts: [email],
        deviceID,
      })
    }
    setSaving(false)
    setOpen(false)
  }

  if (unshared) {
    return <></>
  }

  if (unsharing) {
    return (
      <>
        <ListItem className="py-md">
          <ListItemText>
            <LoadingMessage
              text={t('pages.device-share.stopping-sharing-message', { email })}
            />
          </ListItemText>
        </ListItem>
        <Divider />
      </>
    )
  }

  return (
    <Suspense fallback={<LoadingMessage text={t('global.actions.loading')} />}>
      <ListItem className="py-md">
        <ListItemIcon onClick={() => setOpen(!open)} className="c-pointer">
          <Avatar email={email} round size="36" className="bg-primary" />
        </ListItemIcon>
        <ListItemText onClick={() => setOpen(!open)} className="c-pointer">
          <ContactCardDetails email={email} contact={contact} />
        </ListItemText>
        <ContactCardActions
          allServices={allServices}
          email={email}
          open={open}
          saving={saving}
          scripting={scripting}
          sharedServices={sharedServices}
          setOpen={setOpen}
          unshare={handleUnshare}
          updateSharing={handleSharingUpdate}
        />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div className="ml-xxl mb-md">
          <SharingForm
            services={allServices}
            saving={saving}
            scripting={scripts}
            onChange={handleChange}
            selectedServices={selectedServices}
          />
        </div>
      </Collapse>
      <Divider />
    </Suspense>
  )
}
