import { createModel } from '@rematch/core'
import { axiosGraphQL } from '../utils/graphQL'

type IBilling = {
  invoices: IInvoice[]
  loading: boolean
}

const defaultState: IBilling = {
  invoices: [],
  loading: false,
}

export default createModel({
  state: defaultState,
  effects: (dispatch: any) => ({
    async fetch() {
      dispatch.billing.set({ loading: true })

      const graphqlBillings = ` {
        login {
          invoices {
            plan {
              name
            }
            quantity
            price {
              id
              amount
              currency
              interval
            }
            total
            paid
            url
            created
          }
        }
      }`

      try {
        const graphQL = await axiosGraphQL()
        const response = await graphQL?.post('', {
          query: graphqlBillings,
        })

        dispatch.billing.parse(response?.data?.data)
      } catch (error) {
        this.setState({ error })
        console.error('Failed to Update:', error)
      }
      dispatch.billing.set({ loading: false })
    },
    async parse(data: any) {
      if (!data) return
      console.log('LICENSING', data)
      dispatch.billing.set({
        invoices: data?.login.invoices.map(i => ({
          ...i,
          created: new Date(i.created),
        })),
      })
    },
  }),
  reducers: {
    reset(state: IBilling) {
      state = defaultState
      return state
    },
    set(state: IBilling, params: ILookup<any>) {
      Object.keys(params).forEach(key => (state[key] = params[key]))
      return state
    },
  },
})
